import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EventManagementService} from '../../services/event-service/event-management.service';
import {CustomerTagManagementService} from '../../services/customer-tag-service/customer-tag-management.service';
import {
    SubscriptionTypeManagementService
} from '../../services/subscription-type-service/subscription-type-management.service';
import {Selected} from './selected.class';
import {FilterSetting} from './settings.class';
import {VenueManagementService} from '../../services/venue-service/venue-management.service';
import {
    DiscountCampaignManagementService
} from '../../services/discount-campaign-management-service/discount-campaign-management.service';
import {PlatformService} from '../../services/platform-service/platform.service';
import {map} from 'rxjs';
import {PaymentMethodService} from '../../services/payment-method/payment-method.service';
import {ActivityLogService} from '../../services/activity-log-service/activity-log.service';
import {CmTranslationService} from '../../../core/services/cm-translation.service';
import {CampaignManagementService} from '../../services/campaign-management-service/campaign-management.service';
import {ProductTypeManagementService} from '../../services/product-type-service/product-type-management.service';
import {PayoutManagementService} from '../../services/payout-management-service/payout-management.service';
import {ImportService} from '../../services/import-service/import.service';
import {EventCategoryService} from '../../services/event-category-service/event-category.service';
import {ListItem, ListService} from '../../list/list.service';

@Component({
    selector: 'app-search-filter-container',
    templateUrl: './search-filter-container.component.html',
    styleUrls: ['./search-filter-container.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchFilterContainerComponent implements OnInit {
    @Input() settings: FilterSetting[] = [];
    @Input() selected: Selected;
    @Output() selectedChange: EventEmitter<any> = new EventEmitter<any>();
    previousSelected: Selected;

    @Input() statusOptions = [
        {label: 'Reserved', value: 'RESERVED'},
        {label: 'Issued', value: 'ISSUED'},
        {label: 'Blocked', value: 'BLOCKED'},
        {label: 'Canceled', value: 'CANCELED'},
        {label: 'Resell available', value: 'RESELL_AVAILABLE'},
        {label: 'Resell sold', value: 'RESELL_SOLD'}
    ];

    @Input() filterOptions: {
        includeNonePaymentMethod: boolean
    } = {
        includeNonePaymentMethod: false
    };

    eventOptions: any[] = [];
    eventCategoryOptions: any[] = [];
    venueOptions: any[] = [];
    venueSectionOptions: any[] = [];
    ticketTypeOptions: any[] = [];
    subscriptionTypeOptions: any[] = [];
    subscriptionTypePriceOptions: any[] = [];
    productTypeOptions: any[] = [];
    productTypePriceOptions: any[] = [];
    customerTagOptions: any[] = [];
    orderTagOptions: any[] = [];
    shopOptions: any[] = [];
    campaignOptions: any[] = [];
    discountCampaignOptions: any[] = [];
    paymentMethodOptions: any[] = [];
    importedOptions: any[] = [];
    payoutOptions: any[] = [];
    personOptions: any[] = [];
    activityLogEventOptions: any[] = [];

    constructor(
        private eventManagementService: EventManagementService,
        private eventCategoryService: EventCategoryService,
        private subscriptionTypeManagementService: SubscriptionTypeManagementService,
        private venueManagementService: VenueManagementService,
        private customerTagManagementService: CustomerTagManagementService,
        private paymentMethodService: PaymentMethodService,
        private platformService: PlatformService,
        private campaignManagementService: CampaignManagementService,
        private discountCampaignManagementService: DiscountCampaignManagementService,
        private productTypeManagementService: ProductTypeManagementService,
        private payoutManagementService: PayoutManagementService,
        private activityLogService: ActivityLogService,
        private cmTranslationService: CmTranslationService,
        private importService: ImportService,
        private listService: ListService
    ) {
    }

    ngOnInit(): void {
        this.previousSelected = JSON.parse(JSON.stringify(this.selected));

        this.customerTagManagementService.list$.subscribe(customerTags => {
            this.customerTagOptions = customerTags;
        });

        this.listService.get('orderTag').subscribe({
            next: (listItems: ListItem[]): void => {
                this.orderTagOptions = listItems;
            },
            error: () => {
                this.orderTagOptions = [];
            }
        });

        this.listService.get('shop').subscribe({
            next: (listItems: ListItem[]): void => {
                this.shopOptions = listItems;
            },
            error: () => {
                this.shopOptions = [];
            }
        });

        this.campaignManagementService.list$.subscribe(campaigns => {
            this.campaignOptions = campaigns;
        });

        this.discountCampaignManagementService.list$.subscribe(discountCampaigns => {
            this.discountCampaignOptions = discountCampaigns;
        });

        this.eventManagementService.list$.subscribe(events => {
            this.eventOptions = events;
            this.onFilterUpdateEvent();
        });

        this.importService.getImports().subscribe(response => {
            this.importedOptions = response.body;
        });

        this.eventCategoryService.getEventCategories().subscribe(categories => {
            this.eventCategoryOptions = categories;
        });

        this.activityLogService.getActivityLogEvents().subscribe(data => {
            this.activityLogEventOptions = data.map(event => {
                return {
                    id: event,
                    name: this.cmTranslationService.getPhraseForLanguage(`Activity_Log_Event.${event}`)
                };
            });
        });

        this.subscriptionTypeManagementService.list$.subscribe(subscriptionTypes => {
            this.subscriptionTypeOptions = subscriptionTypes;
            this.onFilterUpdateSubscriptionType();
        });

        this.productTypeManagementService.list$.subscribe(productTypes => {
            this.productTypeOptions = productTypes;
            this.onFilterUpdateProductType();
        });

        this.venueManagementService.venuesSubject.subscribe(venues => {
            this.venueOptions = venues;
            this.updateVenueList();
        });

        this.paymentMethodService.getPaymentMethods().pipe(map(paymentMethods => {
            const mappedPaymentMethods = paymentMethods.map(paymentMethod => {
                return {id: paymentMethod.method, label: paymentMethod.name};
            });

            if (this.filterOptions.includeNonePaymentMethod) {
                mappedPaymentMethods.unshift({id: 'NONE', label: 'None'});
            }

            return mappedPaymentMethods;
        })).subscribe(paymentMethods => {
            this.paymentMethodOptions = paymentMethods;
        });

        this.payoutManagementService.list$.subscribe(payouts => {
            this.payoutOptions = payouts;
        });

        this.platformService.getPersons().pipe(map(persons => {
            return persons.map(person => {
                return {id: person.SSOAccountID, label: `${person.FirstName} ${person.LastName}`};
            });
        })).subscribe({
            next: (persons) => {
                this.personOptions = persons;
            },
            error: (error) => {
                console.warn(error);
            }
        });

        this.venueManagementService.getVenues();
    }

    onFilterUpdateEvent() {
        let ticketTypeIds = [];

        const events = this.eventOptions?.filter(item => this.selected.eventIds.includes(item.id) && item.ticketTypes.length > 0);
        this.ticketTypeOptions = [];

        for (const event of events) {
            this.ticketTypeOptions.push({name: event.name, options: event.ticketTypes});
            ticketTypeIds = ticketTypeIds.concat(event.ticketTypes.map(ticketType => ticketType.id));
        }

        // Filter ticket types that are not in current selected events
        this.selected.ticketTypeIds = this.selected.ticketTypeIds.filter(id => ticketTypeIds.includes(id));

        this.updateVenueList();
        this.change();
    }

    onFilterUpdateSubscriptionType() {
        let subscriptionTypePriceIds = [];
        const subscriptionsTypes = this.subscriptionTypeOptions?.filter(item => this.selected.subscriptionTypeIds.includes(item.id) && item.subscriptionTypePrices.length > 0);
        this.subscriptionTypePriceOptions = [];

        for (const subscriptionsType of subscriptionsTypes) {
            this.subscriptionTypePriceOptions.push({
                name: subscriptionsType.name,
                options: subscriptionsType.subscriptionTypePrices
            });

            subscriptionTypePriceIds = subscriptionTypePriceIds.concat(subscriptionsType.subscriptionTypePrices.map(subscriptionTypePrice => subscriptionTypePrice.id));
        }

        // Filter ticket types that are not in current selected events
        this.selected.subscriptionTypePriceIds = this.selected.subscriptionTypePriceIds.filter(id => subscriptionTypePriceIds.includes(id));

        this.updateVenueList();
        this.change();
    }

    onFilterUpdateProductType() {
        let productTypePriceIds = [];
        const productTypes = this.productTypeOptions?.filter(item => this.selected.productTypeIds.includes(item.id) && item.productTypePrices.length > 0);
        this.productTypePriceOptions = [];

        for (const productType of productTypes) {
            this.productTypePriceOptions.push({name: productType.name, options: productType.productTypePrices});

            productTypePriceIds = productTypePriceIds.concat(productType.productTypePrices.map(productTypePrice => productTypePrice.id));
        }

        // Filter ticket types that are not in current selected events
        this.selected.productTypePriceIds = this.selected.productTypePriceIds.filter(id => productTypePriceIds.includes(id));

        this.updateVenueList();
        this.change();
    }

    updateVenueList() {
        const eventVenueIds = this.eventOptions?.filter(item => this.selected.eventIds.includes(item.id)).map(item => item.venueId);
        const subscriptionsTypeVenueIds = this.subscriptionTypeOptions?.filter(item => this.selected.subscriptionTypeIds.includes(item.id)).map(item => item.venueId);
        const venueIds = [...new Set([].concat(eventVenueIds, subscriptionsTypeVenueIds))];
        const venue = this.venueOptions?.find(item => item.id === venueIds[0]);
        this.venueSectionOptions = [];

        if (venue) {
            for (const section of venue.sections) {
                this.venueSectionOptions.push({name: section.name, options: section.sections});
            }
        }
    }

    change() {
        if (this.selected.payoutIds.length > 0 && JSON.stringify(this.previousSelected.payoutIds) !== JSON.stringify(this.selected.payoutIds)) {
            const payoutIds = this.selected.payoutIds;
            this.selected = new Selected();
            this.selected.payoutIds = payoutIds;
            this.selected.dateRange = {
                id: 'ALL',
                startAt: null,
                endAt: null
            };
        }

        if (JSON.stringify(this.previousSelected) === JSON.stringify(this.selected)) {
            return;
        }

        this.previousSelected = JSON.parse(JSON.stringify(this.selected));
        this.selectedChange.emit(this.selected);
    }

    showAddAdditionalFilters() {
        return this.settings.filter(setting => !setting.isPrimary).length > 0;
    }
}
