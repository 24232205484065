import {Component, EventEmitter, Output, OnInit} from '@angular/core';
import {OrderDistributionService} from '../../services/order-distribution.service';
import {MobileTemplate} from '../../models/mobile-template';

@Component({
    selector: 'cm-ticketing-mobile-template-list',
    templateUrl: './mobile-template-list.component.html',
    styleUrls: ['./mobile-template-list.component.css'],
    standalone: false
})
export class MobileTemplateListComponent implements OnInit {
    @Output()
    mobileTemplateEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    mobileTemplateDelete: EventEmitter<any> = new EventEmitter<any>();

    public mobileTemplates;
    public mobileTemplates$;

    constructor(private orderDistributionService: OrderDistributionService) {
    }

    ngOnInit(): void {
        this.loadMobileTemplateList();
    }

    loadMobileTemplateList(): void {
        this.mobileTemplates$ = this.orderDistributionService.getMobileTemplates();
        this.mobileTemplates$.subscribe((mobileTemplates: MobileTemplate[]) => {
            this.mobileTemplates = mobileTemplates.sort((templateA: MobileTemplate, templateB: MobileTemplate) => {
                if (templateB.is_default) {
                    return 1;
                } else {
                    return new Date(templateB.created_at).getTime() - new Date(templateA.created_at).getTime();
                }
            });
            for (let i = 0; i < this.mobileTemplates.length; i++) {
                const mobileTemplate = this.mobileTemplates[i];
                const index = i;
                if (mobileTemplate.is_visible) {
                    this.orderDistributionService.getMobileTemplateExample(mobileTemplate.uuid).subscribe(() => {
                    }, (error) => {
                        this.renderMobileTemplate(error.error.text, index);
                    });
                }
            }
        });
    }


    renderMobileTemplate(html, index): void {
        const templateElement = document.createElement('div');
        templateElement.innerHTML = html;

        const styleElement = document.createElement('style');
        styleElement.appendChild(
            document.createTextNode(`
            .banner {
                width: 640px;
                height: 200px;
            }

            .container {
                padding-left: 0 !important;
                margin-left: 0 !important;
                margin-bottom: 0 !important;
            }

            .container .main-content {
                box-shadow: none !important;
                max-height: 530px;
            }

            .swiper-container {
                max-height: 530px;
            }

            .logo {
                width: 75px;
            }`)
        );

        templateElement.prepend(styleElement);

        const templateEditor = document.getElementById('mobile-template-' + index);
        if (templateEditor) {
            templateEditor.attachShadow({ mode: 'open' });
            templateEditor.shadowRoot.innerHTML = html;
            templateEditor.shadowRoot.prepend(styleElement);
        }
    }

    onMobileTemplateClick(mobileTemplate): void {
        this.mobileTemplateEdit.emit(mobileTemplate);
    }

    onDeleteMobileTemplateClick(mobileTemplate): void {
        this.orderDistributionService.deleteMobileTemplate(mobileTemplate).subscribe(() => {
            (window as any).AddSuccessNotification('Archived successful');
            this.mobileTemplateDelete.emit(mobileTemplate);
            this.loadMobileTemplateList();
        });
    }

}
