import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-select-account',
    templateUrl: './select-account.component.html',
    styleUrls: ['./select-account.component.css'],
    standalone: false
})
export class SelectAccountComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
