import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {
    EmailTemplateGroupListComponent
} from './components/email-template-group-list/email-template-group-list.component';
import {EmailTemplateEditorComponent} from './components/email-template-editor/email-template-editor.component';
import {CM_TICKETING_LIBRARY_CONFIG} from './core/cm-ticketing-library.config.token';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {OrderDistributionService} from './services/order-distribution.service';
import {ResourceService} from './services/resource.service';
import {TranslationService} from './services/translation.service';
import {TranslationPipe} from './services/translation.pipe';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {TranslationDialogComponent} from './components/translation-dialog/translation-dialog.component';
import {FormsModule} from '@angular/forms';
import {TicketTemplateListComponent} from './components/ticket-template-list/ticket-template-list.component';
import {MobileTemplateListComponent} from './components/mobile-template-list/mobile-template-list.component';
import {MobileTemplateEditorComponent} from './components/mobile-template-editor/mobile-template-editor.component';
import '@angular/common/locales/global/nl';
import {TicketTemplateGuiComponent} from './components/ticket-template-gui/ticket-template-gui.component';
import {
    TicketTemplateA4Default1Component
} from './components/ticket-template-list/ticket-template-presets/A4/default-1/ticket-template-a4-default-1.component';
import {
    TicketTemplateA4Default2Component
} from './components/ticket-template-list/ticket-template-presets/A4/default-2/ticket-template-a4-default-2.component';
import {
    TicketTemplateA4CustomComponent
} from './components/ticket-template-list/ticket-template-presets/A4/custom/ticket-template-a4-custom.component';
import {
    TicketTemplateBocaDefault1Component
} from './components/ticket-template-list/ticket-template-presets/BOCA/default-1/ticket-template-boca-default-1.component';
import {
    TicketTemplateBocaDefault2Component
} from './components/ticket-template-list/ticket-template-presets/BOCA/default-2/ticket-template-boca-default-2.component';
import {
    TicketTemplateBocaCustomComponent
} from './components/ticket-template-list/ticket-template-presets/BOCA/custom/ticket-template-boca-custom.component';
import {
    TicketTemplatePvccardDefault1Component
} from './components/ticket-template-list/ticket-template-presets/PVCCARD/default-1/ticket-template-pvccard-default-1.component';
import {
    TicketTemplatePvccardDefault2Component
} from './components/ticket-template-list/ticket-template-presets/PVCCARD/default-2/ticket-template-pvccard-default-2.component';
import {
    TicketTemplatePvccardCustomComponent
} from './components/ticket-template-list/ticket-template-presets/PVCCARD/custom/ticket-template-pvccard-custom.component';
import {CmTicketingLibraryConfig} from './core/cm-ticketing-library.config';
import {DataTableComponent} from './components/data-table/data-table.component';
import {DataTableRowDataComponent} from './components/data-table-row-data/data-table-row-data.component';
import {DataTableHeaderDataComponent} from './components/data-table-header-data/data-table-header-data.component';
import {DataTableFooterDataComponent} from './components/data-table-footer-data/data-table-footer-data.component';
import {ByPassSecurityPipe} from './services/by-pass-security.pipe';

@NgModule({
    declarations: [
        EmailTemplateGroupListComponent,
        EmailTemplateEditorComponent,
        TranslationDialogComponent,
        TranslationPipe,
        TicketTemplateListComponent,
        MobileTemplateListComponent,
        MobileTemplateEditorComponent,
        TicketTemplateGuiComponent,
        TicketTemplateA4Default1Component,
        TicketTemplateA4Default2Component,
        TicketTemplateA4CustomComponent,
        TicketTemplateBocaDefault1Component,
        TicketTemplateBocaDefault2Component,
        TicketTemplateBocaCustomComponent,
        TicketTemplatePvccardDefault1Component,
        TicketTemplatePvccardDefault2Component,
        TicketTemplatePvccardCustomComponent,
        DataTableComponent,
        DataTableRowDataComponent,
        DataTableHeaderDataComponent,
        DataTableFooterDataComponent,
        ByPassSecurityPipe,
    ],
    exports: [
        EmailTemplateGroupListComponent,
        EmailTemplateEditorComponent,
        TranslationDialogComponent,
        TicketTemplateListComponent,
        TicketTemplateGuiComponent,
        MobileTemplateListComponent,
        MobileTemplateEditorComponent,
        DataTableComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [OrderDistributionService, ResourceService, TranslationService, CurrencyPipe, provideHttpClient(withInterceptorsFromDi())]
})
export class ComponentLibraryModule {
    static forRoot(cmTicketingLibraryConfig: CmTicketingLibraryConfig): ModuleWithProviders<ComponentLibraryModule> {
        return {
            ngModule: ComponentLibraryModule,
            providers: [
                {
                    provide: CM_TICKETING_LIBRARY_CONFIG,
                    useValue: cmTicketingLibraryConfig
                }
            ]
        };
    }
}
