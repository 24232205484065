import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-event-ticket-table-filter',
    templateUrl: './event-ticket-table-filter.component.html',
    styleUrls: ['./event-ticket-table-filter.component.css'],
    standalone: false
})
export class EventTicketTableFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
