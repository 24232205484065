<h3>{{ ('Customer_Invite_List.Invite' | trans) }}</h3>

@if (customer.invite) {
    <div class="page-section">
        <div class="datatable">
            <div class="datatable-header">
                <div class="datatable-header-item col-12">{{ ('Relations.Email' | trans) }}</div>
            </div>
            @for (user of customer.invite.users; track user.id) {
                <div class="datatable-row">
                    <div class="datatable-row-item col-12">{{ user.email }}</div>
                </div>
            }

            @if (!customer.invite.users || customer.invite.users.length === 0) {
                <div class="datatable-row">
                    <div class="datatable-row-item col-12">{{ ('Relations.Invite.No_Users_Found' | trans) }}</div>
                </div>
            }
        </div>
    </div>
}

<div class="page-section">
    <div class="row">
        <div class="col-12 text-right">
            @if (error) {
                <span class="cl-error-color">{{ error }}</span>
            }

            @if (!customer.invite) {
                <cm-button
                    (click)="enableInvite(customer)"
                    [attr.data-label]="('Relations.Invite_' + (customer.inviteOrganisationId ? 'Activated' : 'Activate') | trans)"
                    [disabled]="customer.inviteOrganisationId ? true : null"
                    data-button-style="primary"
                ></cm-button>
            }

            @if (customer.inviteOrganisationId && !inviteForm) {
                <cm-button
                    (click)="inviteForm = !inviteForm"
                    [attr.data-label]="('General.Add' | trans)"
                    data-button-style="primary"
                ></cm-button>
            }
        </div>
    </div>

    @if (inviteForm) {
        <div class="row form-group">
            <div class="col-12 col-md-6">
                <div class="form-row">
                    <div class="form-input">
                        <app-form-input
                            id="email"
                            [formId]="formId"
                            [type]="'email'"
                            [placeholder]="('Order.Email' | trans)"
                            [label]="('Order.Email' | trans)"
                            [value]="inviteUser.email"
                            (valueChange)="inviteUser.email=$event">
                        </app-form-input>
                    </div>
                </div>
            </div>

            <div class="col-12 text-right">
                @if (errorMessage) {
                    <span class="cl-error-color">{{ errorMessage }}</span>
                }

                <cm-button
                    (click)="inviteForm = false;"
                    [attr.data-label]="('General.Cancel' | trans)"
                    data-button-style="secondary"
                ></cm-button>

                <cm-button
                    (click)="addInviteUser()"
                    [disabled]="!isFormValid() ? true : null"
                    [attr.data-label]="('General.Save' | trans)"
                    data-button-style="primary"
                ></cm-button>
            </div>
        </div>
    }
</div>
