import { HttpParams, HttpResponse } from '@angular/common/http';
import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {ActivityLog, mapActivityLog} from '../../models/activity-log.class';
import {ActivityLogService} from '../../services/activity-log-service/activity-log.service';
import {EventService} from '../../services/event-service/event.service';
import {Router} from '@angular/router';
import {FilterSetting} from '../search-filter-container/settings.class';
import {Selected} from '../search-filter-container/selected.class';
import {DialogComponent} from '../dialogs/dialog/dialog.component';

@Component({
    selector: 'app-activity-log',
    templateUrl: './activity-log.component.html',
    styleUrls: ['./activity-log.component.css'],
    standalone: false
})
export class ActivityLogComponent implements OnInit, OnChanges {
    @Input() orderIds: string[];
    @Input() customerIds: string[];

    @ViewChild('activityLogDetailDialog')
    activityLogDetailDialog: DialogComponent;

    // Table
    activityLogsToggle = false;
    activityLogs$: Observable<ActivityLog[]>;
    activityLogsSubject: BehaviorSubject<any> = new BehaviorSubject([]);
    activityLogsTableIndex = 0;
    activityLogsTableTotal = 0;
    activityLogsTablePageSize = 5;
    activityLogsTableLoading = true;
    httpParams: HttpParams;

    // Filter
    filterSettings: FilterSetting[] = [];
    selectedFilters: Selected = new Selected();

    constructor(private activityLogService: ActivityLogService, private eventService: EventService, private router: Router) {
    }

    ngOnInit() {
        this.activityLogs$ = this.activityLogsSubject.asObservable()
            .pipe(
                map((items: ActivityLog[]) => {
                    return items.map(item => {
                        return mapActivityLog(item);
                    });
                })
            );

        this.selectedFilters.activityLogEvents = [];
        this.filterSettings = [
            new FilterSetting('ACTIVITY_LOG_EVENT', 'Activity_Log.Activities', true)
        ];

        this.onFilterUpdate();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.activityLogsTableUpdate();
    }

    activityLogsTableUpdate(pageEvent?: PageEvent) {
        pageEvent = pageEvent ? pageEvent : {pageIndex: 0, pageSize: 5, length: 0};
        this.activityLogsTableIndex = pageEvent.pageIndex;
        this.activityLogsTablePageSize = pageEvent.pageSize;
        this.activityLogsTableLoading = true;
        this.activityLogsToggle = false;
        let params = new HttpParams()
            .set('skip', (pageEvent.pageIndex * pageEvent.pageSize).toString())
            .set('take', (pageEvent.pageSize).toString());

        if (this.orderIds && this.orderIds.length > 0) {
            params = params.set('activityLog[orderIds]', this.orderIds.join(','));
        }

        if (this.customerIds && this.customerIds.length > 0) {
            params = params.set('activityLog[customerIds]', this.customerIds.join(','));
        }

        if (this.selectedFilters.activityLogEvents && this.selectedFilters.activityLogEvents.length > 0) {
            params = params.set('activityLog[events]', this.selectedFilters.activityLogEvents.join(','));
        }

        this.activityLogService.getActivityLogs(params)
            .subscribe((response: HttpResponse<ActivityLog[]>) => {
                this.activityLogsTableTotal = parseInt(response.headers.get('X-CM-PAGINATION-TOTAL'), 10);
                this.activityLogsSubject.next(response.body);
                this.activityLogsTableLoading = false;
            });
    }

    onOrderClicked(orderId: string) {
        this.router.navigate(['orders', orderId]);
    }

    onCustomerClicked(customerId: string) {
        this.router.navigate(['customers', customerId]);
    }

    onFilterUpdate() {
        let params = new HttpParams();

        if (this.selectedFilters.activityLogEvents && this.selectedFilters.activityLogEvents.length > 0) {
            params = params.set('activityLog[events]', this.selectedFilters.activityLogEvents.join(','));
        }

        this.httpParams = params;

        this.activityLogsTableUpdate();
    }

    openActivityLogDetailDialog(activityLog: ActivityLog): void {
        this.activityLogDetailDialog.setData({activityLog});
        this.activityLogDetailDialog.open();
    }
}
