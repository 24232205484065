import {Component, OnInit} from '@angular/core';
import {PrintService} from '../../services/print-service/print.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {formatDate} from '@angular/common';
import {MaintenanceMessage, MaintenanceModeService} from '../../services/maintenance-mode.service';
import {LocaleService} from '../../../core/services/locale.service';
import {AppService} from '../../../app.service';

@Component({
    selector: 'app-page-wrapper',
    templateUrl: './page-wrapper.component.html',
    styleUrls: ['./page-wrapper.component.scss'],
    standalone: false
})
export class PageWrapperComponent implements OnInit {
    technicalLinkId: string | undefined;

    public maintenanceMessage: MaintenanceMessage | undefined;
    public maintenanceMessageTags: { key: string, value: string }[] = [];

    constructor(private printerService: PrintService,
                private clipboard: Clipboard,
                private maintenanceModeService: MaintenanceModeService,
                private localeService: LocaleService,
                private app: AppService) {
    }

    ngOnInit() {
        this.printerService.initialize();
        this.getMaintenanceMessage();

        this.technicalLinkId = this.app.config('organisation').technicalLinkId;
    }

    public copyToClipboard(data: string): void {
        if (!data) {
            return;
        }

        this.clipboard.copy(data);
    }

    public onMaintenanceModeReadMoreClick(): void {
        window.open(this.maintenanceMessage.statusPageUrl, '_blank').focus();
    }

    public onCloseMaintenanceBannerClick(): void {
        this.maintenanceMessage = null;
    }

    private getMaintenanceMessage(): void {
        this.maintenanceModeService.getMaintenanceMessage().subscribe((maintenanceMessage) => {
            this.maintenanceMessage = maintenanceMessage;
            this.maintenanceMessageTags = [
                {
                    key: 'startDate',
                    value: formatDate(maintenanceMessage.startAt, 'fullDate', this.localeService.getLanguage())
                },
                {
                    key: 'startTime',
                    value: formatDate(maintenanceMessage.startAt, 'HH:mm', this.localeService.getLanguage())
                },
                {
                    key: 'endDate',
                    value: formatDate(maintenanceMessage.endAt, 'fullDate', this.localeService.getLanguage())
                },
                {
                    key: 'endTime',
                    value: formatDate(maintenanceMessage.endAt, 'HH:mm', this.localeService.getLanguage())
                }
            ];
        });
    }
}
