import {Pipe, PipeTransform} from '@angular/core';
import {TranslationPipe} from './translation.pipe';
import {CurrencyPipe} from '@angular/common';
import {Order} from '../../shared/models/order.class';
import moment from 'moment';
import {LocaleService} from '../services/locale.service';
import {generateLoadingRows} from '../helpers/table-helper';

@Pipe({
    name: 'orderTableRow',
    standalone: false
})
export class OrderTableRowPipe implements PipeTransform {

    private statusClasses = {
        CANCELED: 'cm-icon-warning cl-error-color',
        EXPIRED: 'cm-icon-time-fill cl-error-color',
        COMPLETE: 'cm-icon-verification cl-success-color',
        RESERVED: 'cm-icon-time-fill cl-warning-color',
        OPEN: 'cm-icon-creditcard cl-warning-color',
        REFUNDED: 'cm-icon-refund bg-default-color'
    };

    constructor(private translationPipe: TranslationPipe, private localeService: LocaleService, private currencyPipe: CurrencyPipe) {
    }

    transform(orders: Order[], ...args: any[]): any[] {
        const isLoading = args[0] || false;

        if (isLoading) {
            return generateLoadingRows(8);
        }

        return orders.map((order, i) => {

            const actionOptions = [
                {dataDisplay: this.translationPipe.transform('Order_List.Actions.View'), dataIcon: 'show', id: 'VIEW'}
            ];

            if (order.status === 'COMPLETE') {
                actionOptions.push({
                    dataDisplay: this.translationPipe.transform('Order_List.Actions.Resend'),
                    dataIcon: 'email',
                    id: 'RESEND'
                });
            }

            return {
                id: order.id,
                rowData: [
                    {
                        value: `<span class="cm-icon small ${this.statusClasses[order.status]}"></span>`,
                        options: [{name: 'data-icon', value: ''}]
                    },
                    {
                        value: order.batchId,
                        options: []
                    },
                    {
                        value: order.customer ? order.customer.getName() : '',
                        options: []
                    },
                    {
                        value: order.orderItemAmount,
                        options: []
                    },
                    {
                        value: this.currencyPipe.transform(order.totalPrice),
                        options: []
                    },
                    {
                        value: (order.status !== 'COMPLETE' && moment(order.expireAt).isAfter(moment())) ? moment(order.expireAt).fromNow() : null,
                        options: []
                    },
                    {
                        value: moment().diff(moment(order.createdAt), 'days') < 7 ? moment(order.createdAt).fromNow() : moment(order.createdAt).format('LL'),
                        options: []
                    },
                    {
                        value: actionOptions,
                        options: [{name: 'data-action', value: true}]
                    }
                ]
            };
        });
    }

}
