import {Component, ContentChild, EventEmitter, Host, Input, OnInit, Optional, Output, TemplateRef} from '@angular/core';
import {KeyValue} from '@angular/common';
import {TableCheckboxesDirective} from '../../directives/table-checkboxes.directive';
import {TableRow} from '../../models/abstract/table-row';
import {BulkSelectMethod} from '../../../orders/services/bulk-select';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    standalone: false
})
export class TableComponent implements OnInit {

    @Input()
    data: TableRow[];

    @Input()
    isLoading: boolean;

    @Output()
    rowClick: EventEmitter<any> = new EventEmitter<any>();

    @ContentChild('headers')
    headers: TemplateRef<any> | undefined;

    @ContentChild('rows')
    rows: TemplateRef<any> | undefined;

    @ContentChild('loadingRows')
    loadingRows: TemplateRef<any> | undefined;

    isCheckboxes: boolean;

    public loadingRowArray = Array(10);

    constructor(@Optional() @Host() readonly checkboxesDirective: TableCheckboxesDirective) {
    }

    ngOnInit(): void {
        this.isCheckboxes = !!this.checkboxesDirective;
    }

    public onRowClick(e: Event, row: any): void {
        const target = (e.target as HTMLElement).tagName;

        // Don't emit when clicking checkboxes
        if (target === 'INPUT' || target === 'SPAN') {
            return;
        }

        this.rowClick.emit(row);
    }

    public isAllCheckboxChecked(): true | undefined {
        if (!this.checkboxesDirective) {
            return undefined;
        }

        return this.checkboxesDirective.bulkSelect.method === BulkSelectMethod.ALL ? true : undefined;
    }

    public areSomeSelected(): true | undefined {
        if (!this.checkboxesDirective) {
            return undefined;
        }

        return (
            !(this.checkboxesDirective.bulkSelect.selectedIds.length === this.checkboxesDirective.bulkSelect.total)
            && this.checkboxesDirective.bulkSelect.selectedIds.length
        ) ? true : undefined;
    }

    public isRowSelected(row: TableRow): true | undefined {
        if (!this.checkboxesDirective) {
            return undefined;
        }

        const method = this.checkboxesDirective.bulkSelect.method;

        if (method === BulkSelectMethod.ALL) {
            return true;
        }

        if (method === BulkSelectMethod.ONLY) {
            return this.checkboxesDirective.bulkSelect.selectedIds.includes(row.id) ? true : undefined;
        }

        if (method === BulkSelectMethod.EXCEPT) {
            return !this.checkboxesDirective.bulkSelect.selectedIds.includes(row.id) ? true : undefined;
        }
    }

    // The keyvalue pipe returns things in an alphabetic order -- this function overrides that
    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

}
