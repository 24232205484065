import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter',
    standalone: false
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], filter: string): any[] {
        if (!items) {
            return [];
        }
        if (!filter) {
            return items;
        }

        filter = filter.toLowerCase();

        return items.filter(item => {
            return item.name.toLowerCase().includes(filter);
        });
    }

}
