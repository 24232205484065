
<div class="form-group status-settings">
    <cm-select [attr.data-label]="'Sale_Status_Settings.State' | trans"
               class="form-row"
               (change)="setStatus($event.detail.value)"
               [value]="saleSubject.status">
        @for (status of statuses; track status.id) {
            <cm-option slot="option"
                       [attr.data-display]="status.value"
                       [attr.value]="status.id"
                       [attr.data-icon]="status.icon"></cm-option>
        }
    </cm-select>

    @if (saleSubject.status === 'SOLD_OUT') {
        <div class="form-row">
            <app-input-translation
                formId="statusText"
                type="text"
                [required]="false"
                [maxLength]="30"
                [(value)]="saleSubject.statusDescription"
                [translation]="saleSubject.statusDescriptionTranslation"
                (valueChange)="validateSettingsForm()"
                [label]="('Sale_Status_Settings.Status_Text' | trans)">
            </app-input-translation>
        </div>
    }
    <cm-button
        class="p-t-24"
        data-icon="save"
        data-button-style="cta"
        data-button-type="icon-text"
        [attr.data-label]="('General.Save' | trans)"
        (click)="saveStatus()">
    </cm-button>
</div>
