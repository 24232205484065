import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {OrderDistributionService} from '../../services/order-distribution.service';
import {Observable, of} from 'rxjs';
import {EmailTemplateGroup} from '../../models/email-template-group';
import {catchError} from 'rxjs/operators';

@Component({
    selector: 'cm-ticketing-email-template-group-list',
    templateUrl: './email-template-group-list.component.html',
    styleUrls: ['./email-template-group-list.component.css'],
    standalone: false
})
export class EmailTemplateGroupListComponent implements OnInit {

    public emailTemplateGroups$: Observable<EmailTemplateGroup[]>;
    public displayEmptyState = false;

    private isVisible = true;

    @Output()
    emailTemplateGroupClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    emailTemplateGroupDelete: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    emailTemplateGroupRestore: EventEmitter<any> = new EventEmitter<any>();

    constructor(private orderDistributionService: OrderDistributionService) {
    }

    ngOnInit(): void {
        this.getEmailTemplateGroups();
    }

    onEmailTemplateGroupClick(emailTemplateGroup): void {
        this.emailTemplateGroupClick.emit(emailTemplateGroup);
    }

    onDeleteEmailTemplateGroupClick(emailTemplateGroup): void {
        this.orderDistributionService.deleteEmailTemplateGroup(emailTemplateGroup).subscribe(() => {
            this.getEmailTemplateGroups();
            this.emailTemplateGroupDelete.emit(emailTemplateGroup);
        });
    }

    public onRestoreEmailTemplateGroupClick(emailTemplateGroup): void {
        this.orderDistributionService.restoreEmailTemplateGroup(emailTemplateGroup).subscribe(() => {
            this.getEmailTemplateGroups();
            this.emailTemplateGroupRestore.emit(emailTemplateGroup);
        });
    }

    public toggleIsVisible(): void {
        this.isVisible = !this.isVisible;

        this.getEmailTemplateGroups();
    }

    private getEmailTemplateGroups(): void {
        this.displayEmptyState = false;

        this.emailTemplateGroups$ = this.orderDistributionService.getEmailTemplateGroups(this.isVisible).pipe(
            catchError(() => {
                this.displayEmptyState = true;
                return of([]);
            })
        );
    }

}
