import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[cm-tooltip]',
    standalone: false
})
export class TooltipDirective implements AfterViewInit, OnChanges {

    @Input('cm-tooltip') tooltipText: string;

    private readonly element;
    private readonly tooltipElement;

    constructor(el: ElementRef) {
        this.element = el.nativeElement;
        this.tooltipElement = document.createElement('cm-tooltip');
    }

    ngAfterViewInit() {
        const parentElement = this.element.parentElement;

        this.tooltipElement.setAttribute('data-tooltip-text', this.tooltipText);

        parentElement.insertBefore(this.tooltipElement, this.element);
        this.tooltipElement.appendChild(this.element);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.tooltipElement.setAttribute('data-tooltip-text', this.tooltipText);
    }

}
