<ng-container *ngIf="type === 'DATA'">
    <cm-table-header #tableDataHeader (cm-sort-table-changed)="sortChanged(id, $event)"
                     (stick-column)="stickColumnCalled($event)" [id]="id" [innerHTML]="value | byPassSecurity">
    </cm-table-header>
</ng-container>

<ng-container *ngIf="type === 'CHECKBOX'">
    <cm-table-header #tableDataHeader data-action-center>
        <div class="form-group">
            <div class="form-row example">
                <div class="form-check">
                    <label>
                        <input type="checkbox" value="Checked value"
                               [class.pseudo-state-indeterminate]="checkBoxState === 'INTERMEDIATE'"
                               [checked]="['CHECKED', 'INTERMEDIATE'].includes(checkBoxState) ? true : null"
                               (change)="onCheckboxChange($event)"/>
                        <span class="label"></span>
                    </label>
                </div>
            </div>
        </div>
    </cm-table-header>
</ng-container>

<ng-container *ngIf="type === 'ACTION'">
    <cm-table-header #tableDataHeader>
        <span>{{value.label ? value.label : value}}</span>
        <ng-container *ngIf="value.options && value.options.length > 0">
            <cm-context-menu slot="action">
                <ng-container *ngFor="let option of value.options">
                    <ng-container *ngIf="option.menuLine">
                        <cm-context-menu-line></cm-context-menu-line>
                    </ng-container>
                    <ng-container *ngIf="!option.menuLine">
                        <cm-option (cm-option-clicked)="actionClicked($event)" [id]="option.id"
                                   [disabled]="option.disabled ? option.disabled : null"
                                   [attr.title]="option.title ? option.title : null"
                                   [attr.data-display]="option.dataDisplay" [attr.data-icon]="option.dataIcon">
                        </cm-option>
                    </ng-container>
                </ng-container>
            </cm-context-menu>
        </ng-container>
    </cm-table-header>
</ng-container>
