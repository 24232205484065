import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from '../../../../../services/translation.service';

@Component({
    selector: 'cm-ticketing-a4-custom',
    templateUrl: '../../shared/ticket-template-shared-custom.component.html',
    styleUrls: ['./ticket-template-a4-custom.component.scss', '../../shared/shared-presets.scss'],
    standalone: false
})
export class TicketTemplateA4CustomComponent implements OnInit {

    @Input() currentLang = 'nl';

    constructor(private translationService: TranslationService) { }

    ngOnInit(): void {
        this.translationService.setLanguage(this.currentLang);
    }

}
