import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Transformer} from 'konva/lib/shapes/Transformer';
import {OrderDistributionService} from '../../services/order-distribution.service';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {TicketTemplateComponent} from '../../models/ticket-template-component';
import {Line} from 'konva/lib/shapes/Line';
import {Stage} from 'konva/lib/Stage';
import {Layer} from 'konva/lib/Layer';
import {Group} from 'konva/lib/Group';
import {Rect} from 'konva/lib/shapes/Rect';
import {Text} from 'konva/lib/shapes/Text';
import {Image} from 'konva/lib/shapes/Image';
import {TicketTemplateComponentDatabase} from '../../models/ticket-template-component-db';
import {MessageTypeEnum} from '../../models/message-type-enum';
import {Phrase} from '../../interfaces/translation.interface';
import {TranslationService} from '../../services/translation.service';
import {IRect} from 'konva/lib/types';
import {OrientationEnum} from '../../models/orientation-enum';
import {KonvaTypesEnum} from '../../models/konva-types-enum';
import {TemplateTypeEnum} from '../../models/template-type-enum';
import {TicketTemplateComponentTypeEnum} from '../../models/ticket-template-component-type-enum';
import TemplateComponentJson from './ticketcomponent.json';
import TemplateFallbackImage from './ticketfallback.json';
import CMLogo from './cmlogo.json';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {TicketTemplateRender} from '../../models/ticket-template-render';
import {TicketTemplateInformation} from '../../models/ticket-template-information';
import {TicketTemplateConstrain} from '../../models/ticket-template-constrain';
import {TicketTemplateComponentType} from '../../models/ticket-template-component-type';
import {NotificationHelper} from '../../helpers/notification-helper';
import 'konva';

@Component({
    selector: 'cm-ticketing-ticket-template-gui',
    templateUrl: './ticket-template-gui.component.html',
    styleUrls: ['./ticket-template-gui.component.scss'],
    standalone: false
})
export class TicketTemplateGuiComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('componentDialog') componentDialog: ElementRef;
    @ViewChild('confirmationModal') confirmationModal: ElementRef;
    @ViewChild('viewPortModal') viewPortModal: ElementRef;
    @ViewChild('imageNotLoadedModal') imageNotLoadedModal: ElementRef;
    @ViewChild('konvaContainer')
    public konvaContainer: ElementRef<HTMLDivElement>;

    @Input() ticketTemplateType;
    @Input() ticketTemplatePresetUuid;
    @Input() isCMBrandingEnabled = true;
    @Input() ticketTemplateUuid;
    @Input() originTypeId = null;
    @Input() currentLang = 'nl';
    @Output() ticketTemplateSaved: EventEmitter<any> = new EventEmitter<any>();

    public templateName: string = null;
    public backgroundColor = '#FFFFFF';
    public templateType: string = null;
    public templateTypeWithDimensions: string;
    public ticketTemplatesComponents;
    public componentStream = new BehaviorSubject<TicketTemplateComponent>(null);
    public component$ = this.componentStream.asObservable();
    public searchSelectedValue = null;
    public selectionComponentStream = new BehaviorSubject(null);
    public selectionComponent$ = this.selectionComponentStream.asObservable();
    public ticketTemplateInformation: TicketTemplateInformation;
    public ticketTemplateConstraints: TicketTemplateConstrain[];
    public ticketTemplateComponentTypes: TicketTemplateComponentType[];
    public isLoading = false;
    public isRenderingCanvas = true;
    public uuid: string;
    public isUpdating: boolean;
    public isViewPortTooLow: boolean;
    public hasImageLoadError = false;
    public qualityImagePercentage: number;
    public aurora1MBFileSize = '1048576';

    public shallowTicketTemplateInformation = {
        width: null,
        height: null,
        ratio: null,
    };

    public printableTicketBoxOptions: {
        event_date: boolean,
        expiry_date: boolean,
        ticket_price: boolean,
        purchase_date: boolean
    } = {
        event_date: false,
        expiry_date: false,
        ticket_price: false,
        purchase_date: false
    };

    private readonly minPrintMarginY = 30;
    private readonly minPrintMarginX = 30;
    public sizeBoundariesForTicketTemplates: {
        min_y: number,
        max_y: number,
        min_x: number,
        max_x: number
    } = {
        min_y: this.minPrintMarginY,
        max_y: 0,
        min_x: this.minPrintMarginX,
        max_x: 0
    };

    private blockSnapSize = 15;
    private roundingDecimals = 2;
    private stage: Stage = null;
    private baseLayer: Layer = null;
    private transformer: Transformer = null;
    private dragInformation = null;
    private shadowRectangle = null;
    private scale: number;

    private fontSizeBoundaries = {
        min: 1,
        max: 30,
        default: 18
    };

    // ROTATION VARIABLES
    private tempKonvaPointerPositionX = null;
    private tempKonvaPointerPositionY = null;
    private tempImage = null;
    private tempOldPosition = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        rotation: 0
    };
    private hasErrorMessage = false;
    private errorMessageThrottleTime = 1000;
    private inputTimeOut: any;
    private inputTimeOutTime = 1000;
    private changeArray = [];
    private routerSubscription: any;
    private navigateTo: string;
    private allowNavigate = false;
    private isSavingTemplate = false;
    private maxFileSizeMB = 1;
    private oneHundredPercent = 100;
    private divideByThreeToGetAverage = 3;
    private bisect = 2;

    constructor(private orderDistributionService: OrderDistributionService, private translationService: TranslationService,
                private router: Router, public breakpointObserver: BreakpointObserver) {
    }

    ngOnDestroy(): void {
        this.componentDialog.nativeElement.remove();
        this.confirmationModal.nativeElement.remove();
        this.routerSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.translationService.setLanguage(this.currentLang);
    }

    ngAfterViewInit(): void {
        this.breakpointObserver
            .observe(['(min-width: 1199px)'])
            .subscribe((state: BreakpointState) => {
                if (!state.matches) {
                    this.isViewPortTooLow = true;

                    setTimeout(() => {
                        this.viewPortModal.nativeElement.open();
                        this.isRenderingCanvas = false;
                    });
                    return;
                }

                this.isViewPortTooLow = false;
                if (this.ticketTemplateUuid) {
                    this.orderDistributionService.getTicketTemplate(this.ticketTemplateUuid).subscribe((ticketTemplate) => {
                        this.retrieveComponentsForType(ticketTemplate);
                    });
                } else {
                    this.retrieveComponentsForType();
                }
                this.preventNavigationGuard();
            });
    }

    onCancel(): void {
        this.confirmationModal.nativeElement.close();
    }

    onComponentDialogClose(): void {
        this.componentDialog.nativeElement.close();
    }

    onAccept(): void {
        this.allowNavigate = true;
        this.router.navigate([this.navigateTo]);
        this.confirmationModal.nativeElement.close();
    }

    preventNavigationGuard(): void {
        this.routerSubscription = this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart && (this.changeArray.length > 0 && !this.allowNavigate) && !this.isSavingTemplate) {
                this.navigateTo = event.url;
                const currentRoute = this.router.routerState;
                this.router.navigateByUrl(currentRoute.snapshot.url, {skipLocationChange: true});
                this.confirmationModal.nativeElement.open();
            }
        });
    }

    // CREATES INITIAL STATE FOR WHOLE COMPONENT (BASED ON INPUT)
    retrieveComponentsForType(ticketTemplate?: any): void {
        this.isUpdating = true;
        this.templateType = this.ticketTemplateUuid ? ticketTemplate.ticket_template_type_id : this.ticketTemplateType;

        if (ticketTemplate) {
            this.setBackgroundColor(ticketTemplate.background_color);
        } else {
            this.setBackgroundColor(this.backgroundColor);
        }

        const componentTypesObserver = this.orderDistributionService.getTicketTemplateComponentTypes();
        const informationObserver = this.orderDistributionService.getTicketTemplateType(this.templateType);
        const componentsObserver = this.orderDistributionService.getTicketTemplateComponentsByType(this.templateType, this.originTypeId);
        const constrainObserver = this.orderDistributionService.getTicketTemplateComponentsConstrainsByType(this.templateType);

        combineLatest([componentTypesObserver, informationObserver, componentsObserver, constrainObserver]).subscribe(
            ([ticketTemplateComponentTypes, ticketTemplateInformation, ticketTemplatesComponents, ticketTemplateConstrains]) => {

                this.ticketTemplateComponentTypes = ticketTemplateComponentTypes;
                this.ticketTemplateInformation = new TicketTemplateInformation(ticketTemplateInformation);
                this.ticketTemplatesComponents = Object.values(ticketTemplatesComponents);
                this.ticketTemplateConstraints = ticketTemplateConstrains;

                this.translateTicketTemplatesComponents();

                this.filterUnusedComponentTypes();

                this.shallowTicketTemplateInformation.width = this.ticketTemplateInformation.width_points;
                this.shallowTicketTemplateInformation.height = this.ticketTemplateInformation.height_points;

                this.setTemplateTypeAsString();
                this.calculateRatioBasedOnTicketTemplateType();

                setTimeout(() => {
                    this.renderCanvas();
                    this.isRenderingCanvas = false;
                    this.enableTransformingShape();
                    this.calculateScale();
                    this.retrievingComponentsWhenUpdating();
                    this.enableDraggingPerComponentType();
                    this.enableClickingPerComponentType();

                    this.componentDialog.nativeElement.addEventListener('cm-dialog-closed', () => {
                        this.clearDialogInformation();
                    });
                }, 0);
            }
        );
    }

    // INITIALIZING HELPERS
    renderCanvas(): void {
        const container = this.konvaContainer.nativeElement;

        this.stage = new Stage({
            konvaType: KonvaTypesEnum.STAGE,
            container,
            width: container.offsetWidth,
            height: container.offsetHeight ? container.offsetHeight : 985,
        });
        const stageWidth = this.stage.width();
        const stageWeight = this.stage.height();

        const gridLayer = new Layer();

        this.setPrintMarginsForTheCanvas(stageWidth, stageWeight);

        this.createCanvasGridLayers(stageWidth, gridLayer, stageWeight);

        this.drawABoundaryLineForTheWholeTemplate();

        this.drawDottedRippingBocaLine();

        this.enableTransformerOnCanvas();

        this.stage.add(gridLayer);
        this.stage.add(this.baseLayer);
    }

    enableTransformerOnCanvas(): void {
        this.transformer = new Transformer({
            konvaType: KonvaTypesEnum.TRANSFORMER,
            rotationSnaps: [0, 45, 90, 135, 180, 225, 270, 315, 360],
        });

        this.baseLayer.add(this.transformer);
    }

    drawDottedRippingBocaLine(): void {
        if (this.ticketTemplateInformation.orientation !== OrientationEnum.HORIZONTAL ||
            this.ticketTemplateInformation.id !== TemplateTypeEnum.BOCA) {
            return;
        }

        const lineBoca = new Line({
            konvaType: KonvaTypesEnum.BOCALINE,
            points: [
                (this.sizeBoundariesForTicketTemplates.max_x - (this.blockSnapSize * 10)), this.sizeBoundariesForTicketTemplates.min_y,
                (this.sizeBoundariesForTicketTemplates.max_x - (this.blockSnapSize * 10)), this.sizeBoundariesForTicketTemplates.max_y
            ],
            stroke: 'rgb(102, 178, 255)',
            dash: [10, 10],

        });

        this.baseLayer.add(lineBoca);
    }

    drawABoundaryLineForTheWholeTemplate(): void {
        this.baseLayer = new Layer({
            konvaType: KonvaTypesEnum.LAYER
        });

        const line = new Line({
            konvaType: KonvaTypesEnum.BOUNDARYLINE,
            points: [
                this.sizeBoundariesForTicketTemplates.min_x, this.sizeBoundariesForTicketTemplates.min_y,
                this.sizeBoundariesForTicketTemplates.max_x, this.sizeBoundariesForTicketTemplates.min_y,
                this.sizeBoundariesForTicketTemplates.max_x, this.sizeBoundariesForTicketTemplates.max_y,
                this.sizeBoundariesForTicketTemplates.min_x, this.sizeBoundariesForTicketTemplates.max_y,
                this.sizeBoundariesForTicketTemplates.min_x, this.sizeBoundariesForTicketTemplates.min_y,
            ],
            stroke: 'rgb(102, 178, 255)',
            dash: [33, 10],

        });

        this.baseLayer.add(line);
    }

    createCanvasGridLayers(stageWidth: number, gridLayer: Layer, stageWeight: number): void {
        const gridLayerPadding = this.blockSnapSize;

        for (let i = 0; i < stageWidth / gridLayerPadding; i++) {
            gridLayer.add(new Line({
                konvaType: KonvaTypesEnum.GRIDLINE,
                points: [Math.round(i * gridLayerPadding) + 0.5, 0, Math.round(i * gridLayerPadding) + 0.5, stageWeight],
                stroke: '#ddd',
                strokeWidth: 1,
            }));
        }

        for (let j = 0; j < stageWeight / gridLayerPadding; j++) {
            gridLayer.add(new Line({
                konvaType: KonvaTypesEnum.GRIDLINE,
                points: [0, Math.round(j * gridLayerPadding), stageWidth, Math.round(j * gridLayerPadding)],
                stroke: '#ddd',
                strokeWidth: 0.5,
            }));
        }
    }

    setPrintMarginsForTheCanvas(stageWidth: number, stageWeight: number): void {
        const stageMarginX = this.blockSnapSize * 2;
        this.sizeBoundariesForTicketTemplates.max_x = stageWidth - stageMarginX;
        const stageMarginY = this.blockSnapSize * 2;
        this.sizeBoundariesForTicketTemplates.max_y = stageWeight - stageMarginY;
    }

    retrievingComponentsWhenUpdating(): void {
        // ticketTemplateUuid -> parameter being set when updating existing template.
        // ticketTemplatePresetUuid -> parameter being set when loading a preset on the template for creating a new template.
        if (!this.ticketTemplateUuid && !this.ticketTemplatePresetUuid) {
            this.isUpdating = false;
            return;
        }

        const ticketTemplateUuid = this.ticketTemplatePresetUuid ?? this.ticketTemplateUuid;

        this.orderDistributionService.getTicketTemplateInformation(ticketTemplateUuid).subscribe((ticketTemplateResponse: any) => {
            const ticketTemplate = ticketTemplateResponse;

            const ticketTemplateInformation = ticketTemplate.ticket_template_information;

            if (this.ticketTemplateUuid) {
                this.templateName = ticketTemplate.name;
            }

            this.renderItemsOnCanvasWhenUpdating(ticketTemplateInformation);

            this.isUpdating = false;
        });
    }

    renderItemsOnCanvasWhenUpdating(ticketTemplateInformation): void {
        ticketTemplateInformation.forEach(selection => {
            let ticketTemplateComponent = new TicketTemplateComponentDatabase({
                position_x: selection.x_position_points,
                position_y: selection.y_position_points,
                height: selection.height_points,
                width: selection.width_points
            });

            if (this.ticketTemplateInformation.orientation === OrientationEnum.HORIZONTAL) {
                ticketTemplateComponent = this.rotateComponentCounterwise(ticketTemplateComponent);
            }

            let x = Number((ticketTemplateComponent.position_x * this.scale).toFixed(this.roundingDecimals));
            let y = Number((ticketTemplateComponent.position_y * this.scale).toFixed(this.roundingDecimals));

            if (x < this.sizeBoundariesForTicketTemplates.min_x) {
                x = this.sizeBoundariesForTicketTemplates.min_x;
            }

            if (y < this.sizeBoundariesForTicketTemplates.min_y) {
                y = this.sizeBoundariesForTicketTemplates.min_y;
            }
            const width = Number((ticketTemplateComponent.width * this.scale).toFixed(this.roundingDecimals));
            const height = Number((ticketTemplateComponent.height * this.scale).toFixed(this.roundingDecimals));

            const ticketTemplateRender = new TicketTemplateRender({
                x, y, width, height, rotation: selection.rotation,
                ticketTemplateComponent: selection.ticket_template_component,
                layer: this.baseLayer, additionalInformation: selection.value
            });

            switch (selection.ticket_template_component.ticket_template_component_type_id) {
                case 'TEXT':
                    this.renderRectangleWithText(ticketTemplateRender);
                    break;
                case 'BARCODE':
                    this.renderRectangleWithBarcode(ticketTemplateRender);
                    break;
                case 'IMAGE':
                    this.renderRectangleWithImage(ticketTemplateRender);
                    break;
                case 'TICKET_COMPONENT':
                    this.renderRectangleWithTicket(ticketTemplateRender);
                    break;
                default:
                    this.renderRectangleWithText(ticketTemplateRender);
            }
        });
    }

    enableClickingPerComponentType(): void {
        this.ticketTemplateComponentTypes.forEach((ticketTemplateComponent) => {

            setTimeout(() => {
                const element = document.getElementById(ticketTemplateComponent.id + '-' + ticketTemplateComponent.name);
                element.addEventListener('click', () => {
                    this.dragInformation = [element.getAttribute('type'),
                        element.getAttribute('name'),
                        element.getAttribute('componentType')
                    ];

                    const availableComponents = this.ticketTemplatesComponents.filter(
                        (component) => component.ticket_template_component_type_id === this.dragInformation[0]);
                    availableComponents.sort((a, b) => a.display_order - b.display_order);

                    this.tempKonvaPointerPositionX = this.sizeBoundariesForTicketTemplates.min_x;
                    this.tempKonvaPointerPositionY = this.sizeBoundariesForTicketTemplates.min_y;

                    this.renderSelectedComponentInitializer(availableComponents);
                });
            }, (300));
        });
    }

    enableDraggingPerComponentType(): void {
        this.konvaContainer.nativeElement.addEventListener('dragover', (e) => {
            e.preventDefault();
        });

        this.ticketTemplateComponentTypes.forEach((ticketTemplateComponent) => {
            setTimeout(() => {
                const element = document.getElementById(ticketTemplateComponent.id + '-' + ticketTemplateComponent.name);
                element.addEventListener('dragstart', (dragEvent: DragEvent) => {
                    this.dragInformation = [element.getAttribute('type'),
                        element.getAttribute('name'),
                        element.getAttribute('componentType')
                    ];
                    this.setDragImage(dragEvent, element);
                }, {passive: true});
            }, (300));
        });

        this.konvaContainer.nativeElement.addEventListener('drop', (e) => {
            this.stage.setPointersPositions(e);
            const availableComponents = this.ticketTemplatesComponents.filter(
                (component) => component.ticket_template_component_type_id === this.dragInformation[0]);
            availableComponents.sort((a, b) => a.display_order - b.display_order);

            this.tempKonvaPointerPositionX = this.stage.getPointerPosition().x > this.sizeBoundariesForTicketTemplates.min_x &&
            this.stage.getPointerPosition().x < this.sizeBoundariesForTicketTemplates.max_x ?
                this.stage.getPointerPosition().x : this.sizeBoundariesForTicketTemplates.min_x;

            this.tempKonvaPointerPositionY = this.stage.getPointerPosition().y > this.sizeBoundariesForTicketTemplates.min_y &&
            this.stage.getPointerPosition().y < this.sizeBoundariesForTicketTemplates.max_y ?
                this.stage.getPointerPosition().y : this.sizeBoundariesForTicketTemplates.min_y;

            this.renderSelectedComponentInitializer(availableComponents);
        });

    }

    setDragImage(dragEvent, element): void {
        const cloneElement: any = element.children[0].cloneNode(element.children[0] as any);
        cloneElement.style.backgroundColor = 'var(--selected-background)';
        cloneElement.style.position = 'fixed';
        cloneElement.style.left = '0px';
        cloneElement.style.top = '0px';

        document.body.appendChild(cloneElement);
        dragEvent.dataTransfer.setDragImage(cloneElement, 22, 22);
        requestAnimationFrame(() => {
            cloneElement.remove();
        });
    }

    enableTransformingShape(): void {
        this.stage.on('click tap', (e) => {
            if (e.target.getAttrs().konvaType === KonvaTypesEnum.STAGE || e.target.getAttrs().konvaType === KonvaTypesEnum.GRIDLINE ||
                e.target.getAttrs().konvaType === KonvaTypesEnum.BOUNDARYLINE || e.target.getAttrs().konvaType === KonvaTypesEnum.LAYER ||
                e.target.getAttrs().konvaType === KonvaTypesEnum.BOCALINE || e.target.getAttrs().konvaType === KonvaTypesEnum.TRANSFORMER ||
                e.target.getAttrs().konvaType === undefined
            ) {
                this.componentStream.next(null);
                this.transformer.nodes([]);
                return;
            }

            const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
            const isSelected = this.transformer.nodes().indexOf(e.target) >= 0;

            if (!metaPressed && !isSelected && e.target.getAttrs().konvaType !== KonvaTypesEnum.RECT) {
                const topIndex = this.baseLayer.children.length - 1;
                this.transformer.nodes([e.target.parent]);
                this.transformer.zIndex(topIndex);
                this.updateComponentAfterManipulations(e.target.parent as Group);
            }
        });
    }

    translateTicketTemplatesComponents(): void {
        this.ticketTemplatesComponents.forEach(component => {
            let key = 'Ticket_Template_Component.' + component.id + '_title';
            this.translationService.getPhraseForLanguage(key).subscribe((translationObject: Phrase) => {
                component.title = translationObject.Value;
            });

            key = 'Ticket_Template_Component.' + component.id + '_description';

            this.translationService.getPhraseForLanguage(key).subscribe((translationObject: Phrase) => {
                component.description = translationObject.Value;
            });
        });

    }

    filterUnusedComponentTypes(): void {
        this.ticketTemplateComponentTypes.forEach((type) => {
            const elementCount = this.ticketTemplatesComponents.filter(
                component => component.ticket_template_component_type_id === type.id);
            if (elementCount.length === 0) {
                this.ticketTemplateComponentTypes = this.ticketTemplateComponentTypes.filter(typeF => typeF.id !== type.id);
            }
        });
    }

    calculateRatioBasedOnTicketTemplateType(): void {
        if (this.ticketTemplateInformation.orientation === OrientationEnum.HORIZONTAL) {
            this.shallowTicketTemplateInformation.ratio =
                this.ticketTemplateInformation.width_points / this.ticketTemplateInformation.height_points;
        } else {
            this.shallowTicketTemplateInformation.ratio =
                this.ticketTemplateInformation.height_points / this.ticketTemplateInformation.width_points;
        }

    }

    calculateScale(): void {
        if (this.ticketTemplateInformation.orientation === OrientationEnum.HORIZONTAL) {
            this.scale = this.konvaContainer.nativeElement.offsetWidth / this.shallowTicketTemplateInformation.height;
        } else {
            this.scale = this.konvaContainer.nativeElement.offsetWidth / this.shallowTicketTemplateInformation.width;
        }
    }

    setTemplateTypeAsString(): void {
        const pointsToPixelConversionRate = 0.0352778;
        const templateHeightInPixelsRounded = (this.shallowTicketTemplateInformation.height * pointsToPixelConversionRate)
            .toFixed(this.roundingDecimals);
        const templateWidthInPixelsRounded = (this.shallowTicketTemplateInformation.width * pointsToPixelConversionRate)
            .toFixed(this.roundingDecimals);

        this.templateTypeWithDimensions = `${this.templateType} | ${templateHeightInPixelsRounded}cm x ${templateWidthInPixelsRounded}cm`;
    }

    // RENDER TYPES
    renderRectangleWithText(ticketTemplateRender: TicketTemplateRender): void {
        let componentWidth = 8;
        let componentHeight = 2;

        if (ticketTemplateRender.ticketTemplateComponent.id === TicketTemplateComponentTypeEnum.DYNAMIC_TEXT_COMPONENT) {
            componentWidth = 12;
            componentHeight = 8;
        }

        const rectangleGroup = this.renderRectangleInitializer(ticketTemplateRender, componentWidth, componentHeight);
        const rectangleTextComponent = this.renderRectangleTextHelper(ticketTemplateRender, componentWidth, componentHeight);

        rectangleGroup.add(rectangleTextComponent);
        this.drawComponentOnCanvas(rectangleGroup, ticketTemplateRender.layer);
    }

    renderRectangleWithBarcode(ticketTemplateRender: TicketTemplateRender): void {
        let componentWidth = 8;
        let componentHeight = 8;

        if (ticketTemplateRender.ticketTemplateComponent.id === TicketTemplateComponentTypeEnum.BARCODE_C128) {
            componentWidth = 19;
            componentHeight = 6;
        }

        const rectangleGroup = this.renderRectangleInitializer(ticketTemplateRender, componentWidth, componentHeight);

        this.renderRectangleImageHelper(ticketTemplateRender.width, ticketTemplateRender.height, rectangleGroup, componentWidth,
            componentHeight, ticketTemplateRender.ticketTemplateComponent.default_value, true);

        this.drawComponentOnCanvas(rectangleGroup, ticketTemplateRender.layer);
    }

    renderRectangleWithImage(ticketTemplateRender: TicketTemplateRender): void {
        const infoObj = JSON.parse(ticketTemplateRender.additionalInformation);

        const DEFAULT_IMAGE_COMPONENT_WIDTH = 14;
        const DEFAULT_IMAGE_COMPONENT_HEIGHT = 6;
        const DEFAULT_CM_LOGO_COMPONENT_WIDTH = 18;
        const DEFAULT_CM_LOGO_COMPONENT_HEIGHT = 12;

        const componentWidth = ticketTemplateRender.ticketTemplateComponent.id  === TicketTemplateComponentTypeEnum.IMAGE_CM_LOGO ?
            DEFAULT_CM_LOGO_COMPONENT_WIDTH : DEFAULT_IMAGE_COMPONENT_WIDTH;
        const componentHeight = ticketTemplateRender.ticketTemplateComponent.id  === TicketTemplateComponentTypeEnum.IMAGE_CM_LOGO ?
            DEFAULT_CM_LOGO_COMPONENT_HEIGHT : DEFAULT_IMAGE_COMPONENT_HEIGHT;

        const rectangleGroup = this.renderRectangleInitializer(ticketTemplateRender, componentWidth, componentHeight);

        if (ticketTemplateRender.ticketTemplateComponent.id === TicketTemplateComponentTypeEnum.IMAGE_CM_LOGO){
            this.renderRectangleImageHelper(ticketTemplateRender.width, ticketTemplateRender.height, rectangleGroup,
                componentWidth, componentHeight, CMLogo.image, true);
        } else{
            if (infoObj?.image == null) {
                rectangleGroup.add(new Text({
                    konvaType: KonvaTypesEnum.TEXT,
                    text: ticketTemplateRender.ticketTemplateComponent.default_value,
                    fontSize: this.fontSizeBoundaries.default,
                    fontFamily: 'Space Mono',
                    fill: '#000',
                    width: ticketTemplateRender.width ?? this.blockSnapSize * componentWidth,
                    height: ticketTemplateRender.height ?? this.blockSnapSize * componentHeight,
                    padding: 5,
                    id: 'image-text' + rectangleGroup._id,
                    align: 'left'
                }));
            } else {
                this.renderRectangleImageHelper(ticketTemplateRender.width, ticketTemplateRender.height, rectangleGroup,
                    componentWidth, componentHeight, infoObj.image);
            }
        }

        this.drawComponentOnCanvas(rectangleGroup, ticketTemplateRender.layer);
    }

    renderRectangleWithTicket(ticketTemplateRender: TicketTemplateRender): void {
        const shape = this.stage.find('#' + TicketTemplateComponentTypeEnum.TICKET_COMPONENT_HORIZONTAL);
        const infoObj = JSON.parse(ticketTemplateRender.additionalInformation);
        if (shape.length === 0) {
            this.printableTicketBoxOptions = infoObj ?? this.printableTicketBoxOptions;

            const componentWidth = 42.4;
            const componentHeight = 14;

            ticketTemplateRender.x = ticketTemplateRender.rotation != null ?
                Number(ticketTemplateRender.x.toFixed(this.roundingDecimals)) :
                this.sizeBoundariesForTicketTemplates.min_x;

            const rectangleGroup = this.renderRectangleInitializer(ticketTemplateRender, componentWidth, componentHeight);

            this.renderRectangleImageHelper(ticketTemplateRender.width, ticketTemplateRender.height, rectangleGroup,
                componentWidth, componentHeight, TemplateComponentJson.image, true);

            this.drawComponentOnCanvas(rectangleGroup, ticketTemplateRender.layer);

        } else {
            this.displayMessagesToUser('Ticket_Template.Ticket_Component_Minimum', MessageTypeEnum.CUSTOM_ERROR);
        }
    }

    // RENDER TYPES HELPER
    renderRectangleInitializer(ticketTemplateRender: TicketTemplateRender, multiplySnapSizeWidth: number,
                               multiplySnapSizeHeight: number): Group {
        const rectangleGroup = new Group({
            konvaType: KonvaTypesEnum.GROUP,
            x: Number(ticketTemplateRender.x.toFixed(this.roundingDecimals)),
            y: Number(ticketTemplateRender.y.toFixed(this.roundingDecimals)),
            id: ticketTemplateRender.ticketTemplateComponent.id,
            type: ticketTemplateRender.ticketTemplateComponent.ticket_template_component_type_id,
            title: ticketTemplateRender.ticketTemplateComponent.title,
            width: ticketTemplateRender.width ?? this.blockSnapSize * multiplySnapSizeWidth,
            height: ticketTemplateRender.height ?? this.blockSnapSize * multiplySnapSizeHeight,
            rotation: ticketTemplateRender.rotation ?? 0,
            draggable: true,
        });

        rectangleGroup.add(new Rect({
            konvaType: KonvaTypesEnum.RECT,
            width: ticketTemplateRender.width ?? this.blockSnapSize * multiplySnapSizeWidth,
            height: ticketTemplateRender.height ?? this.blockSnapSize * multiplySnapSizeHeight,
            fill: '#fff',
            stroke: '#ddd',
            strokeWidth: 1,
            shadowColor: 'black',
        }));

        return rectangleGroup;
    }

    renderRectangleTextHelper(ticketTemplateRender: TicketTemplateRender, multiplySnapSizeWidth: number,
                              multiplySnapSizeHeight: number): Text {
        const infoObj = JSON.parse(ticketTemplateRender.additionalInformation);

        return new Text({
            konvaType: KonvaTypesEnum.TEXT,
            text: infoObj?.text ?? ticketTemplateRender.ticketTemplateComponent.default_value,
            fontSize: infoObj?.fontSize ?? this.fontSizeBoundaries.default,
            fontFamily: infoObj?.fontFamily ?? 'Space Mono',
            fontStyle: infoObj?.fontStyle ?? 'normal',
            align: infoObj?.fontAlignment ?? 'left',
            fill: '#000',
            width: ticketTemplateRender.width ?? this.blockSnapSize * multiplySnapSizeWidth,
            height: ticketTemplateRender.height ?? this.blockSnapSize * multiplySnapSizeHeight,
            padding: 5
        });
    }

    renderRectangleImageHelper(width: number, height: number, rectangleGroup: Group, multiplySnapSizeWidth: number,
                               multiplySnapSizeHeight: number, image: string = null, isBase64Image = false): Group {

        let imageUrl = image;

        if (!isBase64Image) {
            const currentTime = Math.floor(Date.now() / 1000);
            imageUrl = image + '?nocaching=' + currentTime;
        }

        Image.fromURL(imageUrl, (imageOutput) => {
            imageOutput.setAttrs({
                konvaType: KonvaTypesEnum.IMAGE,
                width: width ?? this.blockSnapSize * multiplySnapSizeWidth,
                height: height ?? this.blockSnapSize * multiplySnapSizeHeight,
            });

            rectangleGroup.add(imageOutput);
            if (rectangleGroup.getAttrs().id === TicketTemplateComponentTypeEnum.IMAGE_GENERAL) {
                this.applyCropOnImage('center-middle', rectangleGroup);
            }

            return rectangleGroup;
        }, () => {
            this.imageNotLoadedModal.nativeElement.open();
            this.hasImageLoadError = true;
            this.renderRectangleImageHelper(width, height, rectangleGroup, multiplySnapSizeWidth,
                multiplySnapSizeHeight, TemplateFallbackImage.image);
        });

        return rectangleGroup;
    }

    drawComponentOnCanvas(rectangle: Group, layer: Layer, isRecursed = false): void {
        layer.add(rectangle);
        if (this.validateCanvasDraw(rectangle, false, isRecursed)) {
            this.enableEventListenersOnTheRectangle(rectangle);
            if (!this.isUpdating) {
                this.changeArray.push(rectangle);
                this.transformer.nodes([rectangle]);
                const topIndex = this.baseLayer.children.length - 1;
                this.transformer.zIndex(topIndex);
                this.updateComponentAfterManipulations(rectangle);
            }
        } else {
            if (rectangle.getAttrs().id === TicketTemplateComponentTypeEnum.TICKET_COMPONENT_HORIZONTAL &&
                this.tempKonvaPointerPositionY + rectangle.height() > this.sizeBoundariesForTicketTemplates.max_y && !isRecursed) {
                rectangle.y(Number(this.sizeBoundariesForTicketTemplates.max_y - rectangle.height()));
                this.drawComponentOnCanvas(rectangle, layer, true);
            } else {
                rectangle.remove();
            }
        }
    }

    renderSelectedComponentInitializer(availableComponents): void {
        if (availableComponents.length === 1) {
            this.renderSelectedComponent(availableComponents[0]);
        } else {
            this.selectionComponentStream.next(availableComponents);
            this.componentDialog.nativeElement.open();
        }
    }

    renderSelectedComponent(selection): void {
        this.componentDialog.nativeElement.close();
        const ticketTemplateRender = new TicketTemplateRender({
            x: this.tempKonvaPointerPositionX,
            y: this.tempKonvaPointerPositionY,
            width: null,
            height: null,
            rotation: null,
            ticketTemplateComponent: selection,
            layer: this.baseLayer,
            additionalInformation: null
        });

        switch (selection.ticket_template_component_type_id) {
            case 'TEXT':
            case 'DYNAMIC_TEXT':
                this.renderRectangleWithText(ticketTemplateRender);
                break;
            case 'BARCODE':
                this.renderRectangleWithBarcode(ticketTemplateRender);
                break;
            case 'IMAGE':
                this.renderRectangleWithImage(ticketTemplateRender);
                break;
            case 'TICKET_COMPONENT':
                this.renderRectangleWithTicket(ticketTemplateRender);
                break;
            default:
                this.renderRectangleWithText(ticketTemplateRender);
        }

        this.tempKonvaPointerPositionY = null;
        this.tempKonvaPointerPositionX = null;
    }

    enableEventListenersOnTheRectangle(rectangle: Group): void {
        rectangle.on('dragstart', () => {
            this.tempOldPosition = {
                x: rectangle.x(),
                y: rectangle.y(),
                width: rectangle.width(),
                height: rectangle.height(),
                rotation: rectangle.rotation()
            };

            const width = rectangle.width() * rectangle.scaleX();
            const height = rectangle.height() * rectangle.scaleY();

            this.setShadowRectangle(width + this.blockSnapSize, height + this.blockSnapSize, rectangle.rotation());
            this.baseLayer.add(this.shadowRectangle);

            this.transformer.nodes([]);
            this.shadowRectangle.show();
            this.shadowRectangle.moveToTop();
            rectangle.moveToTop();
            const topIndex = this.baseLayer.children.length - 1;
            this.transformer.zIndex(topIndex);
            this.transformer.nodes([rectangle]);
        });

        rectangle.on('dragend', () => {
            if (!this.validateCanvasDraw(rectangle)) {
                rectangle.x(this.tempOldPosition.x);
                rectangle.y(this.tempOldPosition.y);
                this.stage.batchDraw();
            }

            this.stage.batchDraw();
            this.shadowRectangle.remove();
            this.updateComponentAfterManipulations(rectangle);
        });

        rectangle.on('dragmove', () => {
            this.shadowRectangle.position({
                x: Math.round(rectangle.x() / this.blockSnapSize) * this.blockSnapSize,
                y: Math.round(rectangle.y() / this.blockSnapSize) * this.blockSnapSize
            });

            this.checkCollisionBoundingBoxX(rectangle);
            this.checkCollisionBoundingBoxY(rectangle);

            this.stage.batchDraw();
            this.transformComponentViaUI(rectangle);
        });

        rectangle.on('transformstart', () => {
            this.tempOldPosition = {
                x: rectangle.x(),
                y: rectangle.y(),
                width: rectangle.width(),
                height: rectangle.height(),
                rotation: rectangle.rotation()
            };
        });

        rectangle.on('transform', () => {
            rectangle.x(Number(rectangle.x().toFixed(this.roundingDecimals)));
            rectangle.y(Number(rectangle.y().toFixed(this.roundingDecimals)));
            rectangle.rotation(Number(rectangle.rotation().toFixed(this.roundingDecimals)));

            this.transformer.nodes([rectangle]);
            this.transformComponentViaUI(rectangle);
        });

        rectangle.on('transformend', () => {
            const newX = rectangle.x(Number(rectangle.x().toFixed(this.roundingDecimals))).x();
            if (newX < this.sizeBoundariesForTicketTemplates.min_x) {
                rectangle.x(Number(this.sizeBoundariesForTicketTemplates.min_x));
            }
            const newY = rectangle.y(Number(rectangle.y().toFixed(this.roundingDecimals))).y();
            if (newY < this.sizeBoundariesForTicketTemplates.min_y) {
                rectangle.y(Number(this.sizeBoundariesForTicketTemplates.min_y));
            }
            this.transformComponentViaUI(rectangle);
            if (!this.validateCanvasDraw(rectangle)) {
                rectangle.x(this.tempOldPosition.x);
                rectangle.y(this.tempOldPosition.y);
                rectangle.width(this.tempOldPosition.width);
                rectangle.height(this.tempOldPosition.height);
                rectangle.rotation(this.tempOldPosition.rotation);

                this.stage.batchDraw();
                this.transformComponentViaUI(rectangle);
            }
        });

    }

    setShadowRectangle(width: number, height: number, rotation: number): void {
        this.shadowRectangle = new Rect({
            konvaType: KonvaTypesEnum.RECT,
            name: 'shadowBox',
            x: 0,
            y: 0,
            width,
            height,
            fill: '#036BD2',
            opacity: 0.6,
            stroke: '#084B88',
            strokeWidth: 3,
            rotation,
            dash: [20, 2],
        });
    }

    // BEHAVIOR FUNCTIONS
    transformComponentViaUI(component, widthUI: number = null, heightUI: number = null, fontProperties: any = null): void {
        let width = widthUI != null ? widthUI : Math.max(5, component.width() * component.scaleX());
        let height = heightUI != null ? heightUI : Math.max(5, component.height() * component.scaleY());
        const rotation = component.rotation();

        width = Number(width.toFixed(this.roundingDecimals));
        height = Number(height.toFixed(this.roundingDecimals));

        component.width(width);
        component.height(height);
        component.rotation(rotation);
        component.scaleX(1);
        component.scaleY(1);

        if (component.children != null) {
            component.children.forEach((child) => {
                child.width(width);
                child.height(height);
            });

            switch (component.getAttrs().type) {
                case 'IMAGE':
                    this.applyCropOnImage('center-middle', component);
                    break;
                case 'TEXT':
                case 'DYNAMIC_TEXT':
                    if (component.children.length > 1) {
                        const fontFamily = fontProperties?.family != null ?
                            fontProperties.family : component.children[1].getAttrs().fontFamily;
                        const fontSize = fontProperties?.size != null ? fontProperties.size : component.children[1].getAttrs().fontSize;
                        const fontStyle = fontProperties?.style != null ? fontProperties.style : component.children[1].getAttrs().fontStyle;
                        const fontAlignment = fontProperties?.alignment != null ?
                            fontProperties?.alignment : component.children[1].getAttrs().align;
                        const text = fontProperties?.text != null ? fontProperties.text : component.children[1].getAttrs().text;

                        component.children[1].setAttr('fontFamily', fontFamily);
                        component.children[1].setAttr('fontSize', fontSize);
                        component.children[1].setAttr('fontStyle', fontStyle);
                        component.children[1].setAttr('align', fontAlignment);
                        component.children[1].setAttr('text', text);
                    }
                    break;
            }

        }
        this.transformer.nodes([component]);
        const topIndex = this.baseLayer.children.length - 1;
        this.transformer.zIndex(topIndex);
        this.updateComponentAfterManipulations(component);
    }

    updateComponentAfterManipulations(component: Group): void {
        let value = null;
        let fontFamily = null;
        let fontSize = null;
        let fontAlignment = null;
        let fontStyle = null;
        let text = null;

        switch (component.getAttrs().type) {
            case 'TEXT':
                fontFamily = component.children[1].getAttrs().fontFamily;
                fontSize = component.children[1].getAttrs().fontSize;
                fontAlignment = component.children[1].getAttrs().align;
                fontStyle = component.children[1].getAttrs().fontStyle;
                value = JSON.stringify({fontFamily, fontSize, fontAlignment, fontStyle});
                break;
            case 'DYNAMIC_TEXT':
                text = component.children[1].getAttrs().text;
                fontFamily = component.children[1].getAttrs().fontFamily;
                fontSize = component.children[1].getAttrs().fontSize;
                fontAlignment = component.children[1].getAttrs().align;
                fontStyle = component.children[1].getAttrs().fontStyle;
                value = JSON.stringify({text, fontFamily, fontSize, fontAlignment, fontStyle});
                break;
            case 'TICKET_COMPONENT':
                value = JSON.stringify(this.printableTicketBoxOptions);
                break;
            case 'IMAGE':
                if (component.getAttrs().id === TicketTemplateComponentTypeEnum.IMAGE_CM_LOGO) {
                    value = JSON.stringify('{}');
                    break;
                }

                if (component.children[1].getAttrs().image != null) {
                    value = JSON.stringify({image: component.children[1].getAttrs().image.currentSrc});
                    this.applyCropOnImage('center-middle', component);
                    break;
                }
        }

        const observerComponent = new TicketTemplateComponent({
            positionX: component.x(),
            positionY: component.y(),
            width: component.width(),
            height: component.height(),
            rotation: component.rotation(),
            fontFamily,
            fontSize,
            fontAlignment,
            fontStyle,
            type: component.getAttrs().id,
            text,
            value,
            title: component.getAttrs().title
        });

        this.componentStream.next(observerComponent);
    }

    filterComponents(value: string): void {
        let availableComponents = null;

        const keywordToSearch = value.toLowerCase();

        this.searchSelectedValue = value;
        if (value != null) {
            availableComponents = this.ticketTemplatesComponents.filter(
                (component) => component.ticket_template_component_type_id === this.dragInformation[0] &&
                    component.title.toLowerCase().indexOf(keywordToSearch) > -1);
        } else {
            this.ticketTemplatesComponents.filter(
                (component) => component.ticket_template_component_type_id === this.dragInformation[0]);
        }

        this.selectionComponentStream.next(availableComponents);
    }

    clearDialogInformation(): void {
        this.searchSelectedValue = null;
    }

    // UI EVENTS
    changeImage(): void {
        const htmlElement = (document.querySelector('input[type="file"]') as HTMLInputElement);
        const filesSelected = (document.querySelector('input[type="file"]') as HTMLInputElement).files;
        const fileReader = new FileReader();
        if (filesSelected.length > 0) {
            const file = filesSelected[0];
            const fileSizeMB = file.size / 1024 / 1024;

            if (fileSizeMB >= this.maxFileSizeMB) {
                this.displayMessagesToUser('Ticket_Template.Constrain_Exception_Filesize_Exceed', MessageTypeEnum.ERROR);
                htmlElement.value = null;
                return;
            }

            fileReader.addEventListener('load', () => {
                this.tempImage = fileReader.result;
            }, false);


            fileReader.addEventListener('loadend', async () => {
                const id = '#image-text' + (this.transformer.getNode()._id);
                const shape = this.stage.findOne(id);
                const shapeGroup = this.transformer.getNode() as Group;
                if (shape != null) {
                    shape.destroy();
                } else {
                    shapeGroup.children[1].destroy();
                }

                this.renderRectangleImageHelper(shapeGroup.children[0].width(), shapeGroup.children[0].height(),
                    shapeGroup, 0, 0, this.tempImage, true);
                this.tempImage = null;
                htmlElement.value = '';

            });

            if (file) {
                fileReader.readAsDataURL(file);
            }
        }
    }

    changeTemplateName(name: string): void {
        this.templateName = name;
    }

    setBackgroundColor(hex: string): void {
        this.konvaContainer.nativeElement.style.backgroundColor = hex;
        this.backgroundColor = hex;
    }

    changePositionX(event: any): void {
        const shape = this.transformer.getNode() as Group;

        this.tempOldPosition = {
            x: shape.x(),
            y: shape.y(),
            width: shape.width(),
            height: shape.height(),
            rotation: shape.rotation()
        };

        this.transformer.getNode().x(Number(event.target.value));

        clearTimeout(this.inputTimeOut);
        this.inputTimeOut = setTimeout(() => {
            if (!this.validateCanvasDraw(shape)) {

                if (Number(event.target.value) < this.sizeBoundariesForTicketTemplates.min_x) {
                    event.target.value = this.sizeBoundariesForTicketTemplates.min_x;
                }

                if (Number(event.target.value) - shape.height() < this.sizeBoundariesForTicketTemplates.min_x
                    && (shape.rotation() !== 0 || shape.rotation() !== -0)) {
                    event.target.value = this.sizeBoundariesForTicketTemplates.min_x + shape.height();
                }

                if (Number(event.target.value) + shape.width() > this.sizeBoundariesForTicketTemplates.max_x) {
                    event.target.value = this.sizeBoundariesForTicketTemplates.max_x - shape.width();
                }

                if (Number(event.target.value) + shape.height() < this.sizeBoundariesForTicketTemplates.max_x
                    && (shape.rotation() !== 0 || shape.rotation() !== -0)) {
                    event.target.value = this.sizeBoundariesForTicketTemplates.max_x;
                }

                this.hasErrorMessage = true;

                event.target.max = this.sizeBoundariesForTicketTemplates.max_x - shape.width();
                this.transformer.getNode().x(Number(event.target.value));

                setTimeout(() => {
                    this.hasErrorMessage = false;
                    event.target.removeAttribute('max');
                }, this.errorMessageThrottleTime);

                this.transformComponentViaUI(this.transformer.getNode());
            }
        }, this.inputTimeOutTime);
    }

    changePositionY(event: any): void {
        const shape = this.transformer.getNode() as Group;

        this.tempOldPosition = {
            x: shape.x(),
            y: shape.y(),
            width: shape.width(),
            height: shape.height(),
            rotation: shape.rotation()
        };

        this.transformer.getNode().y(Number(event.target.value));

        clearTimeout(this.inputTimeOut);
        this.inputTimeOut = setTimeout(() => {
            if (Number(event.target.value) < this.sizeBoundariesForTicketTemplates.min_y) {
                event.target.value = this.sizeBoundariesForTicketTemplates.min_y;
            }

            if (Number(event.target.value) + shape.height() > this.sizeBoundariesForTicketTemplates.max_y) {
                event.target.value = this.sizeBoundariesForTicketTemplates.max_y - shape.height();
            }

            if (!this.validateCanvasDraw(shape)) {
                this.hasErrorMessage = true;

                if (shape.rotation() === 0 || shape.rotation() === -0) {
                    event.target.max = this.sizeBoundariesForTicketTemplates.max_y;
                } else {
                    event.target.max = this.sizeBoundariesForTicketTemplates.max_y - shape.width();
                }

                if (Number(event.target.value) < this.sizeBoundariesForTicketTemplates.min_y &&
                    (shape.rotation() !== 0 || shape.rotation() !== -0)) {
                    event.target.value = this.sizeBoundariesForTicketTemplates.min_y;
                }

                if (Number(event.target.value) + shape.width() > this.sizeBoundariesForTicketTemplates.max_y
                    && (shape.rotation() !== 0 || shape.rotation() !== -0)) {
                    event.target.value = this.sizeBoundariesForTicketTemplates.max_y - shape.width();
                }

                this.transformer.getNode().y(Number(event.target.value));

                setTimeout(() => {
                    this.hasErrorMessage = false;
                    event.target.removeAttribute('max');
                }, this.errorMessageThrottleTime);

                this.transformComponentViaUI(this.transformer.getNode());
            }
        }, this.inputTimeOutTime);
    }

    changeWidth(event: any): void {
        if (Number(event.target.value) < 1) {
            event.target.value = this.blockSnapSize;
        }

        const shape = this.transformer.getNode() as Group;

        this.tempOldPosition = {
            x: shape.x(),
            y: shape.y(),
            width: shape.width(),
            height: shape.height(),
            rotation: shape.rotation()
        };

        this.transformer.getNode().width(Number(event.target.value));
        if (!this.validateCanvasDraw(shape, true)) {
            this.hasErrorMessage = true;
            this.transformer.getNode().width(this.tempOldPosition.width);
            event.target.value = this.tempOldPosition.width;
            event.target.max = this.tempOldPosition.width;

            setTimeout(() => {
                this.hasErrorMessage = false;
                event.target.removeAttribute('max');
            }, this.errorMessageThrottleTime);

            this.transformComponentViaUI(this.transformer.getNode(), Number(this.tempOldPosition.width));
        } else {
            this.transformComponentViaUI(this.transformer.getNode(), Number(event.target.value));
        }
    }

    changeHeight(event: any): void {
        if (Number(event.target.value) < 1) {
            event.target.value = this.blockSnapSize * 2;
        }

        const shape = this.transformer.getNode() as Group;

        this.tempOldPosition = {
            x: shape.x(),
            y: shape.y(),
            width: shape.width(),
            height: shape.height(),
            rotation: shape.rotation()
        };

        this.transformer.getNode().height(Number(event.target.value));
        if (!this.validateCanvasDraw(shape, true)) {
            this.hasErrorMessage = true;
            this.transformer.getNode().height(this.tempOldPosition.height);
            event.target.value = this.tempOldPosition.height;
            event.target.max = this.tempOldPosition.height;

            setTimeout(() => {
                this.hasErrorMessage = false;
                event.target.removeAttribute('max');
            }, this.errorMessageThrottleTime);

            this.transformComponentViaUI(this.transformer.getNode(), null, Number(this.tempOldPosition.height));
        } else {
            this.transformComponentViaUI(this.transformer.getNode(), null, Number(event.target.value));
        }
    }

    changeFontSize(event: any): void {
        if (Number(event.target.value) < this.fontSizeBoundaries.min || Number(event.target.value) > this.fontSizeBoundaries.max) {
            event.target.value = this.fontSizeBoundaries.default;
        }

        this.updateFontProperties({size: Number(event.target.value)});
    }

    updateFontProperties(properties: {}): void {
        this.transformComponentViaUI(this.transformer.getNode(), null, null, properties);
    }

    applyCropOnImage(position, rectangle): void {
        const image: any = rectangle.children[1];
        image.setAttr('lastCropUsed', position);
        if (image.image && image.image()) {
            const crop = this.getCrop(
                image.image(),
                {width: image.getAttr('width'), height: image.getAttr('height')},
                position
            );
            image.setAttrs(crop);
        }
    }

    getCrop(image, size, clipPosition): any {
        const width = size.width;
        const height = size.height;
        const aspectRatio = width / height;

        let newWidth;
        let newHeight;

        const imageRatio = image.width / image.height;

        if (aspectRatio >= imageRatio) {
            newWidth = image.width;
            newHeight = image.width / aspectRatio;
        } else {
            newWidth = image.height * aspectRatio;
            newHeight = image.height;
        }

        let aspectRatioQuality;
        if (imageRatio > aspectRatio) {
            aspectRatioQuality = Number(Math.round((aspectRatio / imageRatio) * this.oneHundredPercent));
        } else {
            aspectRatioQuality = Number(Math.round((imageRatio / aspectRatio) * this.oneHundredPercent));
        }

        let widthQuality;
        if (image.width > size.width) {
            widthQuality = this.oneHundredPercent;
        } else {
            widthQuality = Number(Math.round((image.width / size.width) * this.oneHundredPercent));
        }

        let heightQuality;
        if (image.height > size.height) {
            heightQuality = this.oneHundredPercent;
        } else {
            heightQuality = Number(Math.round((image.height / size.height) * this.oneHundredPercent));
        }

        this.qualityImagePercentage = (aspectRatioQuality + widthQuality + heightQuality) / this.divideByThreeToGetAverage;

        let x;
        let y;
        if (clipPosition === 'center-middle') {
            x = (image.width - newWidth) / this.bisect;
            y = (image.height - newHeight) / this.bisect;
        }

        return {
            cropX: x,
            cropY: y,
            cropWidth: newWidth,
            cropHeight: newHeight,
        };
    }

    fitImageHorizontal(): void {
        const groupContainer: any = this.transformer.getNode();
        if (!(groupContainer && groupContainer.children && groupContainer.children[1])) {
            return;
        }
        if (!(groupContainer.children[1].image && groupContainer.children[1].image())) {
            return;
        }
        const imageContainer = groupContainer.children[1].image();
        this.tempOldPosition = {
            x: groupContainer.x(),
            y: groupContainer.y(),
            width: groupContainer.width(),
            height: groupContainer.height(),
            rotation: groupContainer.rotation()
        };
        groupContainer.x(this.sizeBoundariesForTicketTemplates.min_x);
        const imageRatio = imageContainer.width / imageContainer.height;
        const newHeight = (this.sizeBoundariesForTicketTemplates.max_x - this.sizeBoundariesForTicketTemplates.min_x) / imageRatio;
        groupContainer.rotation(0);
        this.transformComponentViaUI(groupContainer,
            this.sizeBoundariesForTicketTemplates.max_x - this.sizeBoundariesForTicketTemplates.min_x,
            newHeight);
        if (!this.validateCanvasDraw(groupContainer as Group)) {
            groupContainer.x(this.tempOldPosition.x);
            this.transformComponentViaUI(groupContainer, this.tempOldPosition.width,
                this.tempOldPosition.height);
        }
    }

    resetRotation(): void {
        this.transformer.getNode().rotation(0);
        this.transformer.getNode().x(this.sizeBoundariesForTicketTemplates.min_x);
        this.transformer.getNode().y(this.sizeBoundariesForTicketTemplates.min_y);
        this.transformComponentViaUI(this.transformer.getNode());
    }

    deleteComponent(): void {
        if (this.transformer.getNode() != null) {
            this.changeArray = this.changeArray.filter(change => this.transformer.getNode()._id !== change._id);
            this.transformer.getNode().destroy();
            this.transformer.nodes([]);
            this.componentStream.next(null);
        }
    }

    // SAVE FUNCTION
    async saveTemplate(): Promise<void> {
        this.isLoading = true;

        if (this.templateName === null || this.templateName.length <= 3) {
            this.displayMessagesToUser('Ticket_Template.Template_Name_Isset', MessageTypeEnum.CUSTOM_ERROR);
            this.isLoading = false;
            return;
        }

        if (this.collisionDetection() || this.constrainValidation() || this.validateCMBrandingIsCorrectlyApplied()) {
            this.isLoading = false;
            return;
        }


        const saveDataOutput = this.prepareSaveData();

        if (saveDataOutput.length === 0) {
            this.displayMessagesToUser('Ticket_Template.No_Items', MessageTypeEnum.CUSTOM_ERROR);
            this.isLoading = false;
        }

        if (!this.validateSetImageInComponent(saveDataOutput)) {
            this.isLoading = false;
            return;
        }

        let ticketTemplateCallType;
        let translationKeyForCall;
        if (this.ticketTemplateUuid == null) {
            ticketTemplateCallType = this.orderDistributionService.postTicketTemplate(
                saveDataOutput,
                this.templateName,
                this.templateType,
                this.backgroundColor
            );
            translationKeyForCall = 'Ticket_Template.Save_Template';
        } else {
            ticketTemplateCallType = this.orderDistributionService.putTicketTemplate(
                saveDataOutput,
                this.templateName,
                this.templateType,
                this.backgroundColor,
                this.ticketTemplateUuid
            );
            translationKeyForCall = 'Ticket_Template.Update_Template';
        }

        ticketTemplateCallType
            .toPromise()
            .then(() => {
                this.displayMessagesToUser(translationKeyForCall, MessageTypeEnum.SUCCESS);
                this.isSavingTemplate = true;
                this.returnToOverview();
            })
            .catch(errors => {
                if (errors.error.code !== 4004) {
                    errors.error.details.forEach(error => {
                        this.displayMessagesToUser(error.translation_key_id,
                            MessageTypeEnum.CUSTOM_ERROR_API, error.ticket_template_component_name, error.amount);
                        this.isLoading = false;
                    });
                } else {
                    this.displayMessagesToUser('Ticket_Template.Error_Validation', MessageTypeEnum.CUSTOM_ERROR);
                }
            });
    }

    // SAVE HELPERS
    prepareSaveData(): Array<TicketTemplateComponentDatabase> {
        const outputArray = Array<TicketTemplateComponentDatabase>();
        let value: string = null;

        this.baseLayer.getChildren().forEach((child) => {
            if (child.getAttrs().konvaType !== KonvaTypesEnum.GROUP) {
                return;
            }
            child = child as Group;

            const innerChild = child.children[1].getAttrs();

            switch (child.getAttrs().type) {
                case 'TEXT':
                    value = JSON.stringify({
                        fontFamily: innerChild.fontFamily,
                        fontSize: innerChild.fontSize,
                        fontStyle: innerChild.fontStyle,
                        fontAlignment: innerChild.align
                    });
                    break;
                case 'DYNAMIC_TEXT':
                    value = JSON.stringify({
                        text: innerChild.text,
                        fontFamily: innerChild.fontFamily,
                        fontSize: innerChild.fontSize,
                        fontStyle: innerChild.fontStyle,
                        fontAlignment: innerChild.align
                    });
                    break;
                case 'TICKET_COMPONENT':
                    value = JSON.stringify(this.printableTicketBoxOptions);
                    break;
                case 'IMAGE':
                    if (innerChild.image != null) {
                        value = JSON.stringify({image: innerChild.image.currentSrc});
                    } else {
                        value = JSON.stringify({});
                    }
                    break;
                case 'BARCODE':
                    value = JSON.stringify({});
                    break;
                default:
                    value = JSON.stringify({});
                    break;
            }

            let ticketTemplateComponent = new TicketTemplateComponentDatabase({
                position_x: Number((child.x() / this.scale).toFixed(this.roundingDecimals)),
                position_y: Number((child.y() / this.scale).toFixed(this.roundingDecimals)),
                width: Number((child.width() / this.scale).toFixed(this.roundingDecimals)),
                height: Number((child.height() / this.scale).toFixed(this.roundingDecimals)),
                rotation: Math.round(child.rotation()),
                typeID: child.getAttrs().id,
                value,
            });

            if (this.ticketTemplateInformation.orientation === OrientationEnum.HORIZONTAL) {
                ticketTemplateComponent = this.rotateComponent(ticketTemplateComponent);
            }

            outputArray.push(ticketTemplateComponent);
        });

        return outputArray;
    }

    rotateComponent(ticketTemplateComponent: TicketTemplateComponentDatabase): TicketTemplateComponentDatabase {
        const incX = ticketTemplateComponent.position_x;
        ticketTemplateComponent.position_x = this.shallowTicketTemplateInformation.width - ticketTemplateComponent.height -
            ticketTemplateComponent.position_y;
        ticketTemplateComponent.position_y = incX;

        return ticketTemplateComponent;
    }

    rotateComponentCounterwise(ticketTemplateComponent: TicketTemplateComponentDatabase): TicketTemplateComponentDatabase {
        const incX = ticketTemplateComponent.position_x;
        ticketTemplateComponent.position_x = ticketTemplateComponent.position_y;
        ticketTemplateComponent.position_y = this.shallowTicketTemplateInformation.width - incX - ticketTemplateComponent.height;
        return ticketTemplateComponent;
    }

    // VALIDATORS
    checkCollisionBoundingBoxX(rectangle: Group): void {
        if (Math.round(rectangle.rotation()) === 0 || Math.round(rectangle.rotation()) === -0) {
            if (Number(rectangle.x()) < this.sizeBoundariesForTicketTemplates.min_x) {
                this.shadowRectangle.position({
                    x: Number(Math.round(this.sizeBoundariesForTicketTemplates.min_x)),
                    y: Number(Math.round(rectangle.y() / this.blockSnapSize) * this.blockSnapSize)
                });
                rectangle.position({
                    x: Number(Math.round(this.sizeBoundariesForTicketTemplates.min_x)),
                    y: Number(Math.round(rectangle.y() / this.blockSnapSize) * this.blockSnapSize)
                });
            }

            if (Number(rectangle.x()) + rectangle.width() > this.sizeBoundariesForTicketTemplates.max_x) {
                this.shadowRectangle.position({
                    x: Number(Math.round(this.sizeBoundariesForTicketTemplates.max_x - rectangle.width())),
                    y: Number(Math.round(rectangle.y() / this.blockSnapSize) * this.blockSnapSize)
                });
                rectangle.position({
                    x: Number(Math.round(this.sizeBoundariesForTicketTemplates.max_x - rectangle.width())),
                    y: Number(Math.round(rectangle.y() / this.blockSnapSize) * this.blockSnapSize)
                });
            }
        }
    }

    checkCollisionBoundingBoxY(rectangle: Group): void {
        if (Math.round(rectangle.rotation()) === 0 || Math.round(rectangle.rotation()) === -0) {
            if (Number(rectangle.y()) < this.sizeBoundariesForTicketTemplates.min_y) {
                this.shadowRectangle.position({
                    x: Number(Math.round(rectangle.x() / this.blockSnapSize) * this.blockSnapSize),
                    y: Number(Math.round(this.sizeBoundariesForTicketTemplates.min_y / this.blockSnapSize) * this.blockSnapSize)
                });
                rectangle.position({
                    x: Number(Math.round(rectangle.x() / this.blockSnapSize) * this.blockSnapSize),
                    y: Number(Math.round(this.sizeBoundariesForTicketTemplates.min_y / this.blockSnapSize) * this.blockSnapSize)
                });
            }

            if (Number(rectangle.y()) + rectangle.height() > this.sizeBoundariesForTicketTemplates.max_y) {
                this.shadowRectangle.position({
                    x: Number(Math.round(rectangle.x() / this.blockSnapSize) * this.blockSnapSize),
                    y: Number(Math.round(this.sizeBoundariesForTicketTemplates.max_y - rectangle.height()))
                });
                rectangle.position({
                    x: Number(Math.round(rectangle.x() / this.blockSnapSize) * this.blockSnapSize),
                    y: Number(Math.round(this.sizeBoundariesForTicketTemplates.max_y - rectangle.height()))
                });
            }
        }
    }

    validateCanvasDraw(shape: Group, isUiManipulation = false, isShowingError = true): boolean {
        const rect = shape.getClientRect({skipStroke: true});
        rect.x = Number(rect.x.toFixed(this.roundingDecimals));
        rect.y = Number(rect.y.toFixed(this.roundingDecimals));
        rect.width = Number(rect.width.toFixed(this.roundingDecimals));
        rect.height = Number(rect.height.toFixed(this.roundingDecimals));

        const errorMessage = this.getErrorMessageWhenValidatingCanvas(rect, shape, isUiManipulation);

        if (this.hasErrorMessage) {
            return !this.collisionDetection();
        }
        if (errorMessage && isShowingError) {
            this.displayMessagesToUser(errorMessage, MessageTypeEnum.CUSTOM_ERROR);
        }

        return !(this.collisionDetection() || errorMessage);
    }

    getErrorMessageWhenValidatingCanvas(rect, shape, isUiManipulation): string | null {
        let errorMessage = null;
        const xTotal = rect.x + rect.width;
        const yTotal = rect.y + rect.height;

        if (rect.x < this.sizeBoundariesForTicketTemplates.min_x) {
            errorMessage = 'Ticket_Template.Validate_Draw_Margins_X';
        } else if (rect.y < this.sizeBoundariesForTicketTemplates.min_y) {
            errorMessage = 'Ticket_Template.Validate_Draw_Margins_Y';
        } else if (xTotal > this.sizeBoundariesForTicketTemplates.max_x) {
            errorMessage = 'Ticket_Template.Validate_Draw_Width';
        } else if (yTotal > this.sizeBoundariesForTicketTemplates.max_y) {
            errorMessage = 'Ticket_Template.Validate_Draw_Height';
        } else if (isUiManipulation) {
            const rotation = shape.rotation();
            const absolutePosition = shape.getAbsolutePosition();
            const width = shape.width();

            if (rotation === 0 && width + absolutePosition.x > this.sizeBoundariesForTicketTemplates.max_x) {
                errorMessage = 'Ticket_Template.Validate_Draw_Width';
            } else if (rotation !== 0 && width + absolutePosition.y > this.sizeBoundariesForTicketTemplates.max_y) {
                errorMessage = 'Ticket_Template.Validate_Draw_Width';
            } else if (rotation !== 0 && shape.height() > this.sizeBoundariesForTicketTemplates.max_y) {
                errorMessage = 'Ticket_Template.Validate_Draw_Height';
            }
        }
        const shapeId = shape.id();
        if (!this.isSizeWithinLimits(rect)) {
            if (shapeId === TicketTemplateComponentTypeEnum.IMAGE_CM_LOGO) {
                errorMessage = 'Ticket_Template.Invalid_CM_Logo_Size';
            }

            if (shapeId === TicketTemplateComponentTypeEnum.BARCODE_QR) {
                errorMessage = 'Ticket_Template.Invalid_QR_Size';
            }
        }

        return errorMessage;
    }

    isSizeWithinLimits(rect): boolean {
        return !(rect.width < 100 || rect.height < 100);
    }

    validateSetImageInComponent(outputArray: Array<TicketTemplateComponentDatabase>): boolean {
        const images = outputArray.filter(component =>
            component.ticket_template_component_id === TicketTemplateComponentTypeEnum.IMAGE_GENERAL
        );
        if (images.length === 0) {
            return true;
        }

        let needsReturn = false;
        images.forEach(image => {
            if (image.value === '{}') {
                this.displayMessagesToUser('Ticket_Template.Image_Uploaded_On_Component', MessageTypeEnum.CUSTOM_ERROR);
                needsReturn = true;
            }
        });

        return !needsReturn;
    }

    validateCMBrandingIsCorrectlyApplied(): boolean {

        const findChildById = (id) => this.baseLayer.getChildren().filter(child => child.getAttrs().id === id);

        const images = findChildById(TicketTemplateComponentTypeEnum.IMAGE_CM_LOGO);
        const ticketComponent = findChildById(TicketTemplateComponentTypeEnum.TICKET_COMPONENT_HORIZONTAL);

        if ((images.length === 0 && ticketComponent.length === 0) && this.isCMBrandingEnabled && this.templateType === 'A4'){
            this.displayMessagesToUser('Ticket_Template.Image_CM_logo_Uploaded_On_Canvas', MessageTypeEnum.CUSTOM_ERROR);
            return true;
        }

        return false;
    }

    collisionDetection(): boolean {
        let outcomeCollision = false;

        const collisionKeys = [TicketTemplateComponentTypeEnum.TICKET_COMPONENT_HORIZONTAL, TicketTemplateComponentTypeEnum.BARCODE_C128,
            TicketTemplateComponentTypeEnum.BARCODE_QR, TicketTemplateComponentTypeEnum.IMAGE_CM_LOGO];

        collisionKeys.forEach(collisionItem => {
            const collisionItemOnStage = this.stage.find('#' + collisionItem);
            if (collisionItemOnStage.length === 0) {
                return;
            }
            const collisionComponent = collisionItemOnStage[0] as Group;

            this.baseLayer.getChildren().forEach((child) => {
                if (child._id === collisionComponent._id || child.getAttrs().konvaType !== KonvaTypesEnum.GROUP) {
                    return;
                }

                if (!this.haveIntersection(collisionComponent.getClientRect(), child.getClientRect())) {
                    return;
                }

                this.addErrorBorderOnCollision();
                if (!outcomeCollision) {
                    this.displayMessagesToUser(
                        'Ticket_Template.Collision_Detection',
                        MessageTypeEnum.CUSTOM_ERROR_COLLISION,
                        collisionItem
                    );
                }
                outcomeCollision = true;
            });
        });

        return outcomeCollision;
    }

    constrainValidation(): boolean {
        let constrainViolated = false;
        this.ticketTemplateConstraints.forEach(constrain => {
            const component = this.ticketTemplatesComponents.find(
                entry => entry.id === constrain.ticket_template_component.ticket_template_component_id);
            const components = this.baseLayer.getChildren().filter(
                child => child.getAttrs().id === constrain.ticket_template_component.ticket_template_component_id);

            switch (constrain.operator) {
                case '=':
                    if (components.length !== constrain.amount) {
                        this.displayMessagesToUser(
                            'Ticket_Template.Constrain_Exception_Equal', MessageTypeEnum.CUSTOM_ERROR_API,
                            component.title, constrain.amount);
                        constrainViolated = true;
                    }
                    break;

                case '>=':
                    if (components.length < constrain.amount) {
                        this.displayMessagesToUser(
                            'Ticket_Template.Constrain_Exception_Less_Then', MessageTypeEnum.CUSTOM_ERROR_API,
                            component.title, constrain.amount);
                        constrainViolated = true;
                    }
                    break;

                case '<=':
                    if (components.length > constrain.amount) {
                        this.displayMessagesToUser(
                            'Ticket_Template.Constrain_Exception_More_Then', MessageTypeEnum.CUSTOM_ERROR_API,
                            component.title, constrain.amount);
                        constrainViolated = true;
                    }
                    break;

                case 'OR':
                    const componentOr = this.ticketTemplatesComponents.find(
                        entry => entry.id === constrain.ticket_template_component_or.ticket_template_component_id);
                    const componentsOr = this.baseLayer.getChildren().filter(
                        child => child.getAttrs().id === constrain.ticket_template_component_or.ticket_template_component_id);

                    if (components.length < 1 && componentsOr.length < 1) {
                        this.displayMessagesToUser('Ticket_Template.Constrain_Exception_Missing', MessageTypeEnum.CUSTOM_ERROR_API,
                            component.title + '/' + componentOr.title);
                        constrainViolated = true;
                    }
                    break;
            }
        });

        return constrainViolated;
    }

    addErrorBorderOnCollision(): void {
        this.transformer.borderStroke('#ff495c');
        this.transformer.anchorStroke('#ff495c');
        setTimeout(() => {
            this.transformer.borderStroke('rgb(102, 178, 255)');
            this.transformer.anchorStroke('rgb(102, 178, 255)');
        }, (1000));
    }

    haveIntersection(r1: IRect, r2: IRect): boolean {
        return !(
            r2.x > (r1.x + r1.width) ||
            (r2.x + r2.width) < r1.x ||
            r2.y > (r1.y + r1.height) ||
            (r2.y + r2.height) < r1.y
        );
    }

    // MESSAGE DISPLAYING
    displayMessagesToUser(key: string, type: MessageTypeEnum, additionalComponentType = null, additionalAmount = null): void {
        let replacingInformation;
        switch (type) {
            case MessageTypeEnum.SUCCESS:
            case MessageTypeEnum.CUSTOM_SUCCESS:
                NotificationHelper.showCustomNotification(this.translationService, 'Generic.Success_Title', key);
                break;
            case MessageTypeEnum.ERROR:
            case MessageTypeEnum.CUSTOM_ERROR:
                NotificationHelper.showCustomNotification(this.translationService, 'Ticket_Template.Not_Allowed_Exception', key);
                break;
            case MessageTypeEnum.CUSTOM_ERROR_API:
                replacingInformation = [['{type}', additionalComponentType], ['{amount}', additionalAmount]];
                NotificationHelper.showCustomerNotificationWithReplaces(this.translationService, 'Ticket_Template.Not_Allowed_Exception',
                    key, replacingInformation);
                break;
            case MessageTypeEnum.CUSTOM_ERROR_COLLISION:
                replacingInformation = [['{type}', additionalComponentType]];
                NotificationHelper.showCustomerNotificationWithReplaces(this.translationService, 'Ticket_Template.Not_Allowed_Exception',
                    key, replacingInformation);
                break;
            default:
                NotificationHelper.showErrorNotification(this.translationService, 'Generic.Error_Unknown');
        }
    }

    // EVENTS
    returnToOverview(): void {
        if (this.isViewPortTooLow) {
            this.viewPortModal.nativeElement.remove();
        }

        if (this.hasImageLoadError) {
            this.imageNotLoadedModal.nativeElement.remove();
        }

        this.ticketTemplateSaved.emit();
    }

    returnToSaveView(): void {
        this.componentStream.next(null);
        this.transformer.nodes([]);
    }
}
