import {Injectable} from '@angular/core';
import {Endpoint} from './api-discovery.service';
import {Observable} from 'rxjs';
import {Vat} from './shared/models/vat.class';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SportsApiService {

    private endpoints: Array<Endpoint> = [];

    constructor(private http: HttpClient) {
    }

    public setEndpoints(endpoints: Array<Endpoint>): void {
        this.endpoints = endpoints;
    }

    private getEndpointUrl(id: string, params?: Record<string, string>): string | null {
        const endpoint = this.endpoints.find(_endpoint => _endpoint.id === id);

        if (!endpoint) {
            console.error(`Endpoint [${id}] not found`);
            return null;
        }

        if (!params) {
            return endpoint.url;
        }

        const [baseUrl, ...pathParts] = endpoint.url.split('/');
        const path = pathParts.join('/');

        const replacedPath = path.replace(/:(\w+)/g, (match, paramName) => {
            if (params.hasOwnProperty(paramName)) {
                return encodeURIComponent(params[paramName]);
            }
            console.warn(`Parameter [${paramName}] not provided for endpoint [${id}]`);
            return match;
        });

        return `${baseUrl}/${replacedPath}`;
    }

    public getManyVat(): Observable<Array<Vat>> {
        let url: string = this.getEndpointUrl('GET_ALL_VAT');

        // Backwards compatibility until Sports API is production ready
        if (!url) {
            url = `${environment.CM_API_URL}/seatedapi/v1.0/vat`;
        }

        return this.http.get<Array<Vat>>(url, {
            withCredentials: true
        });
    }

    public startCustomReport(reportId: string): Observable<void> {
        const url: string = this.getEndpointUrl('GENERATE_CUSTOM_REPORT', {reportId});
        return this.http.post<void>(url, {}, {
            withCredentials: true
        });
    }

    public postOrderToInvite(orderId: string): Observable<void> {
        const url: string = this.getEndpointUrl('POST_ORDER_TO_INVITE', {orderId});
        return this.http.post<void>(url, {}, {
            withCredentials: true
        });
    }

    public createInviteOrganisation(customerId: string): Observable<any> {
        const url: string = this.getEndpointUrl('POST_INVITE_ORGANISATION', {customerId});
        return this.http.post<void>(url, {}, {
            withCredentials: true
        });
    }

    public addUserToInvite(customerId: string, email: string): Observable<any> {
        const url: string = this.getEndpointUrl('POST_INVITE_USER', {customerId});
        return this.http.post<void>(url, {
            email: email
        }, {
            withCredentials: true
        });
    }

    public getInviteUsers(customerId: string): Observable<any> {
        const url: string = this.getEndpointUrl('GET_INVITE_USERS', {customerId});
        return this.http.get<void>(url, {
            withCredentials: true
        });
    }
}
