import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {saveAs} from 'file-saver/dist/FileSaver';
import {SportsApiService} from '../../../sports-api.service';

@Injectable()
export class CustomReportService {

    private config = {
        withCredentials: true,
        params: {}
    };

    private baseUrl = environment.CM_API_URL + '/seatedapi';

    constructor(private http: HttpClient, private sportsApi: SportsApiService) {
    }

    getCustomReports(httpParams?: HttpParams): Observable<CustomReport[]> {
        const config = this.config;
        config.params = httpParams;

        return this.http.get<CustomReport[]>(this.baseUrl + `/v1.0/report`, config);
    }

    getCustomReport(reportId: string, httpParams?: HttpParams): Observable<CustomReport> {
        const config = this.config;
        config.params = httpParams;

        return this.http.get<CustomReport>(this.baseUrl + `/v1.0/report/${reportId}`, config);
    }

    public startCustomReportExport(reportId: string): Observable<void> {
        return this.sportsApi.startCustomReport(reportId);
    }

    generateCSVFromCustomReportContent(report) {
        if (report.content) {
            const rows = report.content;

            if (rows.length === 0) {
                return;
            }

            // specify how you want to handle null values here
            const replacer = (key, value) => value === null ? '' : value;
            const header = Object.keys(rows[0]);
            const csv = rows.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
            csv.unshift(header.join(';'));
            const csvArray = csv.join('\r\n');

            const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
            const fileName = `${date} - ${report.name}.csv`;

            const BOM = '\uFEFF';
            const blob = new Blob([BOM + csvArray], {type: 'text/csv;charset=utf-8'});
            saveAs(blob, fileName, {autoBom: true});
        }

        return false;
    }

}

export class CustomReport {
    constructor(
        public id,
        public name,
        public isViewEnabled,
        public content
    ) {
    }
}
