import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Campaign} from '../../../models/campaign.class';
import {CampaignService} from '../../../services/campaign-service/campaign.service';
import {Subject} from 'rxjs';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {NotificationService} from '../../../services/notification-service/notification.service';
import {DashboardService} from '../../../services/dashboard.service';
import {isMobile} from '../../../../core/helpers/device-helper';
import {DialogComponent} from '../../dialogs/dialog/dialog.component';

@Component({
    selector: 'app-campaign-detail',
    templateUrl: './campaign-detail.component.html',
    styleUrls: ['./campaign-detail.component.scss'],
    standalone: false
})
export class CampaignDetailComponent implements OnInit, OnDestroy {
    public buttonSize = isMobile ? 'large' : 'medium';
    destroy$: Subject<boolean> = new Subject<boolean>();
    campaign: Campaign;

    isNewCampaign;

    @ViewChild('deleteCampaignDialog')
    deleteCampaignDialog: DialogComponent;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private dialogService: DialogService,
                private notificationService: NotificationService,
                private dashboardService: DashboardService,
                private campaignService: CampaignService) {
    }

    ngOnInit() {
        this.dashboardService.setFocusMode(true);
        this.route.params.subscribe(params => {
            this.isNewCampaign = !params.campaignId;
            if (this.isNewCampaign) {
                this.campaign = new Campaign();
                return;
            }

            this.campaignService.getCampaign(params.campaignId).subscribe(campaign => {
                this.campaign = campaign;
            });
        });
    }

    ngOnDestroy() {
        this.dashboardService.setFocusMode(false);
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    startDateChanged(event): void {
        this.campaign.startAt = event.detail.datetime;
    }

    saveCampaign() {
        const saveUrl = this.campaign.id ?
            this.campaignService.patchCampaign(this.campaign) :
            this.campaignService.postCampaign(this.campaign);

        saveUrl.subscribe(campaign => {
            this.campaign = campaign;
            this.notificationService.showTranslatedNotification('success', 'campaign', 'saved');
            this.router.navigate(['settings/campaigns/' + campaign.id]);
        });
    }

    onDeleteCampaignConfirm(): void {
        this.campaignService.deleteCampaign(this.campaign.id).subscribe(() => {
            this.notificationService.showTranslatedNotification('success', 'campaign', 'deleted');
            this.navigateBack();
        });
    }

    openDeleteCampaignDialog(): void {
        this.deleteCampaignDialog.open();
    }

    navigateBack(): void {
        this.router.navigate(['settings/campaigns']);
    }
}
