import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-form-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.css'],
    standalone: false
})
export class RadioGroupComponent implements OnInit {
    @Input() categories: any[];
    @Input() categoryId: string;
    @Input() categoryLabel: string;
    @Input() categoryNameTranslate = false;
    @Input() optionLabelTranslate = false;
    @Input() optionsIndex: string;
    @Input() optionLabel: string;
    @Input() optionValue: string;
    @Input() columns = 2;
    @Input() selectedOption: string = null;
    @Output() selectedOptionChange: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit(): void {

    }

    updateSelection(option: any) {
        this.selectedOption = option[this.optionValue];

        this.selectedOptionChange.emit(this.selectedOption);
    }

    inSelection(option: any) {
        return option[this.optionValue] === this.selectedOption;
    }

}
