import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';

@Component({
    selector: 'app-form-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    standalone: false
})
export class CheckboxComponent implements OnInit, OnDestroy, FormField {
    @Input() formId;
    @Input() id: string;
    @Input() label;
    @Input() hint;
    @Input() disabled;
    @Input() readonly;
    @Input() required;
    @Input() value;
    @Input() options = [];
    @Input() optionLabelTranslate = false;
    @Input() optionLabel = 'label';
    @Input() optionValue = 'value';
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
    errorMessage: string;
    @Input() columns = 1;

    constructor(private formManagementService: FormManagementService) {

    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);
    }

    inputChange() {
        this.valueChange.emit(this.value);
        this.validate();
    }

    validate() {
        if (this.required && this.value.length === 0) {
            this.errorMessage = 'General.Required';
            return false;
        }

        this.errorMessage = '';
        return true;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }

    updateSelection(value: string) {
        if (Array.isArray(this.value)) {
            const index = this.value.findIndex((option: string) => {
                return option === value || option[this.optionValue] === value;
            }, 0);

            if (index >= 0) {
                this.value.splice(index, 1);
            } else {
                this.value.push(value);
            }
        } else {
            this.value = !this.value;
        }
    }

    inSelection(value: string): boolean {
        return Array.isArray(this.value) ? this.value.find((option: string) => {
            return option === value || option[this.optionValue] === value;
        }) : this.value;
    }
}
