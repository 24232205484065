import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cm-ticketing-a4-default-2',
    templateUrl: './ticket-template-a4-default-2.component.html',
    styleUrls: ['./ticket-template-a4-default-2.component.scss', '../../shared/shared-presets.scss'],
    standalone: false
})
export class TicketTemplateA4Default2Component implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
