import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';
import {LocaleService} from '../../../services/locale.service';
import {Translation} from '../../../../shared/models/translation';
import {
    TranslationDialogComponent
} from '../../../../component-library/components/translation-dialog/translation-dialog.component';
import {translate} from "../../../helpers/translation-helper";

@Component({
    selector: 'app-input-translation',
    templateUrl: './input-translation.component.html',
    styleUrls: ['./input-translation.component.scss'],
    standalone: false
})
export class InputTranslationComponent implements OnInit, OnDestroy, FormField {
    @Input() formId;
    @Input() id: string;
    @Input() label;
    @Input() hint;
    @Input() value;
    @Input() required;
    @Input() disabled;
    @Input() readOnly;
    @Input() autocomplete;
    @Input() placeholder;
    @Input() match;
    @Input() errorMessage;
    @Input() isValid = true;
    @Input() type = 'text';
    @Input() key: string;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() translationSaved: EventEmitter<any> = new EventEmitter<any>();
    @Input() translation;
    @Input() translationValuesKey = 'values';
    @Input() maxLength: number | undefined = undefined;
    language;

    @ViewChild('multiTranslationModal')
    multiTranslationModal: TranslationDialogComponent;

    constructor(
        private formManagementService: FormManagementService,
        private localeService: LocaleService) {
    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);
        this.language = this.localeService.getShortLanguage();

        if (!this.translation) {
            this.translation = new Translation(null, null, {
                EN: null,
                NL: null,
                DE: null,
                FR: null
            });
        }
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }

    inputChange() {
        this.valueChange.emit(this.value);
        this.validate();
    }

    validate() {
        this.isValid = true;

        if (this.required && !Object.values(this.translation.values).some(value => (value !== null))) {
            this.errorMessage = 'General.Required';
            this.isValid = false;
        }

        if (this.maxLength !== undefined && Object.values(this.translation.values)
            .filter((value): value is string => typeof value === 'string')
            .some((value) => value.length > this.maxLength)) {
            this.errorMessage = translate('General.Max_Length_Exceeded', [
                {key: 'maxLength', value: `${this.maxLength}`},
            ]);
            this.isValid = false;
        }

        return this.isValid;
    }

    onLanguageClicked() {
        this.multiTranslationModal.openDialog();
    }

    onTranslationChange(translations) {
        const useValue = this.getUseLanguage(translations);
        this.value = translations[useValue];
        this.validate();
        this.translationSaved.emit({message: translations, useValue});
    }

    getUseLanguage(values): string {
        if (values[this.language]) {
            return this.language;
        }

        for (const language of Object.keys(values)) {
            if (values[language]) {
                return language;
            }
        }

        return '';
    }
}
