import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {VenueSection} from '../../models/venue-section.class';
import {VenueSectionSeat} from '../../models/venue-section-seat.class';
import {TranslationPipe} from '../../../core/pipes/translation.pipe';
import {Subject, throttleTime} from 'rxjs';
import {Blockade} from '../../models/blockade.class';
import {SeatMapTool} from '../../models/seatmap/seat-map-tool.interface';
import {SelectTool} from '../../models/seatmap/select-tool.class';
import {TooltipConfig} from '../seat-map-tooltip/seat-map-tooltip.component';
import {BlockadeTool} from '../../models/seatmap/blockade-tool.class';
import {Customer} from '../../models/customer.class';
import {takeUntil} from 'rxjs/operators';
import {LocalReservationService} from '../../services/local-reservation.service';
import {SeatStatus} from '../../enums/seatmap/seat-status';
import {SeatmapSeatInfo} from '../venue-section-master-detail/seatmap-seat-info';
import {ProductGroupType} from '../../enums/order/product-group-type';
import {ReservationTool} from '../../models/seatmap/reservation-tool.class';

@Component({
    selector: 'app-seatmap',
    templateUrl: './seatmap.component.html',
    styleUrls: ['./seatmap.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SeatmapComponent implements OnInit, OnChanges {

    private defaultOptions: SeatMapOptions = {
        showName: true,
        showLegend: true,
        showOrderButton: false,
        showBlockadeButton: false,
        disableChosenSeats: false
    };

    @Input() venueSection: VenueSection;
    @Input() selectedVenueSectionSeats: SeatmapSeatInfo[] = [];
    @Input() blockades: Blockade[] = [];

    @Input()
    options: SeatMapOptions = this.defaultOptions;

    @Input()
    tool: SeatMapTool;

    @Output()
    venueSectionSeatClick: EventEmitter<VenueSectionSeat> = new EventEmitter<VenueSectionSeat>();

    @Output()
    venueSectionSeatsSelected: EventEmitter<VenueSectionSeat[]> = new EventEmitter<VenueSectionSeat[]>();

    @Output()
    venueSectionSeatsDeselected: EventEmitter<VenueSectionSeat[]> = new EventEmitter<VenueSectionSeat[]>();

    public viewBox: string;
    public venueSectionSeatRows = [];
    public isMultipleSubsections: boolean;

    public showingLegend = true;

    public legendItems = [];

    private xMin = 0;
    private yMin = 0;
    private xMax = 0;
    private yMax = 0;

    @ViewChild('svg')
    svg: ElementRef;

    private tooltipStream: Subject<TooltipConfig> = new Subject<TooltipConfig>();
    public tooltip$ = this.tooltipStream.asObservable();

    private mouseClickThrottleTimeInMs = 500;
    private seatMouseDownSubjects: [];

    @Input()
    eventId: string | undefined;

    @Input()
    subscriptionTypeId: string | undefined;

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private elementRef: ElementRef,
        private translationPipe: TranslationPipe,
        private localReservationService: LocalReservationService) {
    }

    handleThrottledSeatMouseDown(e: any, venueSectionSeat: VenueSectionSeat): void {
        if (e.button === 0 && !this.shouldSkipSeatEvent(venueSectionSeat)) {
            this.venueSectionSeatClick.emit(venueSectionSeat);
        }

        if (!this.shouldListenToMouseEvent(e, venueSectionSeat)) {
            return;
        }

        this.tool.seatMouseDown(venueSectionSeat);
    }

    ngOnInit(): void {
        this.initializeSeats();

        if (this.options.showLegend) {
            this.legendItems = [
                new LegendItem(this.translationPipe.transform('Seatmap.Status_Available'), '#70AD47'),
                new LegendItem(this.translationPipe.transform('Seatmap.Status_Unavailable'), '#9CA3AB'),
                new LegendItem(this.translationPipe.transform('Seatmap.Status_Issued'), '#FF4A00'),
                new LegendItem(this.translationPipe.transform('Seatmap.Status_Reserved'), '#44546A'),
                new LegendItem(this.translationPipe.transform('Seatmap.Status_Selected'), '#007FFF')
            ];
        }

        this.localReservationService.localReservation$.subscribe(reservation => {
            if (reservation.hasItems()) {
                return;
            }

            this.deselectLocalReservationSeats();
        });
    }

    private deselectLocalReservationSeats() {
        for (const venueSectionSeat of this.venueSection.seats) {
            if (!this.selectedVenueSectionSeats.some(seat => seat.venueSectionSeatId === venueSectionSeat.id)) {
                continue;
            }
            venueSectionSeat.available = 1;
            venueSectionSeat.status = SeatStatus.AVAILABLE;
        }

        this.selectedVenueSectionSeats = [];
        this.venueSectionSeatsDeselected.emit([]);
    }

    private initializeSeats() {
        this.seatMouseDownSubjects = [];
        this.venueSection.seats.forEach(currentSeat => {
            const subject = new Subject<{ event: MouseEvent, seat: VenueSectionSeat }>();

            subject.pipe(throttleTime(this.mouseClickThrottleTimeInMs), takeUntil(this.destroy$)).subscribe(
                ({event, seat}) => {
                    this.handleThrottledSeatMouseDown(event, seat);
                }
            );

            this.seatMouseDownSubjects[currentSeat.id] = subject;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.venueSection?.currentValue && this.venueSection?.seats.length > 0) {
            this.viewBox = this.getViewBox(this.venueSection.seats);

            this.venueSection = {
                ...changes.venueSection.currentValue, seats: changes.venueSection.currentValue.seats.map(seat => {
                    if (seat.venueSectionSubsectionId) {
                        seat._transform = `translate(${((seat.x - this.xMin) * 3.2) + 100}, ${(seat.y - this.yMin) * 3.2})`;
                        seat._textTransform = `translate(${((seat.x - this.xMin + 16) * 3.2) + 100}, ${(seat.y - this.yMin + 18) * 3.2})`;
                        seat._blockadeTransform = `translate(${((seat.x - this.xMin) * 3.2) + 109}, ${(seat.y - this.yMin) * 3.2 + 50})`;
                        seat._blockadeTextTransform = `translate(${((seat.x - this.xMin + 16) * 3.2 + 100)}, ${(seat.y - this.yMin + 18) * 3.2 + 20})`;
                    } else {
                        seat._transform = `translate(${((seat.x - this.xMin) * 11) + 100}, ${(seat.y - this.yMin) * 11})`;
                        seat._textTransform = `translate(${((seat.x - this.xMin + 4.5) * 11) + 100}, ${(seat.y - this.yMin + 5.4) * 11})`;
                        seat._blockadeTransform = `translate(${((seat.x - this.xMin) * 11) + 109}, ${(seat.y - this.yMin) * 11 + 50})`;
                        seat._blockadeTextTransform = `translate(${((seat.x - this.xMin + 4.5) * 11 + 100)}, ${(seat.y - this.yMin + 5.4) * 11 + 20})`;
                    }

                    return seat;
                })
            };

            const firstSubsection = this.venueSection.seats[0].venueSectionSubsectionId;
            this.isMultipleSubsections = false;
            this.venueSectionSeatRows = [];

            for (const venueSectionSeat of this.venueSection.seats) {
                if (venueSectionSeat.venueSectionSubsectionId !== firstSubsection) {
                    this.isMultipleSubsections = true;
                }
                let rowInfo;
                if (venueSectionSeat.venueSectionSubsectionId) {
                    rowInfo = {
                        startX: '20',
                        endX: `${((this.xMax - this.xMin + 20) * 3.2) + 210}`,
                        startY: `${(venueSectionSeat.y - this.yMin + 20) * 3.2}`,
                        endY: `${((venueSectionSeat.y - this.yMin + 20) * 3.2)}`,
                        row: venueSectionSeat.row
                    };
                } else {
                    rowInfo = {
                        startX: '20',
                        endX: `${((this.xMax + 4.5) * 11) + 120}`,
                        startY: `${((venueSectionSeat.y + 0.5) - this.yMin + 5.4) * 11}`,
                        endY: `${(((venueSectionSeat.y + 0.5) - this.yMin + 5.4) * 11)}`,
                        row: venueSectionSeat.row
                    };
                }
                const exists = this.venueSectionSeatRows.find(section => {
                    if (section.row === rowInfo.row) {
                        return section;
                    }
                });

                if (!exists) {
                    this.venueSectionSeatRows.push(rowInfo);
                }
            }
        }

        if (changes.venueSection || changes.blockades) {
            this.blockades?.forEach(blockade => {
                this.legendItems.push(new LegendItem(blockade.name, blockade.color));

                this.venueSection.seats.forEach(venueSectionSeat => {
                    if (blockade.venueSectionSeatIds.includes(venueSectionSeat.id)) {
                        venueSectionSeat._color = blockade.color;
                        venueSectionSeat._label = blockade.label;
                        venueSectionSeat._blockadeId = blockade.id;
                        venueSectionSeat._originalBlockadeId = blockade.id;
                    }
                });
            });
        }

        if (changes.tool?.currentValue instanceof BlockadeTool) {
            this.elementRef.nativeElement.classList.add('blockade-cursor');
        } else {
            this.elementRef.nativeElement.classList.remove('blockade-cursor');
        }
    }

    getViewBox(venueSectionSeats: VenueSectionSeat[]): string {
        let containsVenueDesignedSection = false;

        this.xMin = 0;
        this.yMin = 0;
        this.xMax = 0;
        this.yMax = 0;

        venueSectionSeats.forEach((venueSectionSeat: VenueSectionSeat) => {
            if (venueSectionSeat.x > this.xMax) {
                this.xMax = venueSectionSeat.x;
            }

            if (venueSectionSeat.y > this.yMax) {
                this.yMax = venueSectionSeat.y;
            }

            if (venueSectionSeat.venueSectionSubsectionId) {
                containsVenueDesignedSection = true;
            }
        });

        this.xMin = this.xMax;
        this.yMin = this.yMax;

        venueSectionSeats.forEach((venueSectionSeat: VenueSectionSeat) => {

            if (venueSectionSeat.x < this.xMin) {
                this.xMin = venueSectionSeat.x;
            }

            if (venueSectionSeat.y < this.yMin) {
                this.yMin = venueSectionSeat.y;
            }
        });

        let x;
        let y;

        if (containsVenueDesignedSection) {
            x = ((this.xMax - this.xMin + 32) * 3.2) + 200;
            y = (this.yMax - this.yMin + 32) * 3.2;
        } else {
            x = ((this.xMax - this.xMin + 10) * 11) + 200;
            y = (this.yMax - this.yMin + 10) * 11;
        }

        return `0 0 ${x} ${y}`;
    }

    getVenueSectionSeatStatus(venueSectionSeat: VenueSectionSeat) {
        const classes = ['seat'];

        if (this.seatInCurrentOrder(venueSectionSeat)) {
            classes.push('active-order');
        } else {
            if (venueSectionSeat.details?.status) {
                classes.push(venueSectionSeat.details.status.toLowerCase());

                if (this.options.disableChosenSeats) {
                    classes.push('disabled');
                }
            }
        }

        const isSeatSelected = this.selectedVenueSectionSeats.some(seat =>
            seat.isSelected(venueSectionSeat.id, this.eventId ?? this.subscriptionTypeId)
        );
        if (venueSectionSeat.available > 0) {
            classes.push('available');
        }

        if (venueSectionSeat.details?.subscriptionId && venueSectionSeat.details?.status === 'ISSUED') {
            classes.push('subscription');
        }

        if (!this.tool && isSeatSelected) {
            // only applicable to the move tool seat map which does not have a tool
            classes.push('selected');
        }

        classes.push('section-path');

        return Array.from(new Set(classes)).join(' ');
    }

    private shouldSkipSeatEvent(venueSectionSeat: VenueSectionSeat): boolean {
        return this.options.disableChosenSeats && !venueSectionSeat?.isAvailable();
    }

    private shouldListenToMouseEvent(e: any, venueSectionSeat: VenueSectionSeat = null): boolean {
        return this.tool && e.button === 0 && !this.shouldSkipSeatEvent(venueSectionSeat);
    }

    seatMouseDown(e: any, venueSectionSeat: VenueSectionSeat): void {
        this.seatMouseDownSubjects[venueSectionSeat.id].next({event: e, seat: venueSectionSeat});
    }

    seatMouseMove(e: any, venueSectionSeat: VenueSectionSeat): void {
        if (!this.shouldListenToMouseEvent(e, venueSectionSeat)) {
            return;
        }

        this.tool.seatMouseMove(e, venueSectionSeat);

        if (this.tool instanceof SelectTool || this.tool instanceof ReservationTool) {
            this.tooltipStream.next({
                position: {
                    x: e.x + 12,
                    y: e.y + 12
                },
                content: this.getTooltipText(venueSectionSeat)
            });
        }
    }

    seatMouseEnter(e: any, venueSectionSeat: VenueSectionSeat): void {
        if (!this.shouldListenToMouseEvent(e, venueSectionSeat)) {
            return;
        }
        this.tool.seatMouseEnter(e, venueSectionSeat);
    }

    seatMouseOut(e: any, venueSectionSeat: VenueSectionSeat): void {
        if (!this.shouldListenToMouseEvent(e, venueSectionSeat)) {
            return;
        }

        this.tooltipStream.next({content: ''});
        this.tool.seatMouseOut(e, venueSectionSeat);
    }

    svgMouseDown(e: any): void {
        if (!this.shouldListenToMouseEvent(e)) {
            return;
        }

        this.tool.svgMouseDown(e);
    }

    svgMouseMove(e: any): void {
        if (!this.shouldListenToMouseEvent(e)) {
            return;
        }

        this.tool.svgMouseMove(e);
    }

    svgMouseUp(e: any): void {
        if (!this.shouldListenToMouseEvent(e)) {
            return;
        }

        this.venueSectionSeatsSelected.emit(this.tool.getSelectedSeats());

        this.tool.svgMouseUp(e);
    }

    svgMouseLeave(e: any): void {
        if (!this.shouldListenToMouseEvent(e)) {
            return;
        }

        this.tool.svgMouseLeave(e);
    }

    private seatInCurrentOrder(venueSectionSeat: VenueSectionSeat): boolean {
        const productType = this.eventId ? ProductGroupType.EVENT_TICKET : ProductGroupType.SUBSCRIPTION;
        return !!this.localReservationService.findOrderItem(productType, this.eventId ?? this.subscriptionTypeId, venueSectionSeat.id);
    }

    private isTouchDevice() {
        return window.matchMedia('(pointer: coarse)').matches;
    }

    private getTooltipText(venueSectionSeat: VenueSectionSeat): string {
        if (this.isTouchDevice()) {
            return;
        }

        let seatInfo = '';

        venueSectionSeat.eventTickets.forEach((eventTicket) => {
            if (eventTicket?.customer) {
                seatInfo += `<div style="font-size: 16px;font-weight:500;">${(eventTicket.customer as Customer).getName()}</div>`;
            }

            if (eventTicket?.order) {
                seatInfo += `<div>${eventTicket.order.batchId}</div>`;
            }
        });

        seatInfo += `<div>Row ${venueSectionSeat.row}, seat ${venueSectionSeat.seat}</div>`;

        return seatInfo;
    }

}

export interface SeatMapOptions {
    showName?: boolean;
    showLegend?: boolean;
    showBlockadesLegend?: boolean;
    showOrderButton?: boolean;
    showBlockadeButton?: boolean;
    disableChosenSeats?: boolean;
}

export class LegendItem {
    constructor(
        public label: string,
        public color: string
    ) {
    }
}
