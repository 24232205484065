import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import moment from 'moment';
import {hasIdOrParentWithId, hasParentOfType, isOfType} from '../../../core/helpers/dom-helper';

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchFilterComponent implements OnInit, OnChanges {
    @ViewChild('button') button: ElementRef;
    @ViewChild('dropdown') dropdown: ElementRef;
    @Input() name: string;
    @Input() options: any[];
    @Input() type = 'CHECKBOX'; // CHECKBOX, RADIO, DATE_RANGE
    @Input() isSearchable = true;
    @Input() optionLabel = 'label';
    @Input() optionValue = 'value';
    @Input() selectedOptions: any = [];
    @Output() selectedOptionsChange: EventEmitter<any> = new EventEmitter<any>();
    searchedOptions: any[];
    isOpen = false;

    dateRangeOptions = [
        {label: 'Date_Range.No_Filter', value: 'ALL'},
        {label: 'Date_Range.Today', value: 'TODAY'},
        {label: 'Date_Range.Yesterday', value: 'YESTERDAY'},
        {label: 'Date_Range.Last_7_Days', value: 'LAST_7_DAYS'},
        {label: 'Date_Range.Last_30_Days', value: 'LAST_30_DAYS'},
        {label: 'Date_Range.Custom', value: 'CUSTOM'}
    ];

    expireDateRangeOptions = [
        {label: 'Date_Range.No_Filter', value: 'ALL'},
        {label: 'Date_Range.Within_One_Day', value: 'WITHIN_ONE_DAY'},
        {label: 'Date_Range.Within_One_Week', value: 'WITHIN_ONE_WEEK'},
        {label: 'Date_Range.Within_one_Month', value: 'WITHIN_ONE_MONTH'},
        {label: 'Date_Range.Custom', value: 'CUSTOM'}
    ];

    constructor() {
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(target) {
        const button = this.button.nativeElement.contains(target);
        const dropdown = this.dropdown.nativeElement.contains(target);

        const dateTimePickerPopupElements = ['cm-time-selector-popup', 'cm-date-time-popup', 'cm-date-time-year', 'cm-date-time-month'];
        const targetIsPartOfDatePicker =
            isOfType(target, dateTimePickerPopupElements) ||
            hasParentOfType(target, dateTimePickerPopupElements) ||
            hasIdOrParentWithId(target, 'header-selector') ||
            hasIdOrParentWithId(target, 'header-selector-icon');

        if (this.isOpen && !button && !dropdown && !targetIsPartOfDatePicker) {
            this.closeDropdown();
        }
    }

    ngOnInit() {
        if (this.type === 'DATE_RANGE') {
            this.closeDropdown();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options) {
            this.searchedOptions = this.options;
        }
    }

    reset() {
        this.selectedOptions = [];
        this.closeDropdown();
    }

    openDropdown() {
        this.isOpen = true;
    }

    radioValueChanged(optionId: string) {
        if (optionId === 'CUSTOM') {
            return;
        }
        this.closeDropdown();
    }

    closeDropdown() {
        this.isOpen = false;

        if (this.type === 'DATE_RANGE') {
            if (this.selectedOptions.id === 'ALL') {
                this.selectedOptions.startAt = null;
                this.selectedOptions.endAt = null;
            }

            if (this.selectedOptions.id === 'TODAY') {
                this.selectedOptions.startAt = moment().startOf('day').toISOString();
                this.selectedOptions.endAt = moment().endOf('day').toISOString();
            }

            if (this.selectedOptions.id === 'YESTERDAY') {
                this.selectedOptions.startAt = moment().subtract(1, 'day').startOf('day').toISOString();
                this.selectedOptions.endAt = moment().subtract(1, 'day').endOf('day').toISOString();
            }

            if (this.selectedOptions.id === 'LAST_7_DAYS') {
                this.selectedOptions.startAt = moment().subtract(7, 'day').startOf('day').toISOString();
                this.selectedOptions.endAt = moment().endOf('day').toISOString();
            }

            if (this.selectedOptions.id === 'LAST_30_DAYS') {
                this.selectedOptions.startAt = moment().subtract(30, 'day').startOf('day').toISOString();
                this.selectedOptions.endAt = moment().endOf('day').toISOString();
            }
        }

        if (this.type === 'EXPIRE_DATE_RANGE') {
            switch (this.selectedOptions.id) {
                case 'ALL':
                    this.selectedOptions.startAt = null;
                    this.selectedOptions.endAt = null;
                    break;

                case 'WITHIN_ONE_DAY':
                    this.selectedOptions.startAt = moment().subtract(1, 'day').endOf('day').toISOString();
                    this.selectedOptions.endAt = moment().add(1, 'day').endOf('day').toISOString();
                    break;

                case 'WITHIN_ONE_WEEK':
                    this.selectedOptions.startAt = moment().subtract(1, 'day').endOf('day').toISOString();
                    this.selectedOptions.endAt = moment().add(1, 'weeks').endOf('day').toISOString();
                    break;

                case  'WITHIN_ONE_MONTH':
                    this.selectedOptions.startAt = moment().subtract(1, 'day').endOf('day').toISOString();
                    this.selectedOptions.endAt = moment().add(1, 'months').endOf('day').toISOString();
                    break;

                default:
                    break;
            }
        }

        this.selectedOptionsChange.emit(this.selectedOptions);
    }

    toggleDropdown() {
        if (this.isOpen) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
    }

    search($event) {
        const value = $event.target.value;
        const options = JSON.parse(JSON.stringify(this.options));
        const searchedOptions = [];

        for (const category of options) {
            category.options = category.options.filter(option => option[this.optionLabel]?.toLowerCase().includes(value.toLowerCase()));

            if (category.options.length > 0) {
                searchedOptions.push(category);
            }
        }

        this.searchedOptions = searchedOptions;
    }

    public isDisplayName(): boolean {
        return this.type !== 'DATE_RANGE' || (!this.selectedOptions.endAt && !this.selectedOptions.startAt && !this.selectedOptions.id);
    }

    public onStartDateChange(e: any): void {
        this.selectedOptions.startAt = e.detail.datetime;
    }

    public onEndDateChange(e: any): void {
        this.selectedOptions.endAt = e.detail.datetime;
    }
}
