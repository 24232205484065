import {
    AfterViewInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import {NgForm, NgModel} from '@angular/forms';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css'],
    standalone: false
})
export class DialogComponent implements AfterViewInit {
    @Input() id: string;

    @Input() title: string;
    @Input() body?: string;
    @Input() width = 400;

    @Input() scope: 'CONFIRM' | 'SAVE' | 'ACKNOWLEDGE' = 'SAVE';
    @Input() showFooter = true;
    @Input() saveDisabled = false;
    @Input() showBorders = false;

    @Input() confirmButtonId: string;

    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() save: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('cmDialog') public cmDialog;
    @ViewChild(NgForm) public form: NgForm;
    @ContentChildren(NgModel, {descendants: true}) public models: QueryList<NgModel>;

    public data: any = {};

    ngAfterViewInit(): void {
        const ngContentModels = this.models.toArray();
        ngContentModels.forEach((model) => {
            this.form.addControl(model);
        });
    }

    open(): void {
        this.cmDialog.nativeElement.open();
    }

    close(): void {
        this.cmDialog.nativeElement.close();
    }

    onCancel(): void {
        this.cancel.emit();
        this.close();
    }

    onSave(): void {
        this.save.emit({...this.form?.value, data: {...this.data}});
        this.close();
    }

    setData(data: any): void {
        this.data = data;
    }

    addData(key: string, value: any): void {
        const data = this.data;
        data[key] = value;

        this.data = {...data};
    }

}
