import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges, OnInit,
    Output, SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-list-picker',
    templateUrl: './list-picker.component.html',
    styleUrls: ['./list-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListPickerComponent implements OnChanges, OnInit {

    @Input() list: any[] = [];
    @Input() selectedInputList: any[] = [];
    @Input() listIdentifier = 'id';
    @Input() selectedIdentifier = 'id';
    @Input() checkIsListItemDisabled: (listItem) => boolean = null;
    @Input() hideDisabledItems = true;
    @Input() disabledItemNameExpansion = '';

    selectedList: any[] = [];

    @Output() selectedChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor(
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.initializeDisabledItems();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.list) {
            this.selectedList = [];
            this.selectedInputList.forEach(selectedInputItem => {
                this.list.forEach(listItem => {
                    if (listItem[this.listIdentifier] === selectedInputItem[this.selectedIdentifier]) {
                        this.selectedList.push(listItem);
                    }
                });
            });

            this.changeDetectorRef.markForCheck();
        }
        this.initializeDisabledItems();
    }

    initializeDisabledItems() {
        if (!this.checkIsListItemDisabled) {
            this.list.map(item => {
                item.isDisabled = false;
                return item;
            });
            return;
        }

        this.list.map(item => {
            item.isDisabled = this.checkIsListItemDisabled(item);
            return item;
        });
    }

    isListItemSelected(listItem: any): boolean {
        return this.selectedList.findIndex(listItemI => listItemI[this.listIdentifier] === listItem[this.listIdentifier]) >= 0;
    }

    toggleSelectListItem(listItem: any): void {
        const index = this.selectedList.findIndex(listItemI => listItemI === listItem);

        if (index >= 0) {
            this.selectedList.splice(index, 1);
        } else {
            this.selectedList.push(listItem);
        }

        this.selectedChanged.emit(this.selectedList);
    }

    onSelectAllClicked(): void {
        this.selectedList = [];
        this.list.forEach(listItem => {
            this.selectedList.push(listItem);
        });

        this.selectedChanged.emit(this.selectedList);
    }

    onDeselectAllClicked(): void {
        this.selectedList = [];
        this.selectedChanged.emit(this.selectedList);
    }

}
