import { HttpParams } from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {EventTicket} from '../../models/event-ticket.class';
import {OrderItem} from '../../models/order-item.class';
import {Order} from '../../models/order.class';
import {SubscriptionType} from '../../models/subscription-type.class';
import {TicketType} from '../../models/ticket-type.class';
import {VenueSection} from '../../models/venue-section.class';
import {Venue} from '../../models/venue.class';
import {OrderManagementService} from '../../services/order-service/order-management.service';
import {SubscriptionService} from '../../services/subscription-service/subscription.service';
import {VenueManagementService} from '../../services/venue-service/venue-management.service';
import {VenueService} from '../../services/venue-service/venue.service';
import {DiscountCampaignService} from '../../services/discount-campaign-service/discount-campaign.service';
import {DiscountCampaign} from '../../models/discount-campaign';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {DialogOrderAddDiscountComponent} from '../dialogs/dialog-order-add-discount/dialog-order-add-discount.component';

@Component({
    selector: 'app-order-table',
    templateUrl: './order-table.component.html',
    styleUrls: ['./order-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrderTableComponent implements OnInit, OnChanges {
    @Input() order: Order;
    @Input() editable = false;
    @Output() orderItemCanceled: EventEmitter<string> = new EventEmitter<string>();
    venues: Venue[] = [];
    venueRequestedIds = [];
    venueSections: VenueSection[];
    subscriptionTypes$: Observable<SubscriptionType[]>;
    discountCampaigns$: Observable<DiscountCampaign[]>;
    displayOrderNote: string;

    // Pagination
    @Input() currentPage = 1;
    itemsPerPage = 10;

    constructor(private orderManagementService: OrderManagementService,
                private dialogService: DialogService,
                private subscriptionService: SubscriptionService,
                private venueService: VenueService,
                private venueManagementService: VenueManagementService,
                private changeDetector: ChangeDetectorRef,
                private discountCampaignService: DiscountCampaignService) {
    }

    ngOnInit() {
        this.venueSections = [];
        this.displayOrderNote = null;
        this.subscriptionTypes$ = this.subscriptionService.getSubscriptionTypes();
        this.discountCampaigns$ = this.discountCampaignService.getDiscountCampaigns(new HttpParams().set('depth', '3').set('discountCampaign[dashboard]', 'true'));
    }

    ngOnChanges() {
        this.changeDetector.markForCheck();
    }

    cancelOrderItem(orderItem: OrderItem) {
        this.orderItemCanceled.emit(orderItem.id);
    }

    deleteOrderItem(orderItem: OrderItem) {
        orderItem._loading = true;
        this.orderManagementService.deleteOrderItem(orderItem.id, true, () => this.venueManagementService.fetchVenueSectionSeats([orderItem]));
    }

    updateOrderItemType(selectedId, orderItem: OrderItem, orderItemsGroupId: string) {
        orderItem._loading = true;
        let orderItemBody = null;

        const orderItemTicket = orderItem.ticket;
        const orderItemProduct = orderItem.product;
        if (orderItemTicket) {
            orderItemBody = {
                ticket: {
                    eventId: orderItemTicket.eventId,
                    venueSectionSeatId: orderItemTicket.venueSectionSeatId,
                    ticketTypeId: selectedId
                }
            };
        } else if (orderItemProduct) {
            orderItemBody = {
                product: {
                    orderItemId: orderItemProduct.orderItemId,
                    productTypeId: orderItemsGroupId,
                    productTypePriceId: selectedId
                }
            };
        }
        this.orderManagementService.updateOrderItem(orderItem.id, orderItemBody, true);
    }

    updateOrderItemPrice(orderItem: OrderItem) {
        this.orderManagementService.updateOrderItem(orderItem.id, {
            price: {
                price: orderItem.price,
                vat: orderItem.vat,
                currencyId: orderItem.currency
            }
        }, true);
    }

    updateOrderSubscriptionTypePrice(subscriptionTypePriceId: string, orderItems: OrderItem[]) {
        const request = [];

        for (const orderItem of orderItems) {
            orderItem._loading = true;
            request.push({
                id: orderItem.id,
                subscription: {
                    subscriptionTypePriceId: subscriptionTypePriceId,
                    venueSectionSeatId: orderItem.subscription.seat.id
                }
            });
        }

        this.orderManagementService.updateOrderItems(request, true);
    }

    updateOrderProductTypePrice(productTypePriceId: string, orderItems: OrderItem[]) {
        const request = [];

        for (const orderItem of orderItems) {
            orderItem._loading = true;
            request.push({
                id: orderItem.id,
                product: {
                    productTypePriceId: productTypePriceId,
                    venueSectionSeatId: orderItem.subscription.seat.id
                }
            });
        }

        this.orderManagementService.updateOrderItems(request, true);
    }

    updateOrderItemSubscriptionTypePrice(orderItem: OrderItem, subscriptionTypePriceId: string) {
        orderItem._loading = true;
        this.orderManagementService.updateOrderItem(orderItem.id, {
            id: orderItem.id,
            subscription: {
                subscriptionTypePriceId: subscriptionTypePriceId,
                venueSectionSeatId: orderItem.subscription.seat?.id
            }
        }, true);
    }

    updateOrderPrice(price: number, orderItems: OrderItem[]) {
        const request = [];

        for (const orderItem of orderItems) {
            orderItem._loading = true;
            request.push({
                id: orderItem.id,
                price: {
                    price: price,
                    vat: orderItem.vat,
                    currency: orderItem.currency
                }
            });
        }

        this.orderManagementService.updateOrderItems(request, true);
    }

    updateOrderVenueSectionAccess(id, orderItems: OrderItem[]) {
        const request = [];

        for (const orderItem of orderItems) {
            orderItem._loading = true;
            request.push({
                id: orderItem.id,
                venueSectionAccess: [id]
            });
        }

        this.orderManagementService.updateOrderItems(request, true);
    }

    updateOrderTicketType(ticketTypeId, orderItems: OrderItem[]) {
        const request = [];

        for (const orderItem of orderItems) {
            orderItem._loading = true;
            request.push({
                id: orderItem.id,
                ticket: {
                    eventId: orderItem.ticket.eventId,
                    venueSectionSeatId: orderItem.ticket.venueSectionSeatId,
                    ticketTypeId: ticketTypeId
                }
            });
        }

        this.orderManagementService.updateOrderItems(request, true);
    }

    updateOrderItemNote(text, orderItem: OrderItem) {
        this.orderManagementService.updateOrderItem(orderItem.id, {
            note: text
        }, true);
    }

    updateOrderItemVenueSectionAccess(id, orderItem: OrderItem) {
        this.orderManagementService.updateOrderItem(orderItem.id, {
            venueSectionAccess: [id]
        }, true);
    }

    openOrderItemNote(orderItemId) {
        if (orderItemId === this.displayOrderNote) {
            this.displayOrderNote = null;
        } else {
            this.displayOrderNote = orderItemId;
        }
    }

    getVenue(venueId) {
        if (!venueId) {
            return null;
        }

        let venue = this.venues.find((venueItem) => venueItem.id === venueId);
        if (!venue) {
            const index = this.venueRequestedIds.indexOf(venueId);
            if (index < 0) {
                this.venueRequestedIds.push(venueId);
                this.venueService.getVenue(venueId, new HttpParams().set('depth', '3')).subscribe(venueData => {
                    venue = venueData;
                    this.venues.push(venue);
                    this.changeDetector.markForCheck();
                });
            }
        }

        return venue;
    }

    getTicketTypePrice(ticketType: TicketType, eventTicket: EventTicket) {
        return true;

        // return ticketType.prices.filter(price => {
        //     if (price.venueSectionId === eventTicket.venueSectionId) {
        //         return price;
        //     }
        // })[0];
    }

    openDiscountDialog() {
        this.dialogService.createDialogComponent(DialogOrderAddDiscountComponent, {
            extraData: {
                order: this.order,
            }
        }, 'order-add-discount');
    }

    deleteOrderDiscount(discountId: string) {
        this.orderManagementService.deleteOrderDiscount(discountId);
    }

    deleteAdditionalCost(costItem: string) {
        if (costItem === 'ORDER_ADMINISTRATION_FEE') {
            this.orderManagementService.updateOrder({administrationFeeId: null});
        }
    }
}
