import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Order} from '../../models/order.class';

@Component({
    selector: 'app-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss'],
    standalone: false
})
export class OrderSummaryComponent implements OnChanges {

    @Input() order: Order;

    orderItems: any[] = [];

    showAllOrderItems = false;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.orderItems = [];
        this.showAllOrderItems = false;

        this.order.groupedOrderItems.forEach(groupedOrderItem => {
            groupedOrderItem.items.forEach(item => {
                this.orderItems.push({
                    name: groupedOrderItem.name,
                    price: item.price,
                    currency: item.currency,
                    orderItem: item.ticket || item.subscription || item.product,
                    additionalCosts: item.additionalCosts
                });
            });
        });
    }

}
