<ng-container *ngIf="customer$ | async as customer">
    <div class="page-content">
        <div class="page-section">

            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="page-section">
                            <h3>{{ ('Customer_Form.Note' | trans) }}</h3>
                            <div class="container-group">
                                <ng-container *ngFor="let customerNote of customer.customerNotes">
                                    <div class="container-group-row row" [class.bg-yellow-25]="!customerNote.id">
                                        <div class="container-group-item row">
                                            <div class="col-12">
                                                <div class="container-group-col header row">
                                                    <div class="meta-avatar col">
                                                        <cm-avatar
                                                            data-image="{{customerNote.actor.avatarUrl}}"></cm-avatar>
                                                    </div>
                                                    <div class="col">
                                                        <span>{{ customerNote.actor.name ? customerNote.actor.name : customerNote.actor.type }}</span>
                                                        <div class="row container-sub-header">
                                                            <cm-tooltip
                                                                [attr.data-tooltip-text]="customerNote.createdAt | date:'H:mm EE d MMM y'">{{ customerNote.getElapsedTime() }}
                                                            </cm-tooltip>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 text-right">
                                                        <button class="btn btn-secondary-icon"
                                                                (click)="deleteCustomerNote(customerNote)">
                                                            <span class="cm-icon cm-icon-delete"
                                                                  aria-hidden="true"></span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="container-group-col content"
                                                     [innerHTML]="customerNote.content"></div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <ng-container *ngIf="showCustomerNoteEditor">
                            <div class="page-section">
                                <div class="row">
                                    <div class="col-12">
                                        <cm-text-editor
                                            data-show-editor-options="true"
                                            [attr.data-innertext]="newCustomerNote.content"
                                            data-custom-height="100px"
                                            data-placeholder="Start typing"
                                            [attr.data-toolbar-options]="editorOptions"
                                            (change)="setCustomerNoteContent($event)"
                                        >
                                        </cm-text-editor>
                                    </div>
                                </div>
                            </div>

                            <div class="page-section">
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <button class="btn btn-primary" (click)="saveCustomerNote();">
                                            <span>{{ ('General.Save' | trans) }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!showCustomerNoteEditor">
                            <div class="page-section">
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <button class="btn btn-primary" (click)="showCustomerNoteEditor = true;">
                                            <span>{{ ('General.Add' | trans) }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="card">
                        @if (inviteEnabled) {
                            <app-customer-invite-list [customer]="customer"></app-customer-invite-list>
                        } @else {
                            <app-customer-guest-manager-list [customer]="customer"></app-customer-guest-manager-list>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>
