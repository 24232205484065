import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Component({
    selector: 'cm-ticketing-data-table-footer-data',
    templateUrl: './data-table-footer-data.component.html',
    styleUrls: ['./data-table-footer-data.component.css'],
    standalone: false
})
export class DataTableFooterDataComponent implements OnInit, AfterViewInit {
    @ViewChild('footerData') footerData: ElementRef;
    @Input() value;
    @Input() options;
    public displayValue;
    public alignRight = false;

    @Input() selected = 0;

    isCheckbox = false;

    constructor(private currencyPipe: CurrencyPipe) { }

    ngOnInit(): void {
        this.displayValue = this.value ? this.value : '';
        this.isCheckbox = this.options.some(option => option.name === 'data-checkbox');
        for (const option of this.options) {
            if (option.name === 'data-align-right') {
                this.alignRight = true;
            } else if (option.name === 'data-currency') {
                const splittedValue = option.value.split('|');
                this.displayValue = `<div class="currency">${this.currencyPipe.transform(this.value,
                    splittedValue[0] ? splittedValue[0] : 'EUR', 'symbol-narrow', null,
                    splittedValue[1] ? splittedValue[1] : 'nl-NL')}</div>`;
            }
        }
    }

    ngAfterViewInit(): void {
        for (const option of this.options) {
            if (this.footerData) {
                this.footerData.nativeElement.setAttribute(option.name, option.value);
            }
        }
    }

}
