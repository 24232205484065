import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslationService} from './translation.service';
import {TranslationHelper} from '../helpers/translation-helper';

@Pipe({
    name: 'trans',
    standalone: false
})

export class TranslationPipe implements PipeTransform {
  constructor(private translationService: TranslationService) {
  }

  transform(value: string = ''): Observable<string> {
      return TranslationHelper.translateValue(this.translationService, value);
  }
}
