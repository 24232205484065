import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {OrderDistributionService} from '../../services/order-distribution.service';
import {EmailTemplateGroup} from '../../models/email-template-group';
import {EmailTemplate} from '../../models/email-template';
import {ResourceService} from '../../services/resource.service';
import {combineLatest, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {TranslationDialogComponent} from '../translation-dialog/translation-dialog.component';
import {EmailImageTypeEnum} from '../../models/email-image-type-enum';
import {EmailImagePositionEnum} from '../../models/email-image-position-enum';

@Component({
    selector: 'cm-ticketing-email-template-editor',
    templateUrl: './email-template-editor.component.html',
    styleUrls: ['./email-template-editor.component.css'],
    standalone: false
})
export class EmailTemplateEditorComponent implements OnInit, OnDestroy {
    @ViewChild('cmfilter') cmfilter: ElementRef;
    @ViewChild('messageTextTranslationDialog') messageTextTranslationDialog: TranslationDialogComponent;
    @ViewChild('subjectTextTranslationDialog') subjectTextTranslationDialog: TranslationDialogComponent;
    @ViewChild('buttonTextTranslationDialog') buttonTextTranslationDialog: TranslationDialogComponent;
    // editingEmailTemplates are all the email templates that are being edited
    @Input() emailTemplateGroupId: string;
    @Input() emailTemplateId: string;
    @Input() currentLang: string;
    @Input() params: any;
    @Input() emailTemplateSubject: any;
    @Input() isDownloadable: boolean;
    @Input() availableLanguages = ['NL', 'EN', 'DE', 'FR', 'AR', 'ES'];

    @Input() messageTypes: string[] = null;

    @Input() timezone: any = null;

    @Output()
    navigateBack: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    emailSaveClick: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    emailTextClick: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    emailSubjectClick: EventEmitter<any> = new EventEmitter<any>();

    public emailTemplateGroup$: Observable<EmailTemplateGroup>;
    public emailTemplates$: Observable<EmailTemplate[]>;

    // Variables
    public emailTemplateGroup: EmailTemplateGroup;
    public emailTemplate: EmailTemplate;
    public emailTemplates: EmailTemplate[];
    public messageTypeIds: any[];
    public emailTemplateMessageTexts: any = {
        NL: null,
        EN: null,
        DE: null,
        FR: null,
        AR: null,
        ES: null
    };
    public emailTemplateSubjectTexts: any = {
        NL: null,
        EN: null,
        DE: null,
        FR: null,
        AR: null,
        ES: null
    };
    public emailTemplateButtonTexts: any = {
        NL: null,
        EN: null,
        DE: null,
        FR: null,
        AR: null,
        ES: null
    };
    private editingEmailTemplates: EmailTemplate[];
    private headerImageType = EmailImageTypeEnum.LOGO;

    // Colours
    public currentBackgroundColour: string;
    public currentBackgroundHighlightColour: string;
    public currentBackgroundHighlightTextColour: string;

    // Edit template elements
    public headerImageElement: HTMLImageElement;
    public footerBannerImageElement: HTMLImageElement;
    public templateElement: HTMLElement;
    public messageText: string;
    public backgroundElements: any;
    public buttonBackgroundElements: any;
    public buttonBorderElements: any;
    public buttonTextElements: any;
    public buttonTextElement: any;
    public messageElement: HTMLParagraphElement;
    public calendarElement: HTMLElement;

    public isLoading: boolean;

    public state$: Observable<any>;

    public defaultLogoUrl = 'https://api.ticketing.cm.com/ticketingresourceapi/v2.0/resource/fcff2793-adcb-469c-87d6-4fc5bfb7aa69/content';

    public showUnstyleableMessageTypeBanner = false;
    private unstyleableMessageTypes = ['GUEST_MANAGER_USER_ACTIVATION'];
    public emailImagePositionEnum = EmailImagePositionEnum;

    constructor(private orderDistributionService: OrderDistributionService, private resourceService: ResourceService, private renderer: Renderer2) {
        this.isDownloadable = true;
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.emailTemplateGroup$ = this.orderDistributionService.getEmailTemplateGroup(this.emailTemplateGroupId);
        this.emailTemplates$ = this.orderDistributionService.getEmailTemplates(this.emailTemplateGroupId);

        this.state$ = combineLatest([this.emailTemplateGroup$, this.emailTemplates$]).pipe(
            tap(([emailTemplateGroup, emailTemplates]) => {
                this.emailTemplateGroup = emailTemplateGroup;
                this.emailTemplates = emailTemplates.filter(emailTemplate => this.availableLanguages.includes(emailTemplate.language_id));
                if (this.messageTypes?.length > 0) {
                    this.emailTemplates = this.emailTemplates
                        .filter(emailTemplate => this.messageTypes.includes(emailTemplate.message_type_id));
                }
                this.emailTemplate = this.emailTemplates.find(emailTemplate => emailTemplate.language_id === this.currentLang);

                if (!this.emailTemplate && this.messageTypes.includes(emailTemplates[0].message_type_id)) {
                    this.emailTemplate = emailTemplates[0];
                    this.currentLang = this.emailTemplate.language_id;
                }

                const messageTypeIds = [];
                emailTemplates.forEach(emailTemplate => {
                    if (this.messageTypes) {
                        if (this.messageTypes.includes(emailTemplate.message_type_id)) {
                            messageTypeIds.push({
                                id: emailTemplate.message_type_id,
                                name: (emailTemplate.message_type_id[0] + emailTemplate.message_type_id.slice(1).toLowerCase()).replace(/_/g, ' ')
                            });
                        }
                    } else {
                        messageTypeIds.push({
                            id: emailTemplate.message_type_id,
                            name: (emailTemplate.message_type_id[0] + emailTemplate.message_type_id.slice(1).toLowerCase()).replace(/_/g, ' ')
                        });
                    }
                });
                this.messageTypeIds = messageTypeIds.filter((v, i) => messageTypeIds.findIndex(item => item.id === v.id) === i);

                this.headerImageType = emailTemplateGroup.banner_url ? EmailImageTypeEnum.BANNER : EmailImageTypeEnum.LOGO;

                this.getAndRenderEmailTemplate(this.emailTemplateGroup.uuid, this.emailTemplate.message_type_id);
            }),
            map(([emailTemplateGroup, emailTemplates]) => {
                return {
                    emailTemplateGroup,
                    emailTemplates
                };
            })
        );
    }

    public getAndRenderEmailTemplate(emailTemplateGroupUuid: string, messageTypeId: string): void {
        this.editingEmailTemplates = this.emailTemplates.filter(emailTemplate => emailTemplate.message_type_id === messageTypeId);
        let emailTemplateUuid = this.editingEmailTemplates.find(emailTemplate => emailTemplate.language_id === this.currentLang)?.uuid;
        this.emailTemplate = this.editingEmailTemplates.find(emailTemplate => emailTemplate.language_id === this.currentLang);

        if (!emailTemplateUuid) {
            emailTemplateUuid = this.editingEmailTemplates[0].uuid;
        }

        this.emailTemplateMessageTexts = {};
        this.emailTemplateSubjectTexts = {};
        this.emailTemplateButtonTexts = {};
        for (const lang of this.availableLanguages) {
            this.emailTemplateMessageTexts[lang] = null;
            this.emailTemplateSubjectTexts[lang] = null;
            this.emailTemplateButtonTexts[lang] = null;
        }
        this.editingEmailTemplates.forEach(emailTemplate => {
            this.emailTemplateMessageTexts[emailTemplate.language_id] = null;
            this.emailTemplateMessageTexts[emailTemplate.language_id] = emailTemplate.message_text ?? null;

            this.emailTemplateSubjectTexts[emailTemplate.language_id] = null;
            this.emailTemplateSubjectTexts[emailTemplate.language_id] = emailTemplate.subject ?? null;

            this.emailTemplateButtonTexts[emailTemplate.language_id] = null;
            this.emailTemplateButtonTexts[emailTemplate.language_id] = emailTemplate.button_text ?? null;
        });

        this.orderDistributionService.getEmailTemplateExample(
            emailTemplateGroupUuid,
            emailTemplateUuid,
            this.params,
            this.timezone
        ).subscribe((response) => {
            this.renderTemplate(response);
            this.cmfilter.nativeElement.open();

            this.showUnstyleableMessageTypeBanner = this.unstyleableMessageTypes.includes(messageTypeId);
        });
    }

    ngOnDestroy(): void {
        this.cmfilter.nativeElement.close();
    }

    toggleFilter(): void {
        if (this.cmfilter.nativeElement.className !== '') {
            this.cmfilter.nativeElement.close();
        } else {
            this.cmfilter.nativeElement.open();
        }
    }

    renderTemplate(html): void {
        if (this.templateElement) {
            this.templateElement.remove();
        }
        this.templateElement = document.createElement('div');
        this.templateElement.innerHTML = html;

        const styleElement = document.createElement('style');
        styleElement.appendChild(
            document.createTextNode(`
            .banner {
                width: 640px;
                height: 200px;
            }

            .logo {
                width: 75px;
            }`)
        );

        this.templateElement.prepend(styleElement);

        const templateEditor = document.getElementById('email-template-editor');
        if (templateEditor) {
            templateEditor.append(this.templateElement);
        }
        this.getEditElements();
    }

    resetHeaderImage(): void {
        this.emailTemplateGroup.logo_url = this.defaultLogoUrl;
        this.emailTemplateGroup.banner_url = null;
        this.headerImageElement.style.setProperty('display', 'none');
        this.headerImageElement.setAttribute('src', this.emailTemplateGroup.logo_url);
        this.checkImageExists(false, EmailImagePositionEnum.HEADER);
    }

    resetFooterBannerImage(): void {
        this.emailTemplateGroup.footer_banner_url = null;
        this.footerBannerImageElement.setAttribute('src', this.emailTemplateGroup.logo_url);
        this.footerBannerImageElement.parentElement.parentElement.style.setProperty('display', 'none');
    }

    uploadImage(event, destination: EmailImagePositionEnum): void {
        if (event instanceof CustomEvent) {
            const file = event.detail[0];
            const isHeaderImage = destination === EmailImagePositionEnum.HEADER;

            if (!file) {
                if (isHeaderImage) {
                    this.resetHeaderImage();
                    return;
                }

                this.resetFooterBannerImage();
                return;
            }

            const img = new Image();
            const reader = new FileReader();

            reader.onload = (e: ProgressEvent) => {
                const content: any = (e.target as FileReader).result;
                img.src = content;
                img.onload = () => {

                    const width = img.width;
                    const height = img.height;

                    const isFooter = destination === EmailImagePositionEnum.FOOTER;
                    const isBannerSize = width % 640 === 0 && height % 200 === 0;
                    const isLogoSize = width % 200 === 0 && height % 200 === 0;
                    const imageElement = isFooter ?
                        this.footerBannerImageElement :
                        this.headerImageElement;

                    if (!isHeaderImage) {
                        imageElement.parentElement.parentElement.style.setProperty('display', 'block');
                    }

                    if (isBannerSize) {
                        this.headerImageType = EmailImageTypeEnum.BANNER;
                        imageElement.classList.add('banner');
                        imageElement.classList.remove('logo');
                        imageElement.style.setProperty('display', 'block');
                        this.saveImage(file, EmailImageTypeEnum.BANNER, destination);
                        this.checkImageExists(true, destination);
                    } else if (isLogoSize) {
                        this.headerImageType = EmailImageTypeEnum.LOGO;
                        imageElement.classList.add('logo');
                        imageElement.classList.remove('banner');
                        imageElement.style.setProperty('display', 'block');
                        this.saveImage(file, EmailImageTypeEnum.LOGO, destination);
                        this.checkImageExists(true, destination);
                    } else {
                        (window as any).AddErrorNotification('Image is invalid');
                    }
                };
            };
            reader.readAsDataURL(file);
        }
    }

    saveImage(file: File, imageType: EmailImageTypeEnum, destination: EmailImagePositionEnum): void {
        this.resourceService.uploadImage(file).subscribe((result) => {
            this.headerImageType = imageType;

            const imageElement = destination === EmailImagePositionEnum.HEADER ?
                this.headerImageElement :
                this.footerBannerImageElement;
            imageElement.setAttribute('src', result.link);

            if (imageType === EmailImageTypeEnum.BANNER) {
                if (destination === EmailImagePositionEnum.HEADER) {
                    this.emailTemplateGroup.banner_url = result.link;
                } else {
                    this.emailTemplateGroup.footer_banner_url = result.link;
                }

                imageElement.classList.add('banner');
                imageElement.classList.remove('logo');
            }

            if (imageType === EmailImageTypeEnum.LOGO) {
                if (destination === EmailImagePositionEnum.HEADER) {
                    this.emailTemplateGroup.logo_url = result.link;
                } else {
                    this.emailTemplateGroup.footer_banner_url = result.link;
                }

                imageElement.classList.add('logo');
                imageElement.classList.remove('banner');
            }
        });
    }

    checkImageExists(uploading: boolean, destination: EmailImagePositionEnum = EmailImagePositionEnum.HEADER): void {
        const imageElement = destination === EmailImagePositionEnum.HEADER ?
            this.headerImageElement :
            this.footerBannerImageElement;

        if (imageElement.src === this.defaultLogoUrl && uploading === false) {
            imageElement.parentElement.style.setProperty('display', 'none');
        } else if (imageElement.src === this.defaultLogoUrl && uploading === true) {
            imageElement.parentElement.style.setProperty('display', 'block');
            if (imageElement.className === 'logo') {
                imageElement.parentElement.style.setProperty('padding', '20px');
            } else {
                imageElement.parentElement.style.setProperty('padding', '0px');
            }
        }
    }

    getEditElements(): void {
        if ((document.getElementById('odb-logo-url'))) {
            this.headerImageElement = (document.getElementById('odb-logo-url') as HTMLImageElement);
            this.checkImageExists(false, EmailImagePositionEnum.HEADER);
            this.headerImageType = EmailImageTypeEnum.LOGO;
        }

        if ((document.getElementById('odb-banner-url'))) {
            this.headerImageElement = (document.getElementById('odb-banner-url') as HTMLImageElement);
            this.checkImageExists(false, EmailImagePositionEnum.HEADER);
            this.headerImageType = EmailImageTypeEnum.BANNER;
        }

        if ((document.getElementById('odb-footer-banner-url'))) {
            this.footerBannerImageElement = (document.getElementById('odb-footer-banner-url') as HTMLImageElement);
            this.footerBannerImageElement.classList.add('banner');
            this.checkImageExists(false, EmailImagePositionEnum.FOOTER);
        }

        this.headerImageElement.removeAttribute('width');
        this.headerImageElement.removeAttribute('height');

        if (this.headerImageType === EmailImageTypeEnum.BANNER) {
            this.headerImageElement.classList.add('banner');
        }

        if (this.headerImageType === EmailImageTypeEnum.LOGO) {
            this.headerImageElement.classList.add('logo');
        }

        this.backgroundElements = document.getElementsByClassName('odb-background-color');
        this.buttonBackgroundElements = document.getElementsByClassName('odb-background-highlight-color');
        this.buttonBorderElements = document.getElementsByClassName('odb-background-highlight-border-bottom-color');
        this.buttonTextElements = document.getElementsByClassName('odb-background-highlight-text-color');
        this.buttonTextElement = document
            .querySelector('td.odb-background-highlight-color a.odb-background-highlight-color.odb-background-highlight-text-color span.odb-background-highlight-text-color');
        this.messageElement = (document.getElementById('odb-message-text') as HTMLParagraphElement);

        this.calendarElement = document.getElementById('odb-calendar-buttons');
        this.messageText = '';
        this.messageText = this.emailTemplate.message_text;
        this.renderText(this.messageText);
        this.setClickEvent();
        this.setColours();
        this.checkButton();
        this.checkCalendar();
        this.cmfilter.nativeElement.open();
    }

    setClickEvent(): void {
        const style: HTMLStyleElement = document.createElement('style');
        style.appendChild(
            document.createTextNode(`
                #odb-message-text {
                    transition: box-shadow .12s;
                }

                #odb-message-text p {
                    color: rgb(22, 29, 41);
                }

                #odb-message-text:hover {
                    border: 1px solid rgba(60, 72, 89, 0.2);
                    box-shadow: 0 24px 24px -16px rgba(16, 30, 30, .5);
                    border-radius: 16px;
                    padding: 8px;
                    margin-left: -9px;
                    margin-top: calc(1em - 9px);
                    margin-bottom: -9px
                }`
            )
        );
        this.messageElement.appendChild(style);
        this.messageElement.style.cursor = 'pointer';
        this.messageElement.addEventListener('click', (() => {
            (this.messageTextTranslationDialog as TranslationDialogComponent).openDialog();
        }));
    }

    openSubjectEditor(): void {
        (this.subjectTextTranslationDialog as TranslationDialogComponent).openDialog();
    }

    openButtonTextEditor(): void {
        (this.buttonTextTranslationDialog as TranslationDialogComponent).openDialog();
    }

    backgroundColourChanged(event): void {
        if (event) {
            for (const element of this.backgroundElements) {
                element.style.background = event.detail.hex;
                this.currentBackgroundColour = event.detail.hex;
                this.emailTemplateGroup.background_color = event.detail.hex;
            }
            this.setColours();
        }
    }

    backgroundHighlightColourChanged(event): void {
        if (event) {
            for (const element of this.buttonBackgroundElements) {
                element.style.background = event.detail.hex;
                this.currentBackgroundHighlightColour = event.detail.hex;
                this.emailTemplateGroup.background_highlight_color = event.detail.hex;
            }
            for (const element of this.buttonBorderElements) {
                element.style.borderBottomColor = event.detail.hex;
            }
            this.setColours();
        }
    }

    backgroundHighlightTextColourChanged(event): void {
        if (event) {
            for (const element of this.buttonTextElements) {
                element.style.color = event.detail.hex;
                this.currentBackgroundHighlightTextColour = event.detail.hex;
                this.emailTemplateGroup.background_highlight_text_color = event.detail.hex;
            }
            this.setColours();
        }
    }

    setColours(): void {
        // Set background colour
        if (this.currentBackgroundColour) {
            for (const element of this.backgroundElements) {
                element.style.background = this.currentBackgroundColour;
            }
        }
        // Set button background colour
        if (this.currentBackgroundHighlightColour) {
            // Set button background colour
            for (const element of this.buttonBackgroundElements) {
                element.style.background = this.currentBackgroundHighlightColour;
            }
        }
        // Set button text colour
        if (this.currentBackgroundHighlightTextColour) {
            for (const element of this.buttonTextElements) {
                element.style.color = this.currentBackgroundHighlightTextColour;
            }
        }
    }

    checkButton(): void {
        if (this.isDownloadable === false &&
            this.emailTemplate.message_type_id !== 'GUEST_LIST' &&
            this.emailTemplate.message_type_id !== 'PERSONALIZE') {
            //
            if ((document.getElementsByClassName('odb-background-highlight-color')[0])) {
                (document.getElementsByClassName('odb-background-highlight-color')[0] as HTMLTableCellElement).style.display = 'none';
            }

            if ((document.getElementsByClassName('odb-background-highlight-color')[1])) {
                (document.getElementsByClassName('odb-background-highlight-color')[1] as HTMLTableCellElement).style.display = 'none';
            }
        } else {
            if ((document.getElementsByClassName('odb-background-highlight-color')[0])) {
                (document.getElementsByClassName('odb-background-highlight-color')[0] as HTMLTableCellElement).style.display = 'block';
            }

            if ((document.getElementsByClassName('odb-background-highlight-color')[1])) {
                (document.getElementsByClassName('odb-background-highlight-color')[1] as HTMLTableCellElement).style.display = 'block';
            }
        }
    }

    checkCalendar(): void {
        if (this.calendarElement) {
            if (this.emailTemplateGroup && this.emailTemplateGroup.is_showing_calendar_items === false) {
                this.calendarElement.style.display = 'none';
            } else {
                this.calendarElement.style.display = '';
            }
        }
    }

    changeSenderName(event): void {
        if (event) {
            this.emailTemplate.sender_name = event.target.value;
        }
    }

    renderText(event): void {
        if (event) {
            this.emailTemplate.message_text = event;

            while (true) {
                if (this.messageElement?.nextElementSibling?.tagName !== 'TABLE') {
                    if (this.messageElement.nextElementSibling) {
                        this.messageElement.nextElementSibling.remove();
                    } else {
                        break;
                    }
                } else {
                    break;
                }
            }

            if (this.messageElement) {
                this.messageElement.innerHTML = event;
            }
        }
    }

    removeEmojis(value: string): string {
        const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        return value.replace(regex, '');
    }

    switchCalendar(event): void {
        if (event) {
            this.emailTemplateGroup.is_showing_calendar_items = !this.emailTemplateGroup.is_showing_calendar_items;
            this.checkCalendar();
        }
    }

    onEmailSaveClick(): void {
        if (this.emailTemplate) {
            this.orderDistributionService.editEmailTemplateGroup(this.emailTemplateGroup).subscribe(() => {
                for (const emailTemplate of this.editingEmailTemplates) {
                    emailTemplate.sender_email_address = this.emailTemplate.sender_email_address;
                    emailTemplate.sender_name = this.emailTemplate.sender_name;
                    emailTemplate.message_text = this.removeEmojis(emailTemplate.message_text);
                    emailTemplate.subject = this.removeEmojis(emailTemplate.subject);
                    this.orderDistributionService.editEmailTemplate(this.emailTemplateGroup, emailTemplate).subscribe();
                }

                (window as any).AddSuccessNotification('Saving successful');
            });
        }
    }

    setMessageTextsForCurrentMessageType(translations: any): void {
        this.editingEmailTemplates.forEach(emailTemplate => {
            emailTemplate.message_text = translations[emailTemplate.language_id];
        });
        this.messageElement.innerHTML = this.emailTemplate.message_text;

        Array.from(this.messageElement.children).forEach(childElement => {
            this.renderer.setStyle(
                childElement,
                'color',
                'rgb(22, 29, 41)'
            );
        });
    }

    setSubjectTextsForCurrentMessageType(translations: any): void {
        this.editingEmailTemplates.forEach(emailTemplate => {
            emailTemplate.subject = translations[emailTemplate.language_id];
        });
    }

    setButtonTextForCurrentMessageType(translations: any): void {
        this.editingEmailTemplates.forEach(emailTemplate => {
            emailTemplate.button_text = translations[emailTemplate.language_id];
        });
        this.buttonTextElement.innerHTML = this.emailTemplate.button_text;
    }
}
