import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';

@Component({
    selector: 'app-form-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    standalone: false
})
export class SelectComponent implements OnInit, OnDestroy, FormField {
    @Input() formId;
    @Input() id;
    @Input() label;
    @Input() hint;
    @Input() value;
    @Input() required;
    @Input() disabled;
    @Input() readonly;
    @Input() autocomplete;
    @Input() placeholder;
    @Input() match;
    @Input() errorMessage;
    @Input() options: any[] = [];
    @Input() optionLabelTranslate = false;
    @Input() optionLabel = 'label';
    @Input() optionValue = 'value';
    @Input() optionDescription = null;
    @Input() optionDisabled = 'isDisabled';
    @Input() optionAddNull = false;
    @Input() isValid = true;
    @Input() isMultiselect = false;
    @Input() isNative = false;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private formManagementService: FormManagementService) {

    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);
    }

    inputChange() {
        this.valueChange.emit(this.value);
        this.validate();
    }

    validate() {
        this.isValid = true;
        this.errorMessage = '';

        if (this.required && !this.value) {
            this.errorMessage = 'General.Required';
            this.isValid = false;
        }

        return this.isValid;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }

    updateArray(item: string) {
        if (!Array.isArray(this.value)) {
            this.value = [];
        }

        const valueIndex = this.value.findIndex(existingValue => existingValue === item);

        if (valueIndex >= 0) {
            this.value.splice(valueIndex, 1);
        } else {
            this.value.push(item);
        }

        this.inputChange();
    }

    setOptions(options: any[]): void {
        this.options = options;
    }
}
