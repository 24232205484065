import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'cm-ticketing-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.css'],
    standalone: false
})
export class DataTableComponent implements OnInit {
    @ViewChild('cmTable') cmTable: ElementRef;
    @Output() sortChangedEmitter: EventEmitter<any> = new EventEmitter();
    @Output() rowClickedEmitter: EventEmitter<any> = new EventEmitter();
    @Output() rowActionClickedEmitter: EventEmitter<any> = new EventEmitter();
    @Output() headerActionClickedEmitter: EventEmitter<any> = new EventEmitter();
    @Output() selectionChangedEmitter: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Input() footer;
    @Input() rows;
    @Input() headers;
    @Input() tableName = 'test-table';

    @Input() selectedRowIds: string[] = [];
    @Input() totalRows = 0;

    headerCheckboxState: 'CHECKED' | 'UNCHECKED' | 'INTERMEDIATE' = 'UNCHECKED';

    ngOnInit(): void {
        this.rows.filter(row => this.selectedRowIds.includes(row.id)).forEach(row => row.selected = true);
        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    sortChanged(event): void {
        this.sortChangedEmitter.emit({
            id: event.id,
            type: event.type
        });
    }

    rowClicked(row: any, event: any): void {
        if (event.target.nodeName === 'INPUT') {
            return;
        }

        this.rowClickedEmitter.emit(row);
    }

    rowCheckboxClicked(event: any, rowId: string): void {
        const row = this.rows.find(r => r.id === rowId);

        if (event.target.checked) {
            row.selected = true;
            this.selectedRowIds = this.pushIfUnique(this.selectedRowIds, rowId);
        } else {
            row.selected = false;
            this.selectedRowIds = this.removeFromArray(this.selectedRowIds, rowId);
        }

        this.selectionChangedEmitter.emit(this.selectedRowIds);

        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    rowActionClicked(event, rowId): void {
        this.rowActionClickedEmitter.emit({
            id: event,
            rowId
        });
    }

    headerActionClicked(event): void {
        this.headerActionClickedEmitter.emit({
            id: event.id,
            headerId: event.headerId
        });
    }

    stickColumnCalled(event): void {
        const stickyCells = this.cmTable.nativeElement.querySelectorAll('cm-table-data[id^=' + event.detail.columnId + ']');
        for (const element of stickyCells) {
            element.parentElement.style.position = 'sticky';
            element.parentElement.style.left = '0';
            element.parentElement.style.zIndex = '18';
        }
        const stickyFooter = this.cmTable.nativeElement.querySelector(`#${event.detail.columnId}_Footer`);
        if (stickyFooter) {
            stickyFooter.parentElement.style.position = 'sticky';
            stickyFooter.parentElement.style.left = '0';
            stickyFooter.parentElement.style.zIndex = '19';
        }
    }

    selectAllClicked(event: any): void {
        if (this.selectedRowIds.length) {
            this.rows.forEach(row => {
                row.selected = false;
            });
            this.selectedRowIds = [];
        } else {
            this.rows.forEach(row => {
                row.selected = true;
                this.selectedRowIds.push(row.id);
            });
        }

        this.selectionChangedEmitter.emit(this.selectedRowIds);
        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    private getHeaderCheckboxState(): 'CHECKED' | 'UNCHECKED' | 'INTERMEDIATE' {
        if (!this.selectedRowIds.length) {
            return 'UNCHECKED';
        }

        if (this.selectedRowIds.length && this.selectedRowIds.length < this.totalRows) {
            return 'INTERMEDIATE';
        }

        if (this.selectedRowIds.length === this.totalRows) {
            return 'CHECKED';
        }
    }

    private pushIfUnique(array, value): any[] {
        const i = array.findIndex(a => a === value);

        if (i === -1) {
            array.push(value);
            return array;
        }
    }

    private removeFromArray(array, value): any[] {
        const i = array.findIndex(a => a === value);

        if (i !== -1) {
            array.splice(i, 1);
            return array;
        }
    }

}
