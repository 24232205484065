import {NotificationHandler} from './notification-handler';
import {Notification} from '../../models/notification.class';
import {Injector} from '@angular/core';
import {TranslationPipe} from '../../../core/pipes/translation.pipe';
import {NotificationTopic} from '../../models/notification-topic.enum';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

export class ReportingNotificationHandler implements NotificationHandler {

    private static instance: ReportingNotificationHandler;

    private translationPipe: TranslationPipe;
    private router: Router;

    private notificationStream: BehaviorSubject<Notification[]>;

    private constructor(private injector: Injector, notificationStream: BehaviorSubject<Notification[]>) {
        this.translationPipe = this.injector.get(TranslationPipe);
        this.router = this.injector.get(Router);
        this.notificationStream = notificationStream;
        window.addEventListener('cm-notification-action', this.handleNotificationButton.bind(this));
    }

    public static getInstance(injector: Injector, notificationStream: BehaviorSubject<Notification[]>): ReportingNotificationHandler {
        if (!ReportingNotificationHandler.instance) {
            ReportingNotificationHandler.instance = new ReportingNotificationHandler(injector, notificationStream);
        }

        return ReportingNotificationHandler.instance;
    }

    handle(notification: Notification): void {
        this.notificationStream.next([notification]);

        switch (notification.topic) {
            case NotificationTopic.REPORT_FINISHED:
                // @todo: Uncomment when reporting SSE notifications are safely delivered to the relevant users
                // (window as any).AddNotification(
                //     this.translationPipe.transform('Notification.REPORT_FINISHED.Title'),
                //     this.translationPipe.transform('Notification.REPORT_FINISHED.Content'), {
                //         iconType: 'icon',
                //         iconName: 'verification',
                //         iconClass: 'success',
                //         duration: 10000,
                //         dismissible: true,
                //         primaryButtonTitle: 'Close',
                //         primaryButtonAction: 'closeNotification',
                //         secondaryButtonTitle: 'Download file',
                //         secondaryButtonAction: this.reportFinishedDownloadReport(notification.content.link, notification.content.fileName)
                //     });
                break;

            case NotificationTopic.REPORT_FAILED:
                (window as any).AddNotification(
                    this.translationPipe.transform(('Report_Export_List.Failed_Reason.' + notification.content.reason)),
                    this.translationPipe.transform(('Report_Export_List.Failed_Reason.' + notification.content.reason + '.Hint')),
                    {
                        iconType: 'icon',
                        iconName: 'error',
                        iconClass: 'error',
                        duration: 10000,
                        dismissible: true,
                        primaryButtonTitle: 'Close',
                        primaryButtonAction: 'closeNotification',
                        secondaryButtonTitle: this.translationPipe.transform('Report_Page.Tabs.Exports'),
                        secondaryButtonAction: this.navigateToReportExportList()
                    });
                break;
        }
    }

    private reportFinishedDownloadReport(link: string, fileName: string): any {
        return (): void => {
            this.download(link, fileName);
        };
    }

    private navigateToReportExportList(): any {
        return (): void => {
            this.router.navigate(['/reports', 'exports']);
        };
    }

    private download(url: string, fileName: string) {
        const a: HTMLAnchorElement = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    private handleNotificationButton(e: any): void {
        if (typeof e.detail.detail !== 'string') {
            e.detail.actionResponse();
            return;
        }

        switch (e.detail.actionResponse) {
            case 'closeNotification':
                (window as any).CloseCmNotification();
        }
    }
}

