import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrderDetailsPayment} from '../../models/order-details/order-details-payment.class';
import {Payment} from '../../models/payment.class';
import {PaymentService} from '../../services/payment-service/payment.service';
import {PaymentStatus} from '../../models/payment-status.enum';

@Component({
    selector: 'app-order-payment-table',
    templateUrl: './order-payment-table.component.html',
    styleUrls: ['./order-payment-table.component.scss'],
    standalone: false
})
export class OrderPaymentTableComponent {
    @Input() orderDetailsPayments: OrderDetailsPayment[];
    @Output() paymentStatusUpdated: EventEmitter<any> = new EventEmitter();

    private refreshPaymentStatuses = [PaymentStatus.OPEN, PaymentStatus.EXPIRED];

    constructor(private paymentService: PaymentService) {
    }

    isRefreshableStatus(paymentStatus: PaymentStatus): boolean {
        return this.refreshPaymentStatuses.includes(paymentStatus);
    }

    updatePaymentStatus(payment: Payment) {
        if (payment.status !== PaymentStatus.OPEN && payment.status !== PaymentStatus.EXPIRED) {
            return;
        }

        this.paymentService.checkPaymentStatus(payment.id).subscribe(_ => {
            this.paymentStatusUpdated.emit();
        });
    }
}
