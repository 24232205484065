import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';
import moment from 'moment';

@Component({
    selector: 'app-form-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    standalone: false
})
export class DatepickerComponent implements OnInit, OnDestroy, FormField {
    @Input() formId;
    @Input() id;
    @Input() label;
    @Input() hint;
    @Input() value;
    @Input() required;
    @Input() autocomplete;
    @Input() locale;
    @Input() inputFormat                     = 'LL';
    @Input() outputFormat                    = 'LL';
    @Input() displayFormat                   = 'LLL';
    @Input() precision                       = 'YMD';
    @Input() minDate;
    @Input() maxDate                         = moment().add(120, 'years').toISOString();
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
    isValid                                  = true;
    errorMessage: string;

    constructor(private formManagementService: FormManagementService) {

    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);

        this.value = moment(this.value, this.inputFormat).toISOString(true);

        setTimeout(() => {
            document.querySelectorAll('cm-datetime-picker .form-input').forEach(el => {
                el.setAttribute('style', 'padding: 0;');
            });

            document.querySelectorAll('cm-datetime-picker .form-row').forEach(el => {
                el.setAttribute('style', 'margin: 0;');
            });
        });
    }

    inputChange(event) {
        if (event.detail) {
            this.value          = event.detail;
            this.value.datetime = moment(event.detail.datetime, this.outputFormat).toISOString(true);
            this.valueChange.emit(this.value.datetime);
            this.validate();
        }
    }

    validate() {
        this.isValid      = true;
        this.errorMessage = '';

        if (this.value && this.value.error) {
            this.isValid      = false;
            this.errorMessage = this.value.error.message;
        }

        if (this.required && !this.value) {
            this.isValid      = false;
            this.errorMessage = 'General.Required';
        }

        if (this.minDate && this.value && this.value.datetime) {
            if (!moment(this.value.datetime).isSameOrAfter(moment(this.minDate))) {
                this.isValid      = false;
                this.errorMessage = 'Error.Date_Should_Be_In_The_Future';
            }
        }

        if (this.maxDate && this.value && this.value.datetime) {
            if (!moment(this.value.datetime).isSameOrBefore(moment(this.maxDate))) {
                this.isValid      = false;
                this.errorMessage = 'Error.Date_Not_Allowed';
            }
        }

        return this.isValid;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }

}
