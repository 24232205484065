import {Component, Input, OnInit} from '@angular/core';
import {AnalyticsDashboard} from '../../../models/analytics-dashboard.class';
import {AnalyticsDashboardItem} from '../../../models/analytics-dashboard-item.class';
import {AnalyticsDashboardItemSetting} from '../../../models/analytics-dashboard-item-setting.class';
import {
    AnalyticsDashboardEditorService
} from '../../../services/analytics-dashboard-editor-service/analytics-dashboard-editor.service';
import {SubscriptionType} from '../../../models/subscription-type.class';
import {EventService} from '../../../services/event-service/event.service';
import {SubscriptionTypeService} from '../../../services/subscription-type-service/subscription-type.service';
import { HttpParams } from '@angular/common/http';
import {Event} from '../../../models/event.class';
import {NotificationService} from '../../../services/notification-service/notification.service';
import {
    AnalyticsDashboardItemSettingService
} from '../../../services/analytics-dashboard-item-setting-service/analytics-dashboard-item-setting.service';
import {
    AnalyticsDashboardItemService
} from '../../../services/analytics-dashboard-item-service/analytics-dashboard-item.service';

@Component({
    selector: 'app-analytics-dashboard-item-editor',
    templateUrl: './analytics-dashboard-item-editor.component.html',
    styleUrls: ['./analytics-dashboard-item-editor.component.scss'],
    standalone: false
})
export class AnalyticsDashboardItemEditorComponent implements OnInit {
    @Input() analyticsDashboard: AnalyticsDashboard;
    editAnalyticsDashboardItem: AnalyticsDashboardItem;
    changedAnalyticsDashboardItemSettings: AnalyticsDashboardItemSetting[] = [];

    events: Event[] = [];
    subscriptionTypes: SubscriptionType[] = [];

    constructor(private analyticsDashboardEditorService: AnalyticsDashboardEditorService,
                private analyticsDashboardItemService: AnalyticsDashboardItemService,
                private analyticsDashboardItemSettingService: AnalyticsDashboardItemSettingService,
                private notificationService: NotificationService,
                private eventService: EventService,
                private subscriptionTypeService: SubscriptionTypeService) {
    }

    ngOnInit(): void {
        this.eventService.getEvents(new HttpParams().set('take', -1).set('depth', '1')).subscribe(response => {
            this.events = response.body;
        });

        this.subscriptionTypeService.getSubscriptionTypes(new HttpParams().set('take', -1).set('depth', '1')).subscribe(subscriptionTypes => {
            this.subscriptionTypes = subscriptionTypes;
        });

        this.analyticsDashboardEditorService.editAnalyticsDashboardItemSubject.asObservable().subscribe(editAnalyticsDashboardItem => {
            this.editAnalyticsDashboardItem = editAnalyticsDashboardItem;

            if (this.editAnalyticsDashboardItem) {
                this.setupPageLayout();
            } else {
                this.resetPageLayout();
            }
        });
    }

    setupPageLayout() {
        // (document.querySelector('cm-sidenav') as any).closeSidenav();
        // (document.querySelector('body') as any).style.overflow = 'hidden';
        (document.querySelector('body') as any).classList.add('no-cm-chat');
    }

    resetPageLayout() {
        // (document.querySelector('cm-sidenav') as any).openSidenav();
        // (document.querySelector('body') as any).style.overflow = 'unset';
        (document.querySelector('body') as any).classList.remove('no-cm-chat');
    }

    update(analyticsDashboardItemSetting?: AnalyticsDashboardItemSetting) {
        if (analyticsDashboardItemSetting) {
            const index = this.changedAnalyticsDashboardItemSettings.findIndex(object => {
                return object === analyticsDashboardItemSetting;
            }, 0);
            if (index < 0) {
                this.changedAnalyticsDashboardItemSettings.push(analyticsDashboardItemSetting);
            }
        }

        // this.editAnalyticsDashboardItem = Object.assign({}, this.editAnalyticsDashboardItem);
        this.editAnalyticsDashboardItem.updatedAt = Date.now().toString();
        // this.analyticsDashboardEditorService.editAnalyticsDashboardItemSubject.next(this.editAnalyticsDashboardItem);
    }

    add() {
        const analyticsDashboardItemSetting = new AnalyticsDashboardItemSetting();
        // analyticsDashboardItemSetting.order              = this.subscriptionTypeSaleSettings.length + 1;
        analyticsDashboardItemSetting.analyticsDashboardItemId = this.editAnalyticsDashboardItem.id;

        this.editAnalyticsDashboardItem.analyticsDashboardItemSettings.push(analyticsDashboardItemSetting);
    }

    close() {
        this.analyticsDashboardEditorService.setEditAnalyticsDashboardItem(null);
    }

    save() {
        this.editAnalyticsDashboardItem.positionX = this.editAnalyticsDashboardItem.gridster.x;
        this.editAnalyticsDashboardItem.positionY = this.editAnalyticsDashboardItem.gridster.y;
        this.editAnalyticsDashboardItem.width = this.editAnalyticsDashboardItem.gridster.cols;
        this.editAnalyticsDashboardItem.height = this.editAnalyticsDashboardItem.gridster.rows;

        if (this.editAnalyticsDashboardItem.id) {
            this.analyticsDashboardItemService
                .patchAnalyticsDashboardItem(this.analyticsDashboard.id, this.editAnalyticsDashboardItem.id, this.editAnalyticsDashboardItem)
                .subscribe(analyticsDashboardItem => {
                    this.editAnalyticsDashboardItem.id = analyticsDashboardItem.id;
                    this.saveSettings();
                });
        } else {
            this.analyticsDashboardItemService
                .postAnalyticsDashboardItem(this.analyticsDashboard.id, this.editAnalyticsDashboardItem)
                .subscribe(analyticsDashboardItem => {
                    this.editAnalyticsDashboardItem.id = analyticsDashboardItem.id;
                    this.saveSettings();
                });
        }
    }

    saveSettings() {
        for (const analyticsDashboardItemSetting of this.changedAnalyticsDashboardItemSettings) {
            if (analyticsDashboardItemSetting.id) {
                this.analyticsDashboardItemSettingService.patchAnalyticsDashboardItemSetting(
                    this.analyticsDashboard.id,
                    this.editAnalyticsDashboardItem.id,
                    analyticsDashboardItemSetting.id,
                    analyticsDashboardItemSetting
                ).subscribe(result => {
                    analyticsDashboardItemSetting.id = result.id;
                });
            } else {
                this.analyticsDashboardItemSettingService.postAnalyticsDashboardItemSetting(
                    this.analyticsDashboard.id,
                    this.editAnalyticsDashboardItem.id,
                    analyticsDashboardItemSetting
                ).subscribe(result => {
                    analyticsDashboardItemSetting.id = result.id;
                });
            }
        }

        this.changedAnalyticsDashboardItemSettings = [];
        this.analyticsDashboardEditorService.editAnalyticsDashboardItemSubject.next(this.editAnalyticsDashboardItem);
        this.analyticsDashboardEditorService.setEditAnalyticsDashboardItem(null);
        this.notificationService.showTranslatedNotification('success', 'analytics_dashboard_item', 'saved');
    }

    onAnalyticsDashboardItemSettingDelete(analyticsDashboardItemSetting) {
        if (analyticsDashboardItemSetting.id) {
            this.analyticsDashboardItemSettingService.deleteAnalyticsDashboardItemSetting(
                this.analyticsDashboard.id,
                this.editAnalyticsDashboardItem.id,
                analyticsDashboardItemSetting.id
            ).subscribe(_ => {
                this.notificationService.showTranslatedNotification('success', 'analytics_dashboard_item_setting', 'deleted');
            });
        }

        const index = this.editAnalyticsDashboardItem.analyticsDashboardItemSettings.findIndex(a => a === analyticsDashboardItemSetting);

        if (index >= 0) {
            this.editAnalyticsDashboardItem.analyticsDashboardItemSettings.splice(index, 1);
        }
    }

}
