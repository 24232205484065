import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, fromEvent, merge, Observable, of} from 'rxjs';
import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import moment from 'moment';
import {EventService} from '../../services/event-service/event.service';
import {OrderService} from '../../services/order-service/order.service';
import {SubscriptionTypeService} from '../../services/subscription-type-service/subscription-type.service';
import {CustomerService} from '../../services/customer-service/customer.service';
import {BarcodeService} from '../../services/barcode-service/barcode-service.service';
import {translate} from '../../../core/helpers/translation-helper';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
    standalone: false
})
export class MenuComponent implements OnInit, AfterViewInit {
    modules: any = [];

    @ViewChild('search') searchViewChild;
    search$: Observable<any>;

    public moveBottomModulesUp = false;

    constructor(
        private route: ActivatedRoute,
        private eventService: EventService,
        private orderService: OrderService,
        private customerService: CustomerService,
        private barcodeService: BarcodeService,
        private subscriptionTypeService: SubscriptionTypeService
    ) {
    }

    ngOnInit() {
        this.modules = this.route.snapshot.data.init.userManagement.filter(module => module.canRead && module.showInMenu);
    }

    ngAfterViewInit() {
        const searchElement = this.searchViewChild.nativeElement;

        this.search$ = merge(
            fromEvent(searchElement, 'keyup'),
            fromEvent(searchElement, 'change')
        ).pipe(
            debounceTime(500),
            map((event: any) => {
                return event.type === 'keyup' ? event.target.value : null;
            }),
            switchMap(searchString => {
                if (searchString) {
                    return combineLatest([
                        this.generateSearchEventRequest(searchString).pipe(startWith([{isLoading: true}])),
                        this.generateSearchOrderRequest(searchString).pipe(startWith([{isLoading: true}])),
                        this.generateSearchCustomerRequest(searchString).pipe(startWith([{isLoading: true}])),
                        this.generateSearchSubscriptionTypeRequest(searchString).pipe(startWith([{isLoading: true}])),
                        this.generateSearchBarcodeRequest(searchString).pipe(startWith([{isLoading: true}])),
                    ]).pipe(map(results => {
                        return {
                            search: searchString,
                            categories: [
                                {
                                    id: 'EVENTS',
                                    translationKey: 'Menu.Events',
                                    results: results[0]
                                },
                                {
                                    id: 'SUBSCRIPTION_TYPES',
                                    translationKey: 'Menu.Subscription',
                                    results: results[3]
                                },
                                {
                                    id: 'CUSTOMERS',
                                    translationKey: 'Menu.Relations',
                                    results: results[2]
                                },
                                {
                                    id: 'ORDERS',
                                    translationKey: 'Menu.Orders',
                                    results: results[1]
                                },
                                {
                                    id: 'BARCODES',
                                    translationKey: 'Menu.Barcodes',
                                    results: results[4]
                                }
                            ]
                        };
                    }));
                }

                return of(null);
            })
        );
    }

    onSearchResultClicked() {
        this.searchViewChild.nativeElement.value = null;
    }

    onCloseClicked() {
        this.searchViewChild.nativeElement.value = null;
    }

    private generateSearchEventRequest(search: string) {
        const httpParams = new HttpParams()
            .set('event[after]', moment().toISOString())
            .set('depth', '1')
            .set('event[search]', search);

        return this.eventService.getEvents(httpParams).pipe(
            map(response => {
                return response.body;
            }),
            map(events => {
                return events.map(event => {
                    return {
                        title: event.name,
                        subtitle: `${new Date(event.startAt).toDateString()} - ${new Date(event.endAt).toDateString()}`,
                        route: `/events/${event.id}`
                    };
                });
            })
        );
    }

    private generateSearchOrderRequest(search: string) {
        const httpParams = new HttpParams()
            .set('order[customer]', 'true')
            .set('depth', '1')
            .set('order[search]', search);

        return this.orderService.getOrders(httpParams).pipe(
            map(response => {
                return response.body;
            }),
            map(events => {
                return events.map(order => {
                    return {
                        title: order.batchId,
                        subtitle: `${order.status}`,
                        route: `/orders/${order.id}`
                    };
                });
            })
        );
    }

    private generateSearchCustomerRequest(search: string) {
        const httpParams = new HttpParams()
            .set('depth', '1')
            .set('customer[search]', search);

        return this.customerService.getCustomers(httpParams).pipe(
            map(response => {
                return response.body;
            }),
            map(customers => {
                return customers.map(customer => {
                    return {
                        title: customer.getName(),
                        subtitle: customer.externalReference1 ? customer.externalReference1 : null,
                        route: `/customers/${customer.id}`
                    };
                });
            })
        );
    }

    private generateSearchSubscriptionTypeRequest(search: string) {
        const httpParams = new HttpParams()
            .set('depth', '1')
            .set('subscriptionType[search]', search);

        return this.subscriptionTypeService.getSubscriptionTypes(httpParams).pipe(
            map(subscriptionTypes => {
                return subscriptionTypes.map(subscriptionType => {
                    return {
                        title: subscriptionType.name,
                        subtitle: null,
                        route: `/subscription-types/${subscriptionType.id}`
                    };
                });
            })
        );
    }

    private generateSearchBarcodeRequest(searchString: string) {
        return this.barcodeService.find(searchString).pipe(
            map(results => {
                return results.map(result => {
                    return {
                        title: translate(`PRODUCT.` + result.product),
                        subtitle: result.barcode,
                        route: `/orders/${result.orderId}`
                    };
                });
            })
        );
    }
}
