import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ResourceService} from '../../services/resource-service/resource.service';
import {Resource} from '../../models/event-resource.class';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {DialogFilePickerComponent} from '../dialogs/dialog-file-picker/dialog-file-picker.component';

@Component({
    selector: 'app-file-picker',
    templateUrl: './file-picker.component.html',
    styleUrls: ['./file-picker.component.css'],
    standalone: false
})
export class FilePickerComponent {

    @Input() type: string;
    @Input() file: Resource;
    @Input() label: string;
    @Input() isRemoveAllowed = false;
    @Input() showFileName = false;
    @Input() disabled = false;
    files: Resource[];

    @Output() fileSelected: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() fileRemoved: EventEmitter<Resource> = new EventEmitter<Resource>();

    constructor(
        private resourceService: ResourceService,
        private dialogService: DialogService
    ) {
    }

    onFileSelected(event) {
        this.file = event.data.extraData;
        this.fileSelected.emit(this.file);
    }

    onFileSelectClicked() {
        this.dialogService.createDialogComponent(DialogFilePickerComponent, {
            extraData: {
                type: this.type
            }
        }, 'file-picker');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                this.onFileSelected(response);
            }
        });
    }

    fileEditClicked() {
        this.onFileSelectClicked();
    }

    fileRemoveClicked() {
        this.file = null;
        this.fileRemoved.emit(null);
    }

}
