import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnalyticsDashboardItemSetting} from '../../../models/analytics-dashboard-item-setting.class';
import {SubscriptionType} from '../../../models/subscription-type.class';
import {Event} from '../../../models/event.class';
import {VenueService} from '../../../services/venue-service/venue.service';
import { HttpParams } from '@angular/common/http';
import {mapVenue, Venue} from '../../../models/venue.class';

@Component({
    selector: 'app-analytics-dashboard-item-setting',
    templateUrl: './analytics-dashboard-item-setting.component.html',
    styleUrls: ['./analytics-dashboard-item-setting.component.scss'],
    standalone: false
})
export class AnalyticsDashboardItemSettingComponent implements OnInit {
    isExpanded = false;
    activeMenuItem: string;
    eventOrSubscriptionSelected = false;
    @Input() analyticsDashboardItemSetting: AnalyticsDashboardItemSetting;
    @Output() analyticsDashboardItemSettingChange = new EventEmitter<AnalyticsDashboardItemSetting>();
    @Output() change = new EventEmitter();
    @Output() deleteClick: EventEmitter<AnalyticsDashboardItemSetting> = new EventEmitter<AnalyticsDashboardItemSetting>();

    // TODO: replace with management service
    @Input() events: Event[] = [];
    @Input() subscriptionTypes: SubscriptionType[] = [];

    venue: Venue;

    settings = [
        {
            key: 'eventId',
            viewValue: 'General.Event',
            switch: true
        },
        {
            key: 'subscriptionTypeId',
            viewValue: 'General.Subscription',
            switch: true
        },
        {
            key: 'isCumulative',
            viewValue: 'Analytics.Cumulative',
            switch: true
        },
        {
            key: 'groupBy',
            viewValue: 'Analytics.Subject',
            switch: false
        },
        {
            key: 'type',
            viewValue: 'Analytics.Chart_Type',
            switch: false
        }
    ];
    chartTypesOptions = [
        {
            label: 'Analytics.Chart_Line',
            value: 'LINE'
        },
        {
            label: 'Analytics.Chart_Line_Smooth',
            value: 'LINE_SMOOTH'
        },
        {
            label: 'Analytics.Chart_Line_Area',
            value: 'LINE_AREA'
        },
        {
            label: 'Analytics.Chart_Line_Area_Smooth',
            value: 'LINE_AREA_SMOOTH'
        },
        {
            label: 'Analytics.Chart_Bar_Vertical',
            value: 'BAR_VERTICAL'
        },
        {
            label: 'Analytics.Chart_Pie',
            value: 'PIE'
        }
    ];
    groupByOptions = [
        {
            label: 'Analytics.Group_By_TOTAL',
            value: 'TOTAL'
        },
        {
            label: 'Analytics.Group_By_PARENT_VENUE_SECTION',
            value: 'PARENT_VENUE_SECTION'
        },
        {
            label: 'Analytics.Group_By_VENUE_SECTION',
            value: 'VENUE_SECTION'
        },
        {
            label: 'Analytics.Group_By_TICKET_TYPE',
            value: 'TICKET_TYPE'
        },
        {
            label: 'Analytics.Group_By_SUBSCRIPTION_TYPE_PRICE',
            value: 'SUBSCRIPTION_TYPE_PRICE'
        },
        {
            label: 'Analytics.Group_By_PAYMENT_METHOD',
            value: 'PAYMENT_METHOD'
        },
        {
            label: 'Analytics.Group_By_CUSTOMER_GENDER',
            value: 'CUSTOMER_GENDER'
        },
        {
            label: 'Analytics.Group_By_CUSTOMER_AGE',
            value: 'CUSTOMER_AGE'
        },
        {
            label: 'Analytics.Group_By_CUSTOMER_COUNTRY',
            value: 'CUSTOMER_COUNTRY'
        },
        {
            label: 'Analytics.Group_By_CUSTOMER_CITY',
            value: 'CUSTOMER_CITY'
        }
    ];
    dataOptions = [
        {
            label: 'General.Event',
            value: 'EVENT'
        },
        {
            label: 'General.Subscription',
            value: 'SUBSCRIPTION'
        }
    ];

    constructor(
        private venueService: VenueService
    ) {
    }

    ngOnInit(): void {
        let venueId = null;
        if (this.analyticsDashboardItemSetting.eventId) {
            const event: Event = this.getEventById(this.analyticsDashboardItemSetting.eventId);
            venueId = event?.venueId;
            this.eventOrSubscriptionSelected = true;
        }

        if (this.analyticsDashboardItemSetting.subscriptionTypeId) {
            const subscriptionType: SubscriptionType = this.getSubscriptionTypeById(this.analyticsDashboardItemSetting.subscriptionTypeId);
            venueId = subscriptionType?.venueId;
            this.eventOrSubscriptionSelected = true;
        }

        if (venueId) {
            this.venueService.getVenue(venueId, new HttpParams().set('depth', '3')).subscribe(venue => {
                this.venue = mapVenue(venue);
            });
        }
    }

    switchMenu(setting) {
        this.activeMenuItem = setting.key;
    }

    isSettingEnabled(setting) {
        return (setting.key === 'eventId' && this.analyticsDashboardItemSetting.eventId ||
            setting.key === 'subscriptionTypeId' && this.analyticsDashboardItemSetting.subscriptionTypeId ||
            setting.key === 'isCumulative' && this.analyticsDashboardItemSetting.isCumulative ||
            setting.key === 'venueSectionIds' && this.analyticsDashboardItemSetting.venueSectionIds.length > 0 ||
            setting.key === 'customerTagIds' && this.analyticsDashboardItemSetting.customerTagIds.length > 0
        );
    }

    isSettingDisabled(setting) {
        return (setting.key === 'eventId' && this.analyticsDashboardItemSetting.subscriptionTypeId ||
            setting.key === 'subscriptionTypeId' && this.analyticsDashboardItemSetting.eventId ||
            setting.key === 'venueSectionIds' && !this.analyticsDashboardItemSetting.eventId && !this.analyticsDashboardItemSetting.subscriptionTypeId ||
            !this.eventOrSubscriptionSelected && (setting.key === 'groupBy' || setting.key === 'type' || setting.key === 'isCumulative')
        );
    }

    triggerChange(setting?) {
        if (setting?.key === 'eventId') {
            this.eventOrSubscriptionSelected = true;
            const event: Event = this.getEventById(this.analyticsDashboardItemSetting.eventId);
            if (event) {
                this.venueService.getVenue(event.venueId, new HttpParams().set('depth', '2')).subscribe(venue => {
                    this.venue = mapVenue(venue);
                });
            }
        } else if (setting?.key === 'subscriptionTypeId') {
            this.eventOrSubscriptionSelected = true;
            const subscriptionType: SubscriptionType = this.getSubscriptionTypeById(this.analyticsDashboardItemSetting.subscriptionTypeId);
            if (subscriptionType) {
                this.venueService.getVenue(subscriptionType.venueId, new HttpParams().set('depth', '2')).subscribe(venue => {
                    this.venue = mapVenue(venue);
                });
            }
        }

        this.change.emit();
    }

    toggleSetting(setting) {
        if (this.activeMenuItem === setting.key && (
            setting.key === 'eventId' ||
            setting.key === 'subscriptionTypeId' ||
            setting.key === 'isCumulative'
        )) {
            this.analyticsDashboardItemSetting[setting.key] = null;
        } else {
            this.analyticsDashboardItemSetting[setting.key] = [];
        }

        if ((setting.key === 'eventId' || setting.key === 'subscriptionTypeId')) {
            this.eventOrSubscriptionSelected = false;
        }
    }

    delete() {
        this.deleteClick.emit(this.analyticsDashboardItemSetting);
    }

    getEventName(eventId: string) {
        return this.events.find(event => event.id === eventId)?.name;
    }

    getEventById(eventId: string) {
        return this.events.find(event => event.id === eventId);
    }

    getSubscriptionTypeName(subscriptionTypeId: string) {
        return this.subscriptionTypes.find(subscriptionType => subscriptionType.id === subscriptionTypeId)?.name;
    }

    getSubscriptionTypeById(subscriptionTypeId: string) {
        return this.subscriptionTypes.find(subscriptionType => subscriptionType.id === subscriptionTypeId);
    }

    updateSelection(field: string, object: any) {
        const analyticsDashboardItemSettingField = this.analyticsDashboardItemSetting[field];
        const index = analyticsDashboardItemSettingField.findIndex(id => {
            return id === object.id;
        }, 0);
        if (index >= 0) {
            analyticsDashboardItemSettingField.splice(index, 1);
        } else {
            analyticsDashboardItemSettingField.push(object.id);
        }

        this.triggerChange();
    }

    inSelection(field: string, object: any) {
        return this.analyticsDashboardItemSetting[field].findIndex(id => {
            return id === object.id;
        }) >= 0;
    }

    updateArraySelection(field: string, array) {
        for (const object of array) {
            this.updateSelection(field, object);
        }
    }
}
