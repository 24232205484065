import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Consent} from '../../models/consent.class';
import {Resource} from '../../models/event-resource.class';
import {Event} from '../../models/event.class';
import {SubscriptionType} from '../../models/subscription-type.class';
import {DialogConfirmationComponent} from '../dialogs/dialog-confirmation/dialog-confirmation.component';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {Translation} from '../../models/translation';
import {NotificationService} from '../../services/notification-service/notification.service';

@Component({
    selector: 'app-consent-detail',
    templateUrl: './consent-detail.component.html',
    styleUrls: ['./consent-detail.component.scss'],
    standalone: false
})
export class ConsentDetailComponent implements OnInit {

    @Input() consent: Consent;
    @Input() subscriptionTypes: SubscriptionType[];
    @Input() events: Event[];

    @Output() consentSave: EventEmitter<Consent> = new EventEmitter<Consent>();
    @Output() consentDelete: EventEmitter<Consent> = new EventEmitter<Consent>();
    @Output() consentSettingDelete: EventEmitter<Consent> = new EventEmitter<Consent>();

    filterSubscriptionTypeIds: string[] = [];
    filterEventIds: string[] = [];

    isExpanded = false;

    activeMenuItem = 'SETTINGS';
    menuItems = [
        {
            value: 'SETTINGS',
            label: 'Consent_Detail.Settings'
        },
        {
            value: 'CONDITIONS',
            label: 'Consent_Detail.Conditions'
        }
    ];

    resourceType = 'WEBSITE';
    resourceTypes = [
        {
            value: 'WEBSITE',
            label: 'Consent_Detail.Website'
        },
        {
            value: 'PDF',
            label: 'Consent_Detail.File'
        }
    ];

    constructor(private dialogService: DialogService, private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        if (!this.consent.id) {
            this.isExpanded = true;
        }

        if (!this.consent.nameTranslation) {
            this.consent.nameTranslation = new Translation(null, null, {
                EN: null,
                NL: null,
                DE: null,
                FR: null
            });
        }
        this.resourceType = this.consent.resource.type ? this.consent.resource.type : 'WEBSITE';
    }

    onSaveConsentClicked() {
        if (Object.values(this.consent.nameTranslation.values).some(value => !!value)) {
            this.consentSave.emit(this.consent);
        }
    }

    onDeleteConsentClicked() {
        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {key: 'Dialog.Delete_Consent_Title'},
            bodyText: {key: 'Dialog.Delete_Consent_Body'},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'consent-delete');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (response.cancelled) {
                return;
            }

            this.consentDelete.emit(this.consent);
        });
    }

    onClearConsentSettingClicked() {
        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {key: 'Dialog.Delete_Consent_Setting_Title'},
            bodyText: {key: 'Dialog.Delete_Consent_Setting_Body'},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'consent-setting-delete');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (response.cancelled) {
                return;
            }

            const consentSetting = this.consent.consentSetting;
            consentSetting.filterEventIds = [];
            consentSetting.filterSubscriptionTypeIds = [];
            this.consentSettingDelete.emit(this.consent);
            this.notificationService.showTranslatedNotification('success', 'consent_conditions', 'removed');
        });
    }

    getMockResource(link: string, type?: string) {
        return link ? new Resource(type ? type : 'WEBSITE', null, null, link) : null;
    }

    onResourceSelected(resource: Resource, type?: string) {
        this.consent.resource = null;
        if (type) {
            resource.type = type;
        }
        this.consent.resource = resource;
    }

    updateSelection(field: string, object: any) {
        const index = this.consent.consentSetting[field].findIndex(id => {
            return id === object.id;
        }, 0);
        if (index >= 0) {
            this.consent.consentSetting[field].splice(index, 1);
        } else {
            this.consent.consentSetting[field].push(object.id);
        }
    }

    inSelection(field: string, object: any) {
        return this.consent.consentSetting[field].findIndex(id => {
            return id === object.id;
        }) >= 0;
    }

    updateArraySelection(field: string, array) {
        this.consent.consentSetting[field].length = 0;
        for (const object of array) {
            this.updateSelection(field, object);
        }
    }

}
