import {Component} from '@angular/core';
import {SeatedApiService} from '../../services/util/seated-api/seated-api.service';
import {Observable} from 'rxjs';
import {Request} from '../../models/request.class';
import {ActivatedRoute} from '@angular/router';
import { HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import {SidebarConfig, SidebarInterface} from '../../models/abstract/sidebar.interface';
import {MoveRequestService} from '../../services/move-request/move-request.service';

@Component({
    selector: 'app-move-request-sidebar',
    templateUrl: './move-request-sidebar.component.html',
    styleUrls: ['./move-request-sidebar.component.scss'],
    standalone: false
})
export class MoveRequestSidebarComponent implements SidebarInterface {

    sidebarConfig: SidebarConfig = {
        closeAfterRefresh: false
    };

    private httpParams: HttpParams = new HttpParams()
        .set('request[customer]', 'true')
        .set('request[order]', 'true')
        .set('request[requestItems]', 'true')
        .set('requestItem[ticketType]', 'true')
        .set('requestItem[subscriptionTypePrice]', 'true')
        .set('requestItem[venueSection]', 'true');

    public request$: Observable<Request> = this.seatedApi.get<Request>(new Request(), {id: this.route.snapshot.params.requestId}, this.httpParams).pipe(map(request => {
        const requestItemGroups = Object.values(this.groupBy(request.requestItems, '_typeId')).map((group) => {
            const requestItem = group[0];
            return {
                name: requestItem.ticketType?.name || requestItem.subscriptionTypePrice?.name || requestItem.productTypePrice?.name,
                groupName: requestItem.event?.name || requestItem.subscriptionType?.name || requestItem.productType?.name,
                typeId: requestItem.ticketTypeId || requestItem.subscriptionTypePriceId || requestItem.productTypePriceId,
                toVenueSectionSeat: null,
                isSelected: false,
                amount: (group as any).length,
                initialAmount: (group as any).length,
                venueSection: requestItem.venueSection
            };
        });

        return ({...request, _requestItemGroups: requestItemGroups, _moveVenueSection: request.requestItems[0].venueSection} as Request);
    }));

    constructor(private seatedApi: SeatedApiService, private route: ActivatedRoute, private moveRequestService: MoveRequestService) {
    }

    private groupBy(data, key) {
        return data.reduce((storage, item) => {
            const group = item[key];
            storage[group] = storage[group] || [];
            storage[group].push(item);

            return storage;
        }, {});
    }

}
