import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {TranslationService} from '../../services/translation.service';

@Component({
    selector: 'cm-ticketing-translation-dialog',
    templateUrl: 'translation-dialog.component.html',
    styleUrls: ['translation-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TranslationDialogComponent implements OnInit, OnDestroy {
    @ViewChild('cmModal') cmModal: ElementRef;
    @Output() translationChanged: EventEmitter<any> = new EventEmitter();
    @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
    @Input() title: string;
    @Input() readonly = false;
    @Input() multiLine = false;
    @Input() textArea = false;
    @Input() maxChars;
    @Input() translationObject;
    public beforeTranslationObject;
    public translationArray;
    public currentLang: string;

    constructor(private translationService: TranslationService) {
        //
    }

    ngOnInit(): void {
        this.currentLang = this.translationService.getLanguage();
    }

    ngOnDestroy(): void {
        this.cmModal.nativeElement.parentElement.removeChild(this.cmModal.nativeElement);
    }

    openDialog(): void {
        this.beforeTranslationObject = Object.assign({}, this.translationObject);
        this.translationArray = [];
        for (const key in this.translationObject) {
            if (this.translationObject.hasOwnProperty(key)) {
                this.translationArray.push(key);
            }
        }
        this.cmModal.nativeElement.open();

        // todo Ugly setTimeout, how to fix?
        setTimeout(() => {
            document.querySelectorAll('.cm-flag').forEach((flagElement, i) => {
                flagElement.classList.add(`cm-flag-${this.checkTranslationFlag(this.translationArray[i])}`);
            });
        });
    }

    closeModal(resetTranslations = false): void {
        if (this.multiLine) {
            for (const key of this.translationArray) {
                if (!this.translationObject[key]) {
                    this.translationObject[key] = '';
                }
            }
        }
        this.cmModal.nativeElement.close();
        this.dialogClosed.emit();

        if (resetTranslations) {
            this.translationObject = this.beforeTranslationObject;
        }
    }

    checkMaxChars(): boolean {
        for (const translation in this.translationObject) {
            if (this.translationObject[translation] && this.translationObject[translation].length > this.maxChars) {
                return true;
            }
        }
        return false;
    }

    checkTranslationFlag(flagKey: string): string {
        if (flagKey === 'EN') {
            return 'GB';
        } else if (flagKey === 'AR') {
            return 'SA';
        } else {
            return flagKey;
        }
    }

    translationObjectChanged(event, lang): void {
        if (event && event.target && event.target.value) {
            this.translationObject[lang] = event.target.value;
        } else {
            this.translationObject[lang] = '';
        }
    }

    saveChanges(): void {
        Object.keys(this.translationObject).forEach((key: string) => {
            this.translationObject[key] =
                this.translationObject[key]?.replace(/<a\s+([^>]*?)>/g, (_: any, attributes: string) => {
                    // Handle anchor tag attributes separately
                    const cleanedAttributes = attributes.replace(/\\"/g, '"');
                    return `<a ${cleanedAttributes}>`;
                })
                // Remove surrounding quotes from the entire string
                .replace(/^"(.*)"$/, '$1')
                // Remove attributes from all tags EXCEPT <a> tags
                .replace(/<(?!a\b)(\w+)(\s[^>]*)?>/g, '<$1>')
                // Convert any remaining \" to " in the entire string
                .replace(/\\"/g, '"');
        });

        this.translationChanged.emit(this.translationObject);
        this.closeModal();
    }

    public translationInputChanged(e: any, language: string): void {
        if (!e.detail) {
            return;
        }

        this.translationObject[language] = e.detail.value;
    }
}
