import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {VenueSectionSeat} from '../../../models/venue-section-seat.class';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {VenueSectionSeatSettingService} from '../../../services/venue-section-seat-setting-service/venue-section-seat-setting.service';
import {VenueSectionSeatSetting} from '../../../models/venue-section-seat-setting.class';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-dialog-venue-section-seat-setting',
    templateUrl: './dialog-venue-section-seat-setting.component.html',
    styleUrls: ['./dialog-venue-section-seat-setting.component.scss'],
    standalone: false
})
export class DialogVenueSectionSeatSettingComponent extends DialogBase<IInput, IOutput> implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    venueSectionSeats: VenueSectionSeat[] = [];
    eventId: string;

    constructor(dialogService: DialogService, private venueSectionSeatSettingService: VenueSectionSeatSettingService) {
        super(dialogService);
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    setModal(data: IInput) {
        if (data.extraData && data.extraData.venueSectionSeats) {
            this.venueSectionSeats = data.extraData.venueSectionSeats;
            this.eventId = data.extraData.eventId;

            for (const venueSectionSeat of this.venueSectionSeats) {
                this.venueSectionSeatSettingService.getVenueSectionSeatSettings(venueSectionSeat.id).pipe(
                    takeUntil(this.destroy$)
                ).subscribe(venueSectionSeatSettings => {
                    venueSectionSeat.venueSectionSeatSettings = venueSectionSeatSettings;
                });
            }
        }
    }

    isDisabled(venueSectionSeat: VenueSectionSeat, isEventException: boolean) {
        if (venueSectionSeat.venueSectionSeatSettings === null) {
            return true;
        }

        for (const venueSectionSeatSetting of venueSectionSeat.venueSectionSeatSettings) {
            if ((venueSectionSeatSetting.eventId === this.eventId && venueSectionSeatSetting.eventId && isEventException) || (!venueSectionSeatSetting.eventId && !isEventException)) {
                return true;
            }
        }
    }

    public confirm() {
        let httpParams = new HttpParams();

        if (this.eventId) {
            httpParams = httpParams.set('scope[eventId]', this.eventId);
        }

        for (const venueSectionSeat of this.venueSectionSeats) {
            for (const venueSectionSeatSetting of venueSectionSeat.venueSectionSeatSettings) {
                if (venueSectionSeatSetting.id) {
                    this.venueSectionSeatSettingService.patchVenueSectionSeatSetting(venueSectionSeat.id, venueSectionSeatSetting.id, venueSectionSeatSetting, httpParams).subscribe();
                } else {
                    this.venueSectionSeatSettingService.postVenueSectionSeatSetting(venueSectionSeat.id, venueSectionSeatSetting, httpParams).subscribe();
                }
            }
        }

        super.confirm({
            successful: true,
            // @ts-ignore
            extraData: null
        });
    }

    public cancel() {
        super.cancel();
    }

    addVenueSectionSeatSetting(venueSectionSeat: VenueSectionSeat, isEventException: boolean) {
        venueSectionSeat.venueSectionSeatSettings.push(new VenueSectionSeatSetting(null, venueSectionSeat.id, isEventException ? this.eventId : null, venueSectionSeat.capacity));
    }

    deleteVenueSectionSeatSetting(venueSectionSeat: VenueSectionSeat, venueSectionSeatSetting: VenueSectionSeatSetting) {
        if (venueSectionSeatSetting.id) {
            this.venueSectionSeatSettingService.deleteVenueSectionSeatSetting(venueSectionSeat.id, venueSectionSeatSetting.id).subscribe(() => {
                const index = venueSectionSeat.venueSectionSeatSettings.findIndex(item => {
                    return item.id === venueSectionSeatSetting.id;
                }, 0);

                if (index >= 0) {
                    venueSectionSeat.venueSectionSeatSettings.splice(index, 1);
                }
            });
        } else {
            const index = venueSectionSeat.venueSectionSeatSettings.findIndex(item => {
                return item.id === venueSectionSeatSetting.id && item.eventId === venueSectionSeatSetting.eventId;
            }, 0);

            if (index >= 0) {
                venueSectionSeat.venueSectionSeatSettings.splice(index, 1);
            }
        }
    }

}
