import {Pipe, PipeTransform} from '@angular/core';
import {TranslationPipe} from './translation.pipe';
import {CurrencyPipe} from '@angular/common';
import {Discount} from '../../shared/models/discount';
import {generateLoadingRows} from '../helpers/table-helper';

@Pipe({
    name: 'discountCodeTableRow',
    standalone: false
})
export class DiscountCodeTableRowPipe implements PipeTransform {

    constructor(private translationPipe: TranslationPipe, private currencyPipe: CurrencyPipe) {
    }

    transform(discounts: Discount[], ...args: any[]): any[] {
        const isLoading = args[0] || false;
        const isDeletable = args[1] || false;

        if (isLoading) {
            return generateLoadingRows(4);
        }

        return discounts.map((discount, i) => {

            const actionOptions = [
                {
                    dataDisplay: this.translationPipe.transform('Table_Row.Actions.Delete'),
                    dataIcon: 'delete',
                    id: 'DELETE',
                    disabled: (discount.usage > 0 || !isDeletable)
                }
            ];

            return {
                id: discount.id,
                rowData: [
                    {
                        value: discount.code,
                        options: []
                    },
                    {
                        value: discount.usage,
                        options: []
                    },
                    {
                        value: this.currencyPipe.transform(discount.totalDiscount),
                        options: []
                    },
                    {
                        value: actionOptions,
                        options: [{name: 'data-action', value: true}]
                    }
                ]
            };
        });
    }

}
