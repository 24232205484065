import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {DialogComponent} from '../dialogs/dialog/dialog.component';
import {SeatedApiService} from '../../services/util/seated-api/seated-api.service';
import {Observable} from 'rxjs';
import {Event} from '../../models/event.class';
import {map} from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import {NotificationService} from '../../services/notification-service/notification.service';
import {BlockadeChangeEvent, BlockadeService} from '../../services/blockade.service';
import moment from 'moment';
import {pushIfUnique, removeFromArray} from '../../../core/helpers/array-helper';

@Component({
    selector: 'app-blockade-action-footer',
    templateUrl: './blockade-action-footer.component.html',
    styleUrls: ['./blockade-action-footer.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BlockadeActionFooterComponent implements OnInit, OnChanges {

    @Input()
    updatedBlockades: any = null;

    @Input()
    venueId: string = null;

    @Input()
    eventId: string;

    @Output()
    blockadesSaved: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    changesDiscarded: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('cancelDialog')
    cancelDialog: DialogComponent;

    @ViewChild('reviewDialog')
    reviewDialog: DialogComponent;

    @ViewChild('saveDialog')
    saveDialog: DialogComponent;

    private eventsHttpParams: HttpParams = new HttpParams()
        .set('take', -1)
        .set('event[after]', moment().toISOString());

    public events$: Observable<Event[]> = this.seatedApi.getMany<Event>(new Event(), this.eventsHttpParams).pipe(map(events => {
        if (this.eventId) {
            const event = events.find(eventI => eventI.id === this.eventId);
            const eventIndex = events.findIndex(eventI => eventI.id === this.eventId);
            events.splice(eventIndex, 1);
            events.unshift(event);
        }

        return events.filter(event => event?.venueId === this.venueId);
    }));

    public summary: any;
    public review: {
        id: string,
        added: number,
        removed: number
    }[] = [];

    public selectedEventIds: string[] = [];

    constructor(private seatedApi: SeatedApiService, private notificationService: NotificationService, private blockadeService: BlockadeService) {
    }

    ngOnInit(): void {
        if (this.eventId) {
            this.selectedEventIds.push(this.eventId);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.summary = null;

        const summary = {
            seats: [],
            blockades: []
        };

        if (!changes.updatedBlockades) {
            return;
        }

        this.summary = changes.updatedBlockades.currentValue.reduce((acc, cur) => {
            acc.seats = pushIfUnique(acc.seats, cur.venueSectionSeatId);

            if (cur.addToBlockadeId) {
                acc.blockades = pushIfUnique(acc.blockades, cur.addToBlockadeId);
            }

            if (cur.removeFromBlockadeId) {
                acc.blockades = pushIfUnique(acc.blockades, cur.removeFromBlockadeId);
            }

            return acc;
        }, summary);

        let updatedBlockadeIds = [];
        changes.updatedBlockades.currentValue.forEach((blockadeChangeEvent: BlockadeChangeEvent) => {
            if (blockadeChangeEvent.addToBlockadeId) {
                updatedBlockadeIds = pushIfUnique(updatedBlockadeIds, blockadeChangeEvent.addToBlockadeId);
            }

            if (blockadeChangeEvent.removeFromBlockadeId) {
                updatedBlockadeIds = pushIfUnique(updatedBlockadeIds, blockadeChangeEvent.removeFromBlockadeId);
            }
        });
    }

    cancelChanges(): void {
        this.cancelDialog.open();
    }

    openReviewDialog(): void {
        this.reviewDialog.open();
    }

    openSaveDialog(): void {
        this.saveDialog.open();
    }

    onCancelDialogConfirm(): void {
        this.changesDiscarded.emit();
    }

    onSaveDialogConfirm(e: any): void {
        const saveBlockadesBody = this.blockadeService.generateSaveBlockadesBody(this.updatedBlockades, this.selectedEventIds);

        this.blockadeService.updateBlockades(saveBlockadesBody).subscribe({
            next: () => {
                this.blockadesSaved.emit();
                this.notificationService.showTranslatedNotification('success', 'blockade', 'saved');
            },
            error: () => {
                this.notificationService.showTranslatedNotification('error', 'blockade', 'save_failed');
            }
        });
    }

    onEventChange(e: any, eventId: string): void {
        if (e.target.checked) {
            this.selectedEventIds = pushIfUnique(this.selectedEventIds, eventId);
        } else {
            this.selectedEventIds = removeFromArray(this.selectedEventIds, eventId);
        }
    }

}
