import {Component} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {FormManagementService} from '../../../services/form-management.service';

@Component({
    selector: 'app-dialog-create-event-category',
    templateUrl: './dialog-create-event-category.component.html',
    styleUrls: ['./dialog-create-event-category.component.css'],
    standalone: false
})
export class DialogCreateEventCategoryComponent extends DialogBase<IInput, IOutput> {
    public titleText: any;
    public bodyText: any;
    public confirmText: string;
    public extraData: any;

    public eventCategoryName: string;
    public selectedVenueId: string;

    constructor(dialogService: DialogService, private formManagementService: FormManagementService) {
        super(dialogService);
    }

    setModal(data: IInput) {
        this.titleText = data.titleText;
        this.bodyText = data.bodyText;
        this.confirmText = data.confirmText;
        this.extraData = data.extraData;
    }

    public confirm() {
        if (this.formManagementService.validate('createEventCategory')) {
            super.confirm({
                successful: true,
                extraData: {
                    name: this.eventCategoryName,
                    venueId: this.selectedVenueId
                }
            });
        }
    }

    public cancel() {
        super.cancel();
    }

}
