import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '@angular/common';
import {Event} from '../models/event.class';
import {LocaleService} from '../../core/services/locale.service';

@Pipe({
    name: 'startEndDate',
    standalone: false
})
export class StartEndDatePipe implements PipeTransform {

    constructor(private localeService: LocaleService) {
    }

    transform(value: Event, ...args: string[]): unknown {
        const locale = this.localeService.getLanguage();
        const timezone = args[0] ?? '';

        const startAt = formatDate(value.startAt, 'MMM dd', locale, timezone);
        const endAt = formatDate(value.endAt, 'MMM dd yyyy', locale, timezone);
        return (`${startAt} - ${endAt}`).trim();
    }

}
