import {Component} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';

@Component({
    selector: 'app-dialog-confirmation',
    templateUrl: 'dialog-confirmation.component.html',
    styleUrls: ['./dialog-confirmation.component.css'],
    standalone: false
})
export class DialogConfirmationComponent extends DialogBase<IInput, IOutput> {
    public titleText: any;
    public bodyText: any;
    public cancelText: string;
    public confirmText: string;
    public extraData: any;

    constructor(dialogService: DialogService) {
        super(dialogService);
    }

    setModal(data: IInput) {
        this.titleText = data.titleText;
        this.bodyText = data.bodyText;
        this.cancelText = data.cancelText;
        this.confirmText = data.confirmText;
        this.extraData = data.extraData;
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: null
        });
    }

    public cancel() {
        super.cancel();
    }

}
