import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import {VenueSectionSeat} from '../../models/venue-section-seat.class';

@Component({
    selector: 'app-seat-map-tooltip',
    templateUrl: './seat-map-tooltip.component.html',
    styleUrls: ['./seat-map-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SeatMapTooltipComponent implements OnInit, OnChanges {

    @Input() venueSectionSeat: VenueSectionSeat;
    @Input() tooltipConfig: TooltipConfig;

    private host: HTMLElement;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.host = this.elementRef.nativeElement;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.host) {
            return;
        }

        const opacity = this.tooltipConfig.content && this.tooltipConfig.position ? '1' : '0';
        const display = this.tooltipConfig.content && this.tooltipConfig.position ? 'block' : 'none';

        this.renderer.setStyle(this.host, 'opacity', opacity);
        setTimeout(() => {
            this.renderer.setStyle(this.host, 'display', display);
        }, 40);

        if (!this.tooltipConfig.position) {
            return;
        }

        this.renderer.setStyle(this.host, 'left', `${this.tooltipConfig.position.x}px`);
        this.renderer.setStyle(this.host, 'top', `${this.tooltipConfig.position.y}px`);
    }

}

export class TooltipConfig {
    position?: { x: number, y: number };
    content?: string;
}
