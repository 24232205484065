import {ChangeDetectorRef, Component, DEFAULT_CURRENCY_CODE, Inject, OnDestroy, OnInit} from '@angular/core';
import {CmTranslationService} from '../../../../core/services/cm-translation.service';
import {OrderManagementService} from '../../../services/order-service/order-management.service';
import {OrderItem} from '../../../models/order-item.class';
import {ProductGroupType} from '../../../enums/order/product-group-type';
import {HttpParams} from '@angular/common/http';
import {Venue} from '../../../models/venue.class';
import {VenueSection} from '../../../models/venue-section.class';
import {VenueService} from '../../../services/venue-service/venue.service';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {WindowHelper} from '../../../helpers/util/window-helper';
import {DialogConfirmationComponent} from '../../dialogs/dialog-confirmation/dialog-confirmation.component';
import {LocalReservation} from '../../../models/reservation/local-reservation.class';
import {LocalReservationService} from '../../../services/local-reservation.service';
import {GroupedOrderItem} from '../../../models/reservation/grouped-order-item.class';
import {OrderItemReservation} from '../../../models/reservation/order-item-reservation.class';
import {TicketTypeService} from '../../../services/ticket-service/ticket.service';
import {filter, map, tap} from 'rxjs/operators';
import {SubscriptionTypeService} from '../../../services/subscription-type-service/subscription-type.service';
import {SeatedApiService} from '../../../services/util/seated-api/seated-api.service';
import {ProductType} from '../../../models/product-type';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {OrderBarService} from '../../../services/order-bar.service';
import {OrderItemType} from '../../../models/reservation/order-item-type.class';
import {OrderItemGroup} from '../../../models/order-item-group.class';
import {pushIfUniqueValue} from '../../../../core/helpers/array-helper';
import {NotificationService} from '../../../services/notification-service/notification.service';
import {getCurrencySymbol} from '@angular/common';

@Component({
    selector: 'app-basket-order-table',
    templateUrl: './basket-order-table.component.html',
    styleUrls: ['./basket-order-table.component.scss'],
    standalone: false
})
export class BasketOrderTableComponent implements OnInit, OnDestroy {
    public isMobile = WindowHelper.isMobile();

    reservation: LocalReservation;

    showDetailsItemId: string;
    currencySymbol: string;

    localReservation$: Observable<LocalReservation>;
    conflictedOrderItemsGrouped$: Observable<GroupedOrderItem[]>;

    venues: Venue[] = [];
    venueRequestedIds = [];
    venueSections: VenueSection[];

    orderItemTypes: OrderItemType[] = [];
    /** Map<groupedOrderItemId, Map<VenueSectionId, OrderItemType[]>> */
    venueSectionOrderItemTypes: Map<string, Map<string, OrderItemType[]>>;
    venueSectionGroupIdsPerGroupedOrderItem: Map<string, string[]>;

    private orderBarExpandedSubscription: Subscription;
    private localReservationSubscription: Subscription;
    private conflictedOrderItemsSubscription: Subscription;

    isLoading = false;

    constructor(@Inject(DEFAULT_CURRENCY_CODE) private currencyId: string,
                private translationService: CmTranslationService,
                private orderManagementService: OrderManagementService,
                private venueService: VenueService,
                private changeDetector: ChangeDetectorRef,
                private dialogService: DialogService,
                private localReservationService: LocalReservationService,
                private ticketService: TicketTypeService,
                private subscriptionTypeService: SubscriptionTypeService,
                private seatedApiService: SeatedApiService,
                private orderBarService: OrderBarService,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.showDetailsItemId = null;
        this.localReservation$ = this.localReservationService.localReservation$;

        this.conflictedOrderItemsGrouped$ = this.localReservation$.pipe(
            filter(reservation => !!reservation),
            map(reservation => reservation.groupedOrderItems.filter(groupedOrderItem => groupedOrderItem.hasConflict)),
            map(conflictedGroupedOrderItems => {
                const groupedOrderItems = JSON.parse(JSON.stringify(conflictedGroupedOrderItems));
                groupedOrderItems.forEach(groupedOrderItem => {
                    groupedOrderItem.items = groupedOrderItem.items.filter(orderItem => orderItem.hasConflict);
                });

                return groupedOrderItems;
            })
        );

        this.localReservationSubscription = this.localReservationService.localReservation$.subscribe(reservation => {
            if (reservation) {
                this.reservation = reservation;
            }
        });


        this.venueSectionOrderItemTypes = new Map<string, Map<string, any>>();
        this.initializeOrderBarExpandedEvent();

        this.currencySymbol = getCurrencySymbol(this.currencyId, (window as any).preferredLanguage);
    }

    public removeConflictedOrderItems(conflictedGroupedOrderItems: GroupedOrderItem[]): void {
        const isLocalReservation = this.reservation.isLocalReservation();
        const orderItemIdsToDelete = [];
        for (const conflict of conflictedGroupedOrderItems) {
            for (const orderItem of conflict.items) {
                if (!isLocalReservation) {
                    orderItemIdsToDelete.push(orderItem.orderItemId);
                    continue;
                }
                this.localReservationService.deleteOrderItemFromReservation(ProductGroupType[conflict.type], conflict.id, orderItem.venueSectionSeatId);
            }
        }

        if (isLocalReservation) {
            this.orderManagementService.conflictsSubject.next(null);
            this.notificationService.showTranslatedSuccessNotification('Order_Item', 'Deleted', {
                amount: orderItemIdsToDelete.length,
                isPlural: orderItemIdsToDelete.length > 1
            });
            return;
        }

        this.orderManagementService.deleteOrderItems(orderItemIdsToDelete).subscribe({
            next: () => {
                this.localReservationService.deleteOrderItemsWithIdsFromReservation(orderItemIdsToDelete);
                this.orderManagementService.refreshSelectedOrder();
                this.notificationService.showTranslatedSuccessNotification('Order_Item', 'Deleted', {
                    amount: orderItemIdsToDelete.length,
                    isPlural: orderItemIdsToDelete.length > 1
                });

                this.orderManagementService.conflictsSubject.next(null);
            },
            error: () => {
                this.notificationService.showTranslatedError('Deleting_Order_Items');
            }
        });
    }

    private initializeOrderBarExpandedEvent() {
        this.orderBarExpandedSubscription = this.orderBarService.orderBarExpanded$.subscribe(expanded => {
            if (!expanded || !this.localReservationService.isActive()) {
                return;
            }

            this.isLoading = true;
            const groupedOrderItems = this.localReservationService.getGroupedOrderItems();
            const groupItemIds = groupedOrderItems.map(groupedOrderItem => groupedOrderItem.id);

            const newGroupItems = groupItemIds.filter(
                orderItemId => this.orderItemTypes.map(type => type.groupedOrderItemId).indexOf(orderItemId) < 0
            );

            this.venueSectionGroupIdsPerGroupedOrderItem = this.getVenueSectionGroupIdsPerGroupedOrderItem();
            if (newGroupItems.length === 0) {
                // No new order item types to fetch
                this.updateVenueSectionOrderItemTypes();
                this.updateLocalReservationItemPrices();
                return;
            }

            this.updateLocalOrderItemTypes(newGroupItems, groupedOrderItems);
        });
    }

    getOrderItemTypes(groupId: string): OrderItemType[] {
        return this.orderItemTypes.filter(orderItemType => orderItemType.groupedOrderItemId === groupId);
    }

    getPriceItems = (orderItemTypeId: string, venueSectionGroupId?: string): number => {
        if (venueSectionGroupId === null) {
            return this.orderItemTypes.find(type => type.id === orderItemTypeId)?.prices[0].price;
        }

        const orderItemType = this.orderItemTypes.find(type => type.id === orderItemTypeId);
        const orderItemPriceItem = orderItemType?.prices.find(priceItem => {
            return priceItem.venueSectionGroupId === venueSectionGroupId;
        });

        if (!orderItemPriceItem) {
            return null;
        }

        return orderItemPriceItem.price;
    }

    /**
     * Update venueSectionOrderItemTypes map.
     * This map contains all order item types for each venue section. Each venue section has its own grouped order item.
     *
     * Example:
     * venueSectionOrderItemTypes = {
     *    event1Id: {
     *      'goldenCircleId': [OrderItemType, OrderItemType, ...],
     *      'defaultSeatId': [OrderItemType, OrderItemType, ...],
     *    },
     * }
     */
    private updateVenueSectionOrderItemTypes() {
        this.venueSectionGroupIdsPerGroupedOrderItem.forEach((venueSectionGroupIds, groupItemId) => {
            if (!this.venueSectionOrderItemTypes.has(groupItemId)) {
                this.venueSectionOrderItemTypes.set(groupItemId, new Map<string, any>());
            }

            const sectionGroupPriceItems = this.venueSectionOrderItemTypes.get(groupItemId);

            // Venue section group ids are used to filter the priceItems
            venueSectionGroupIds.forEach(groupId => {
                if (this.venueSectionOrderItemTypes.get(groupItemId).has(groupId)) {
                    return;
                }

                const orderItemTypes = JSON.parse(JSON.stringify(this.getOrderItemTypes(groupItemId)));
                let relevantOrderItemTypes = orderItemTypes.filter(orderItemType => {
                    return orderItemType.prices.find(priceItem => priceItem.venueSectionGroupId === groupId) !== null;
                });

                relevantOrderItemTypes.forEach(orderItemType => {
                    orderItemType.prices = orderItemType.prices.filter(priceItem => {
                        return priceItem.venueSectionGroupId === groupId;
                    });
                });

                relevantOrderItemTypes = relevantOrderItemTypes.filter(orderItemType => orderItemType.prices.length > 0);

                sectionGroupPriceItems.set(groupId, relevantOrderItemTypes);
            });
        });

        this.isLoading = false;
    }

    private updateLocalReservationItemPrices() {
        try {
            this.localReservationService.initializePrices(this.getPriceItems);
        } catch (e) {
            this.notificationService.showTranslatedError('Price_Not_Set');
        }
    }

    private getVenueSectionGroupIdsPerGroupedOrderItem(): Map<string, string[]> {
        const venueSectionGroupIds = new Map();
        for (const groupedOrderItem of this.reservation.groupedOrderItems) {
            if (groupedOrderItem.type === ProductGroupType.PRODUCT) {
                continue;
            }

            for (const orderItemReservation of groupedOrderItem.items) {
                if (!venueSectionGroupIds.has(groupedOrderItem.id)) {
                    venueSectionGroupIds.set(groupedOrderItem.id, []);
                }

                venueSectionGroupIds.get(groupedOrderItem.id).push(orderItemReservation.venueSectionGroupId);
            }
        }

        return venueSectionGroupIds;
    }

    getGroupOrderItemTypes(groupId: string): OrderItemType[] {
        return this.orderItemTypes.filter(orderItemType => orderItemType.groupedOrderItemId === groupId);
    }

    private updateLocalOrderItemTypes(newGroupItems: string[], groupedOrderItems: GroupedOrderItem[]) {
        const requests = [];
        const depthParameter = new HttpParams().set('depth', '3');
        for (const groupItemId of newGroupItems) {
            switch (groupedOrderItems.find(groupedOrderItem => groupedOrderItem.id === groupItemId).type) {
                case ProductGroupType.EVENT_TICKET:
                    requests.push(this.ticketService.getTicketTypes(groupItemId, depthParameter).pipe(
                        tap(ticketTypes => {
                            ticketTypes.forEach(ticketType => {
                                this.orderItemTypes = pushIfUniqueValue(
                                    this.orderItemTypes,
                                    new OrderItemType(ticketType.id, groupItemId, ticketType.name, ProductGroupType.EVENT_TICKET, ticketType.prices)
                                );
                            });
                        })
                    ));
                    break;
                case ProductGroupType.SUBSCRIPTION:
                    requests.push(
                        this.subscriptionTypeService.getSubscriptionType(groupItemId, depthParameter).pipe(
                            tap(subscriptionType => {
                                subscriptionType.subscriptionTypePrices.forEach(subscriptionTypePrice => {
                                    this.orderItemTypes = pushIfUniqueValue(
                                        this.orderItemTypes,
                                        new OrderItemType(subscriptionTypePrice.id, groupItemId, subscriptionTypePrice.name, ProductGroupType.SUBSCRIPTION, subscriptionTypePrice.priceItems)
                                    );
                                });
                            })
                        )
                    );
                    break;
                case ProductGroupType.PRODUCT:
                    requests.push(
                        this.seatedApiService.getMany<ProductType>(new ProductType(), depthParameter
                            .set('productType[productTypePrice]', 'true')).pipe(
                            tap(productTypes => {
                                for (const productType of productTypes) {
                                    productType.productTypePrices.forEach(productTypePrice => {
                                        const orderItemType = new OrderItemType(productTypePrice.id, productType.id, productTypePrice.name, ProductGroupType.PRODUCT, [productTypePrice.priceItem]);
                                        this.orderItemTypes = pushIfUniqueValue(this.orderItemTypes, orderItemType);
                                    });
                                }
                            })
                        )
                    );
                    break;
            }
        }

        forkJoin(requests).subscribe(() => {
            this.orderItemTypes = this.orderItemTypes.filter(type => type.prices.length > 0);

            this.updateVenueSectionOrderItemTypes();
            this.updateLocalReservationItemPrices();
        });
    }

    deleteOrderItem(orderItem: OrderItemReservation): void {
        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {
                key: 'Order_Item.Delete'
            },
            bodyText: {key: 'Order_Item.Delete.Confirm_Body'},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'event-delete');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (response.cancelled) {
                return;
            }

            if (!this.reservation.isLocalReservation()) {
                this.orderManagementService.deleteOrderItems([orderItem.orderItemId]).subscribe({
                    complete: () => {
                        this.localReservationService.deleteOrderItemReservation(orderItem);

                        this.notificationService.showTranslatedSuccessNotification('Order_Item', 'Deleted');
                        if (!this.reservation.hasItems()) {
                            this.orderBarService.setOrderBarExpanded(false);
                        }
                    },
                    error: () => {
                        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
                            titleText: {
                                key: 'Order_Item.Delete'
                            },
                            bodyText: {key: 'Order_Item.Delete.Error_Body'},
                            confirmText: 'Dialog.Ok'
                        }, 'order-item-delete');
                    }
                });
                return;
            }

            this.localReservationService.deleteOrderItemReservation(orderItem);
            this.notificationService.showTranslatedSuccessNotification('Order_Item', 'Deleted');
            if (!this.reservation.hasItems()) {
                this.orderBarService.setOrderBarExpanded(false);
            }
        });
    }

    updateOrderItemsVenueSectionAccessId(id: string, orderItems: OrderItemReservation[]) {
        for (const orderItemReservation of orderItems) {
            orderItemReservation.venueSectionAccessId = id;
        }

        this.localReservationService.setLocalReservation(this.reservation);
    }

    updateOrderPrice(event, orderItems: OrderItem[], price: string) {
        if (price === null || price === '') {
            return;
        }

        const priceFloat = parseFloat(price.replace(this.currencySymbol, '').replace(',', '.'));
        for (const orderItem of orderItems) {
            orderItem.price = priceFloat;
        }

        event.target.value = null;
    }

    updateOrderItemTypes(e: Event, group: OrderItemGroup, id: string, orderItems: OrderItemReservation[]): void {
        if (e) {
            (e.target as HTMLSelectElement).selectedIndex = 0;
        }

        for (const orderItem of orderItems) {
            if (group.type === ProductGroupType.PRODUCT) {
                orderItem.typeId = id;
                orderItem.price = this.orderItemTypes?.find(type => type.id === id).prices[0]?.price;
                continue;
            }

            const orderItemType = this.venueSectionOrderItemTypes.get(group.id)
                .get(orderItem.venueSectionGroupId)
                .find(type => type.id === id);
            if (!orderItemType) {
                continue;
            }

            orderItem.typeId = id;
            orderItem.price = orderItemType.prices[0].price;
        }
        this.localReservationService.setLocalReservation(this.reservation);
    }

    getVenue(venueId: string): Venue {
        if (!venueId) {
            return null;
        }

        let venue = this.venues.find((venueItem) => venueItem.id === venueId);
        if (!venue) {
            const index = this.venueRequestedIds.indexOf(venueId);
            if (index < 0) {
                this.venueRequestedIds.push(venueId);
                this.venueService.getVenue(venueId, new HttpParams().set('depth', '3')).subscribe(venueData => {
                    venue = venueData;
                    this.venues.push(venue);
                    this.changeDetector.markForCheck();
                });
            }
        }

        return venue;
    }

    showDetailsFor(orderItemId: string): void {
        this.showDetailsItemId = orderItemId === this.showDetailsItemId ? null : orderItemId;
    }

    updateOrderItemNote(orderItem: OrderItemReservation, value: string): void {
        orderItem.note = value;
        this.localReservationService.setLocalReservation(this.reservation);
    }

    public getOrderItemReservationName(orderItemReservation: OrderItemReservation): string {
        const stringsToJoin = [];
        if (orderItemReservation.row) {
            stringsToJoin.push(`${this.translationService.getPhraseForLanguage('General.Row')} ${orderItemReservation.row}`);
        }

        if (orderItemReservation.seat) {
            stringsToJoin.push(`${this.translationService.getPhraseForLanguage('General.Seat')} ${orderItemReservation.seat}`);
        }

        return stringsToJoin.join(', ');
    }

    ngOnDestroy(): void {
        this.orderBarExpandedSubscription?.unsubscribe();
        this.conflictedOrderItemsSubscription?.unsubscribe();
        this.localReservationSubscription?.unsubscribe();
    }
}
