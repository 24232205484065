import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {VenueSection} from '../../models/venue-section.class';
import {EventStatistics} from '../../models/event-statistics.class';
import { HttpParams } from '@angular/common/http';
import {TicketReportDownload} from '../../services/download-manager-service/types/ticket-report-download';
import {EventService} from '../../services/event-service/event.service';
import {VenueManagementService} from '../../services/venue-service/venue-management.service';
import {DownloadManagerService} from '../../services/download-manager-service/download-manager.service';
import {ExportService} from '../../services/export-service/export.service';
import {EventManagementService} from '../../services/event-service/event-management.service';
import {combineLatest, Observable, Subject} from 'rxjs';
import {Event} from '../../models/event.class';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {Venue} from '../../models/venue.class';

@Component({
    selector: 'app-event-analytics-venue-section-table',
    templateUrl: './event-analytics-venue-section-table.component.html',
    styleUrls: ['./event-analytics-venue-section-table.component.css'],
    standalone: false
})
export class EventAnalyticsVenueSectionTableComponent implements OnInit {

    destroy$: Subject<boolean> = new Subject<boolean>();
    event: Event;
    eventId: string;
    event$ = this.eventManagementService.item$.pipe(
        tap(event => {
            this.event = event;
        })
    );
    eventStatistics: EventStatistics;
    venue$: Observable<Venue>;
    expandedSection: string;

    constructor(private eventService: EventService,
                private venueManagementService: VenueManagementService,
                private downloadManagerService: DownloadManagerService,
                private exportService: ExportService,
                private changeDetector: ChangeDetectorRef,
                private eventManagementService: EventManagementService) {
    }

    ngOnInit(): void {
        this.venue$ = this.venueManagementService.venueSubject.asObservable();

        combineLatest([this.event$]).pipe(
            takeUntil(this.destroy$),
            switchMap(event => {
                return this.eventService.getEventStatistics(this.event.id);
            })
        ).subscribe((eventStatistics: EventStatistics) => {
            this.eventStatistics = eventStatistics;
        });
    }

    getStatistics(venueSection: VenueSection, subVenueSection?: VenueSection) {
        let result: EventStatistics = null;
        this.eventStatistics.venueSections.filter((eventStatistic: EventStatistics) => {
            if (eventStatistic.id === venueSection.id) {
                if (subVenueSection) {
                    return eventStatistic.venueSections.filter((subEventStatistic: EventStatistics) => {
                        if (subEventStatistic.id === subVenueSection.id) {
                            result = subEventStatistic;
                            return true;
                        }
                    });
                } else if (!subVenueSection) {
                    result = eventStatistic;
                    return true;
                }
            }
        });

        return result;
    }

    expandSection(id) {
        if (id !== this.expandedSection) {
            this.expandedSection = id;
        } else {
            this.expandedSection = undefined;
        }
    }

    exportData() {
        this.exportService.getTicketReport(new HttpParams().set('eventTicket[eventIds]', this.event.id).set('skip', '0').set('take', '0')).subscribe(response => {
            this.downloadManagerService.downloadData(
                new TicketReportDownload(this.exportService)
                    .setFilters({eventIds: this.event.id})
                    .setFileName(`${this.event.name} - report`)
                    .setTotal(parseInt(response.headers.get('X-CM-PAGINATION-TOTAL'), 10))
            );
        });
    }

}
