import {
    Component,
    DEFAULT_CURRENCY_CODE,
    EventEmitter, Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Setting} from '../../models/filter-setting.class';
import {isMobile} from '../../../core/helpers/device-helper';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    standalone: false
})
export class SettingsComponent implements OnChanges {
    @Input() hideCurrency = false;
    @Input() formId: string;
    @Input() settings: Setting[];
    @Input() imagesShownOnId = [
        {id: 'SPORTS_ALLIANCE', name: 'ticketingBoy'},
        {id: 'VERIFICATION', name: 'verification'},
        {id: 'EXTERNAL_TICKETS', name: 'ticketingGirl'},
        {id: 'ORGANISATION', name: 'mobileMarketingCloud'},
        {id: 'BARCODE', name: 'mobileShopping'},
    ];

    @Output() settingsSaved: EventEmitter<Setting[]> = new EventEmitter<Setting[]>();

    settingsGroups: SettingsGroup[];

    public isMobile = isMobile;

    @ViewChild('settingsGroup') settingsGroup;

    constructor(@Inject(DEFAULT_CURRENCY_CODE) protected currencyId: string) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.settingsGroups = [];

        for (const setting of this.settings) {
            const groupName = setting.settingGroupId ? setting.settingGroupId : setting.id;
            const settingGroup = this.settingsGroups.find(s => s.name === groupName);

            if (settingGroup) {
                settingGroup.settings.push(setting);
                continue;
            }

            this.settingsGroups.push({
                name: groupName,
                settings: [setting],
                isUpdated: false
            });
        }
    }

    settingUpdated(settingsGroup: SettingsGroup): void {
        settingsGroup.isUpdated = true;
    }

    /**
     * Will consistently skip 3rd and 4th column
     */
    shouldBeRightAligned(index: number): boolean {
        return (index + 1) % 3 === 0 || (index + 1) % 4 === 0;
    }

    findImageNameById(id: string): string {
        return this.imagesShownOnId.find(image => image.id === id)?.name;
    }

    checkForHidden(id: string): boolean {
        for (const settingGroup of this.settingsGroups) {
            for (const setting of settingGroup.settings) {
                if (setting.settingTriggers.includes(id)) {
                    return setting.organisationSetting.value === false;
                }
            }
        }

        return false;
    }

    saveSettingsGroupSettings(settingsGroup: SettingsGroup): void {
        this.settingsSaved.emit(settingsGroup.settings);
        settingsGroup.isUpdated = false;
    }
}

export class SettingsGroup {
    constructor(public name: string = null,
                public settings: Setting[] = null,
                public isUpdated = false) {
    }
}
