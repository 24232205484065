import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormManagementService} from '../../../../shared/services/form-management.service';

@Component({
    selector: 'app-form-datepicker-v3',
    templateUrl: './datepicker-v3.component.html',
    styleUrls: ['./datepicker-v3.component.scss'],
    standalone: false
})
export class DatepickerV3Component implements OnInit, OnDestroy {
    @Input() formId;
    @Input() id;
    @Input() label: string;
    @Input() value: string;
    @Input() isRequired = false;
    @Input() minDate;
    @Input() maxDate;
    @Input() dateFormat = 'dd-MM-yyyy HH:mm';
    @Input() placeholder;
    @Input() errorMessage;
    @Input() includeDateInTime = false;
    @Input() disabled = false;
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private formManagementService: FormManagementService) {
    }

    dateChange(e) {
        const newDate = new Date(e.detail.datetime).toISOString();
        this.value = newDate;
        this.valueChange.emit(newDate);
    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);
    }

    validate(): boolean {
        return this.isRequired && !!this.value;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }
}
