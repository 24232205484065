import {Component, OnInit} from '@angular/core';
import {ProductService} from '../../../shared/services/product-service/product.service';
import {Observable} from 'rxjs';
import {Product} from '../../../shared/models/product';
import {CustomerManagementService} from '../../../shared/services/customer-management/customer-management.service';
import {Customer} from '../../../shared/models/customer.class';
import {tap} from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import {NotificationService} from '../../../shared/services/notification-service/notification.service';

@Component({
    selector: 'app-customer-products',
    templateUrl: './customer-products.component.html',
    styleUrls: ['./customer-products.component.scss'],
    standalone: false
})
export class CustomerProductsComponent implements OnInit {

    customer$: Observable<Customer> = this.customerManagementService.customer$.pipe(
        tap(customer => this.onCustomerLoad(customer))
    );
    products$: Observable<Product[]>;

    private customerId: string;

    constructor(
        private customerManagementService: CustomerManagementService,
        private productService: ProductService,
        private notificationService: NotificationService) {
    }

    ngOnInit(): void {
    }

    cancelProduct(productId: string): void {
        this.productService.cancelProduct(productId).subscribe({
            next: () => {
                this.notificationService.showTranslatedNotification('success', 'product', 'deleted');
                this.products$ = this.getProducts();
            },
            error: () => {
                this.notificationService.showTranslatedNotification('error', 'product', 'delete_failed');
            }
        });
    }

    private onCustomerLoad(customer: Customer): void {
        this.customerId = customer.id;
        this.products$ = this.getProducts();
    }

    private getProducts(): Observable<Product[]> {
        const httpParams = new HttpParams()
            .set('product[customerId]', this.customerId)
            .set('product[productType]', 'true')
            .set('product[productTypePrice]', 'true')
            .set('product[status]', 'ISSUED');

        return this.productService.getProducts(httpParams);
    }

}
