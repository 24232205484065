import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Translation} from '../../models/translation';
import {FormManagementService} from '../../services/form-management.service';
import {translate} from '../../../core/helpers/translation-helper';

@Component({
    selector: 'app-sale-status-setting',
    templateUrl: './sale-status-setting.component.html',
    styleUrl: './sale-status-setting.component.scss',
    standalone: false
})
export class SaleStatusSettingComponent {
    @Input() saleSubject: SaleSubject;

    @Output() onUpdate: EventEmitter<SaleSubject> = new EventEmitter<SaleSubject>();

    public statuses = [
        {id: 'ONLINE', value: translate('Sale_Status.Online'), icon: 'play-open cl-success-color'},
        {id: 'SOLD_OUT', value: translate('Sale_Status.Offline'), icon: 'pause-open cl-warning-color'}
    ];

    constructor(private formManagementService: FormManagementService) {
    }

    public validateSettingsForm(): boolean {
        return this.formManagementService.validate('statusText');
    }

    setStatus(value: string) {
        this.saleSubject.status = value;

        if (this.saleSubject.status !== 'SOLD_OUT') {
            this.saleSubject.statusDescription = null;
            this.saleSubject.statusDescriptionTranslation = Translation.empty();
        }
    }

    saveStatus(): void {
        if (!this.validateSettingsForm()) {
            return;
        }

        this.onUpdate.emit(this.saleSubject);
    }
}

export type SaleSubject = {
    id: string,
    name: string,
    status: string,
    statusDescription: string,
    statusDescriptionTranslation: Translation
};
