import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';

@Component({
    selector: 'app-range-picker',
    templateUrl: './range-picker.component.html',
    styleUrls: ['./range-picker.component.css'],
    standalone: false
})
export class RangePickerComponent implements OnInit, OnDestroy, FormField {
    @Input() id;
    @Input() label;
    @Input() formId;
    @Input() timezone;
    @Input() startDatePlaceholder = 'Range_Picker.Enter_Start_Date';
    @Input() endDatePlaceholder = 'Range_Picker.Enter_End_Date';
    @Input() minDate;
    @Input() startDateTime: string;
    @Input() endDateTime: string;
    @Input() required = false;
    @Input() dateLabel = 'Range_Picker.From';
    @Input() endDateLabel = 'Range_Picker.To';
    @Input() dateTimeFormat = 'yyyy-MM-dd HH:mm';
    requiredError = 'General.Required';
    @Output() startDateTimeChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() endDateTimeChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() rangeChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor(private formManagementService: FormManagementService) {
    }

    ngOnInit(): void {
        this.formManagementService.subscribe(this.formId, this);
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }

    inputChange(event) {
        this.startDateTime = event.detail.datetime;
        this.endDateTime = event.detail.endDatetime;
        this.startDateTimeChange.emit(this.startDateTime);
        this.endDateTimeChange.emit(this.endDateTime);
        this.rangeChanged.emit(event.detail.date + ',' + event.detail.endDate);
        this.validate();
    }

    validate(): boolean {
        return this.required ? (!!this.startDateTime && !!this.endDateTime) : true;
    }
}
