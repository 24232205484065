import { HttpParams } from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Observable} from 'rxjs';
import {OrderManagementService} from '../../services/order-service/order-management.service';
import {TicketTypeService} from '../../services/ticket-service/ticket.service';
import {VenueManagementService} from '../../services/venue-service/venue-management.service';
import {DialogConfirmationComponent} from '../dialogs/dialog-confirmation/dialog-confirmation.component';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {OrderService} from '../../services/order-service/order.service';
import {EventService} from '../../services/event-service/event.service';
import {NotificationService} from '../../services/notification-service/notification.service';
import {CmTranslationService} from '../../../core/services/cm-translation.service';
import {map} from 'rxjs/operators';
import {SidebarService} from '../../services/sidebar/sidebar.service';
import {MoveOrderService} from '../../services/move-order/move-order.service';
import {SubscriptionType} from '../../models/subscription-type.class';
import {EventCategory} from '../../models/event-category.class';
import {EventTicket} from '../../models/event-ticket.class';
import {VenueSectionSeat} from '../../models/venue-section-seat.class';
import {VenueSection} from '../../models/venue-section.class';
import {TicketType} from '../../models/ticket-type.class';
import {Order} from '../../models/order.class';
import {Event} from '../../models/event.class';
import {getTechnicalLink} from '../../../core/helpers/platform-helper';
import {OrderItemRequestHelper} from '../../helpers/request/order-item-request-helper';
import {ProductGroupType} from '../../enums/order/product-group-type';
import {SeatStatus} from '../../enums/seatmap/seat-status';

@Component({
    selector: 'app-venue-section',
    templateUrl: './venue-section.component.html',
    styleUrls: ['./venue-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VenueSectionComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('svg') svg: ElementRef;
    @Input() event: Event;
    @Input() ticketTypes: TicketType[];
    @Input() eventCategory: EventCategory;
    @Input() subscriptionType: SubscriptionType;
    @Input() venueSection: VenueSection;
    @Input() showDetails = true;
    order$: Observable<Order>;
    selectedVenueSectionSeat = null;
    selectedSectionSeats = [];
    selectedEventTicket: EventTicket;
    venueSectionSeatRows = [];
    forceSubscription = false;
    xMax = 0;
    yMax = 0;
    xMin = 0;
    yMin = 0;

    isMultipleSubsections;

    activeTool = 'SELECT';
    originalViewBox: string;

    // Drag to select
    isSelecting = false;
    selectRect: SVGRectElement;
    selectRectStartPositionUntransformed: { x: number, y: number };
    selectRectStartPosition: { x: number, y: number };
    transformedSeatPositions: { x: number, y: number }[];

    // Drag to pan
    isPanning = false;
    panningStartPosition: { x: number, y: number };

    translations = {
        seat: 'Seat',
        row: 'Row'
    };

    eventTickets: EventTicket[] = [];

    constructor(private changeDetector: ChangeDetectorRef,
                private venueManagementService: VenueManagementService,
                private dialogService: DialogService,
                private orderService: OrderService,
                private ticketService: TicketTypeService,
                private orderManagementService: OrderManagementService,
                private renderer: Renderer2,
                private eventService: EventService,
                private notificationService: NotificationService,
                private cmTranslationService: CmTranslationService,
                private sidebarService: SidebarService,
                private moveOrderService: MoveOrderService) {
    }

    ngOnInit() {
        if (localStorage.getItem(`seatedDashboard.${getTechnicalLink()}.orderId`)) {
            this.orderManagementService.getOrder(localStorage.getItem(`seatedDashboard.${getTechnicalLink()}.orderId`), null, true);
        }

        this.order$ = this.orderManagementService.orderSubject.asObservable();
        this.order$.subscribe(() => {
            this.changeDetector.markForCheck();
        });

        this.venueManagementService.venueSectionSubject.subscribe(() => {
            this.changeDetector.markForCheck();
        });

        if (this.event && this.venueSection && this.venueSection.seats && this.venueSection.seats.length > 1) {
            const eventTicketHttpParams = new HttpParams()
                .set('eventTicket[venueSectionIds]', this.venueSection.id)
                .set('eventTicket[eventIds]', this.event.id)
                .set('eventTicket[customer]', 'true')
                .set('eventTicket[status]', 'ISSUED,RESERVED,RESELL_AVAILABLE,RESELL_RESERVED,RESELL_SOLD')
                .set('take', -1);
            this.eventService.getEventTickets(eventTicketHttpParams).pipe(
                map(response => response.body)
            ).subscribe(eventTickets => this.eventTickets = eventTickets);
        }
    }

    ngAfterViewInit() {
        if (this.svg) {
            // add some space at top and bottom of svg
            this.renderer.setStyle(this.svg.nativeElement, 'min-height', this.svg.nativeElement.getBoundingClientRect().height + 50);
        }

        this.translations.seat = this.cmTranslationService.getPhraseForLanguage('General.Seat');
        this.translations.row = this.cmTranslationService.getPhraseForLanguage('General.Row');

        this.changeDetector.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        setTimeout(() => {
            if (this.venueSection.seats) {
                const firstSubsection = this.venueSection.seats[0].venueSectionSubsectionId;
                this.isMultipleSubsections = false;

                for (const venueSectionSeat of this.venueSection.seats) {
                    if (venueSectionSeat.venueSectionSubsectionId !== firstSubsection) {
                        this.isMultipleSubsections = true;
                    }
                    let rowInfo;
                    if (venueSectionSeat.venueSectionSubsectionId) {
                        rowInfo = {
                            startX: '20',
                            endX: `${((this.xMax - this.xMin + 20) * 3.2) + 210}`,
                            startY: `${(venueSectionSeat.y - this.yMin + 20) * 3.2}`,
                            endY: `${((venueSectionSeat.y - this.yMin + 20) * 3.2)}`,
                            row: venueSectionSeat.row
                        };
                    } else {
                        rowInfo = {
                            startX: '20',
                            endX: `${((this.xMax + 4.5) * 11) + 120}`,
                            startY: `${((venueSectionSeat.y + 0.5) - this.yMin + 5.4) * 11}`,
                            endY: `${(((venueSectionSeat.y + 0.5) - this.yMin + 5.4) * 11)}`,
                            row: venueSectionSeat.row
                        };
                    }
                    const exists = this.venueSectionSeatRows.find(section => {
                        if (section.row === rowInfo.row) {
                            return section;
                        }
                    });

                    if (!exists) {
                        this.venueSectionSeatRows.push(rowInfo);
                    }
                }
            }

            this.changeDetector.markForCheck();
        });
    }

    removeSelectRectElement(e) {
        if (this.isSelecting) {
            this.renderer.removeChild(this.svg.nativeElement, this.selectRect);
            this.isSelecting = false;
        }
    }

    handleSVGMouseDown(event) {
        this.selectedSectionSeats = [];
        const matrixTransform = this.getMatrixTransform(event);

        if (this.activeTool === 'SELECT') {
            this.isSelecting = true;
            const svgClientBoundingRect = this.svg.nativeElement.getBoundingClientRect();

            this.selectRectStartPosition = {
                x: matrixTransform.x - svgClientBoundingRect.x,
                y: matrixTransform.y - svgClientBoundingRect.y
            };

            this.selectRectStartPositionUntransformed = {
                x: event.clientX,
                y: event.clientY
            };

            this.selectRect = this.renderer.createElement('rect', 'svg');
            this.renderer.addClass(this.selectRect, 'select-rect');
            this.renderer.setAttribute(this.selectRect, 'x', `${matrixTransform.x}`);
            this.renderer.setAttribute(this.selectRect, 'y', `${matrixTransform.y}`);
            this.renderer.setAttribute(this.selectRect, 'vector-effect', 'non-scaling-stroke');
            this.renderer.appendChild(this.svg.nativeElement, this.selectRect);

            this.transformedSeatPositions = [];

            document.querySelectorAll('.section-path').forEach(path => {
                const pathPosition = path.getBoundingClientRect();
                this.transformedSeatPositions.push({
                    x: pathPosition.left + (pathPosition.width / 2),
                    y: pathPosition.top + (pathPosition.height / 2)
                });
            });
        }

        if (this.activeTool === 'PAN' && !this.isSelecting) {
            this.isPanning = true;
            this.panningStartPosition = this.getMatrixTransform(event);
        }
    }

    handleSVGMouseMove(event) {
        const matrixTransform = this.getMatrixTransform(event);
        const svgBoundingClientRect = this.svg.nativeElement.getBoundingClientRect();

        if (this.isSelecting) {
            const selectRectBoundingClientRect = this.selectRect.getBoundingClientRect();
            const selectedSeats: VenueSectionSeat[] = [];
            const size = {
                width: Math.abs(matrixTransform.x - this.selectRectStartPosition.x - svgBoundingClientRect.x),
                height: Math.abs(matrixTransform.y - this.selectRectStartPosition.y - svgBoundingClientRect.y)
            };

            this.renderer.setAttribute(this.selectRect, 'width', `${size.width}`);
            this.renderer.setAttribute(this.selectRect, 'height', `${size.height}`);

            if (matrixTransform.x - svgBoundingClientRect.left < this.selectRectStartPosition.x) {
                this.renderer.setAttribute(this.selectRect, 'x', matrixTransform.x);
            }

            if (matrixTransform.y - svgBoundingClientRect.top < this.selectRectStartPosition.y) {
                this.renderer.setAttribute(this.selectRect, 'y', matrixTransform.y);
            }

            this.transformedSeatPositions.forEach((seatPosition, i) => {

                if (
                    seatPosition.x > selectRectBoundingClientRect.left &&
                    seatPosition.x < selectRectBoundingClientRect.left + selectRectBoundingClientRect.width &&
                    seatPosition.y > selectRectBoundingClientRect.top &&
                    seatPosition.y < selectRectBoundingClientRect.top + selectRectBoundingClientRect.height
                ) {
                    selectedSeats.push(this.venueSection.seats[i]);
                }
            });

            this.selectedSectionSeats = selectedSeats;
        }

        if (this.isPanning) {
            const viewBoxSplit = this.svg.nativeElement.getAttribute('viewBox').split(' ');

            const viewBox = {
                x: viewBoxSplit[0],
                y: viewBoxSplit[1],
                width: viewBoxSplit[2],
                height: viewBoxSplit[3],
            };

            viewBox.x = viewBox.x - (matrixTransform.x - this.panningStartPosition.x);
            viewBox.y = viewBox.y - (matrixTransform.y - this.panningStartPosition.y);

            this.renderer.setAttribute(this.svg.nativeElement, 'viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`);
        }
    }

    handleSVGMouseUp() {
        this.dragSelectSeat(this.selectedSectionSeats);
        this.selectedSectionSeats = [];

        this.isSelecting = false;
        this.isPanning = false;
    }

    dragSelectSeat(venueSectionSeats: Array<any>) {
        const request = [];
        let params = new HttpParams()
            .set('venueSectionSeat[available]', 'true')
            .set('venueSectionSeat[details]', 'true')
            .set('eventTicket[customer]', 'true')
            .set('eventTicket[order]', 'true')
            .set('depth', '3');

        if (this.eventCategory) {
            params = params.set('scope[eventCategoryId]', this.eventCategory.id);
        } else if (this.subscriptionType) {
            params = params.set('scope[subscriptionTypeId]', this.subscriptionType.id);
        } else if (this.event) {
            params = params.set('scope[eventId]', this.event.id);
        }

        // Fix drag to select bug
        for (const venueSectionSeat of venueSectionSeats) {
            if (venueSectionSeat.capacity === 0) {
                return false;
            }
            const orderItem = this.seatInCurrentOrder(venueSectionSeat);

            if (orderItem) {
                // Delete order item
                // TODO: Add fail check
                venueSectionSeat.available = 1;
                venueSectionSeat.status = SeatStatus.AVAILABLE;
                this.orderManagementService.deleteOrderItem(orderItem.id, true, () => {
                    this.venueManagementService.fetchVenueSectionSeats([orderItem]);
                    this.changeDetector.markForCheck();
                });
            } else if (venueSectionSeat.available > 0 && venueSectionSeat.status !== SeatStatus.UNAVAILABLE) {
                // Move Seat
                if (this.orderManagementService.moveOrderItem) {
                    let body;
                    if (this.orderManagementService.moveOrderItem.ticket) {
                        body = {
                            move: {
                                venueSectionSeatId: venueSectionSeat.id,
                            }
                        };
                    } else if (this.orderManagementService.moveOrderItem.subscription) {
                        body = {
                            move: {
                                venueSectionSeatId: venueSectionSeat.id,
                            }
                        };
                    }

                    this.orderManagementService.updateOrderItem(this.orderManagementService.moveOrderItem.id, body, true, () => {
                        this.venueManagementService.fetchVenueSectionSeats();
                        this.changeDetector.markForCheck();
                    });
                    break;
                }

                // Add order item
                if (this.event) {
                    // TODO: Add fail check
                    venueSectionSeat.available = 0;
                    venueSectionSeat.status = SeatStatus.UNAVAILABLE;
                    this.venueManagementService.addSelectedSeat(venueSectionSeat);
                    request.push(
                        OrderItemRequestHelper.getRequestBody(
                            ProductGroupType.EVENT_TICKET,
                            this.event.id,
                            venueSectionSeat.id
                        )
                    );
                } else if (this.subscriptionType) {
                    // TODO: Add fail check
                    venueSectionSeat.available = 0;
                    venueSectionSeat.status = SeatStatus.UNAVAILABLE;
                    this.venueManagementService.addSelectedSeat(venueSectionSeat);
                    request.push(
                        OrderItemRequestHelper.getRequestBody(
                            ProductGroupType.SUBSCRIPTION,
                            this.subscriptionType.id,
                            venueSectionSeat.id
                        )
                    );
                }
            } else if (venueSectionSeat.details.subscriptionId === null && this.subscriptionType && venueSectionSeat.status !== SeatStatus.UNAVAILABLE) {
                if (this.forceSubscription) {
                    venueSectionSeat.status = SeatStatus.UNAVAILABLE;
                    this.venueManagementService.addSelectedSeat(venueSectionSeat);
                    this.orderManagementService.addOrderItem(
                        OrderItemRequestHelper.getRequestBody(
                            ProductGroupType.SUBSCRIPTION,
                            this.subscriptionType.id,
                            venueSectionSeat.id
                        ),
                        true,
                        new HttpParams()
                            .set('subscription[force]', 'true')
                            .set('subscription[eventTicket][available]', 'true')
                            .set('venueSectionSeat[sectionName]', 'true')
                    );
                } else {
                    this.dialogService.createDialogComponent(DialogConfirmationComponent, {
                        titleText: {key: 'Dialog.Sold_Seat_Title'},
                        bodyText: {key: 'Dialog.Sold_Seat_Body'},
                        cancelText: 'Dialog.Cancel',
                        confirmText: 'Dialog.Ok'
                    }, 'subscription-force');

                    this.dialogService.emitDataSubject.subscribe(response => {
                        if (!response.cancelled) {
                            this.forceSubscription = true;
                            this.dragSelectSeat(venueSectionSeats);
                        }
                    });
                }
            } else if (venueSectionSeat.status !== SeatStatus.UNAVAILABLE) {
                // Get seat info
                this.selectedSectionSeats = [];
                this.selectedEventTicket = null;
                this.selectedVenueSectionSeat = venueSectionSeat;

                for (const seat of this.venueSection.seats) {
                    if (seat.details.customerId === venueSectionSeat.details.customerId && venueSectionSeat.details.customerId !== null) {
                        this.selectedSectionSeats.push(seat);
                    }
                }

                if (venueSectionSeat.eventTickets && venueSectionSeat.eventTickets.length > 0) {
                    this.selectVenueSectionSeat(venueSectionSeat);
                } else {
                    params = params.set('venueSectionSeat[eventTicket]', 'true').set('eventTicket[customer]', 'true');
                    this.venueManagementService.getVenueSectionSeat(venueSectionSeat.id, params, (resultVenueSectionSeat) => {
                        this.selectVenueSectionSeat(resultVenueSectionSeat);
                    });
                }
            }
        }

        if (request.length > 0) {
            this.orderManagementService.addOrderItems(request, true, () => {
                this.changeDetector.markForCheck();
            });
        }
    }

    selectVenueSectionSeat(venueSectionSeat: VenueSectionSeat) {
        if (venueSectionSeat.eventTickets[0]) {
            this.selectedEventTicket = venueSectionSeat.eventTickets[0];

            this.sidebarService.openSidebar(`orders/${this.selectedEventTicket.order.id}`);
        }

        if (venueSectionSeat.details.status === 'RESERVED') {
            if (this.selectedEventTicket.orderItemId) {
                this.orderManagementService.getOrderByOrderItemId(this.selectedEventTicket.orderItemId, () => this.orderManagementService.expandDialog(true));
            } else if (this.selectedEventTicket.subscriptionId) {
                this.orderManagementService.getOrderBySubscriptionId(this.selectedEventTicket.subscriptionId, () => this.orderManagementService.expandDialog(true));
            }
        }
    }

    seatInCurrentOrder(venueSectionSeat: VenueSectionSeat) {
        if (this.orderManagementService.order) {
            for (const groupedItem of this.orderManagementService.order.groupedOrderItems) {
                if ((this.event && this.event.id === groupedItem.id)
                    || (this.subscriptionType && this.subscriptionType.id === groupedItem.id)) {
                    for (const orderItem of groupedItem.items) {
                        if ((orderItem.ticket && orderItem.ticket.seat.id === venueSectionSeat.id) ||
                            (orderItem.subscription && orderItem.subscription.seat.id === venueSectionSeat.id)) {
                            return orderItem;
                        }
                    }
                }
            }
        }

        return false;
    }

    getVenueSectionSeatStatus(venueSectionSeat: VenueSectionSeat) {
        const classes = [];

        if (this.seatInCurrentOrder(venueSectionSeat) !== null && this.seatInCurrentOrder(venueSectionSeat) !== false) {
            classes.push(['active-order']);
        } else if (venueSectionSeat.details.status) {
            classes.push(venueSectionSeat.details.status.toLowerCase());
        }

        if (venueSectionSeat.available > 0) {
            classes.push('available');
        }

        if (venueSectionSeat.details.subscriptionId) {
            classes.push('subscription');
        }

        for (const selectedInfoVenueSectionSeat of this.selectedSectionSeats) {
            if (selectedInfoVenueSectionSeat.id === venueSectionSeat.id) {
                classes.push('selected');
            }
        }

        if (this.selectedVenueSectionSeat?.id === venueSectionSeat.id) {
            classes.push('extra-selected');
        }

        classes.push('section-path');

        return Array.from(new Set(classes)).join(' ');
    }

    getViewBox(venueSectionSeats: VenueSectionSeat[]): string {
        let containsVenueDesignedSection = false;

        venueSectionSeats.map((venueSectionSeat: any) => {
            if (venueSectionSeat.x > this.xMax) {
                this.xMax = venueSectionSeat.x;
            }

            if (venueSectionSeat.y > this.yMax) {
                this.yMax = venueSectionSeat.y;
            }

            if (venueSectionSeat.venueSectionSubsectionId) {
                containsVenueDesignedSection = true;
            }
        });

        this.xMin = this.xMax;
        this.yMin = this.yMax;

        venueSectionSeats.map((venueSectionSeat: any) => {

            if (venueSectionSeat.x < this.xMin) {
                this.xMin = venueSectionSeat.x;
            }

            if (venueSectionSeat.y < this.yMin) {
                this.yMin = venueSectionSeat.y;
            }
        });

        let x;
        let y;

        if (containsVenueDesignedSection) {
            x = ((this.xMax - this.xMin + 32) * 3.2) + 200;
            y = (this.yMax - this.yMin + 32) * 3.2;
        } else {
            x = ((this.xMax - this.xMin + 10) * 11) + 200;
            y = (this.yMax - this.yMin + 10) * 11;
        }

        const viewBox = `0 0 ${x} ${y}`;
        this.originalViewBox = viewBox;

        return viewBox;
    }

    getTransform(seat: VenueSectionSeat) {
        if (seat.venueSectionSubsectionId) {
            return `translate(${((seat.x - this.xMin) * 3.2) + 100}, ${(seat.y - this.yMin) * 3.2})`;
        } else {
            return `translate(${((seat.x - this.xMin) * 11) + 100}, ${(seat.y - this.yMin) * 11})`;
        }
    }

    getTextTransform(seat: VenueSectionSeat) {
        if (seat.venueSectionSubsectionId) {
            return `translate(${((seat.x - this.xMin + 16) * 3.2) + 100}, ${(seat.y - this.yMin + 18) * 3.2})`;
        } else {
            return `translate(${((seat.x - this.xMin + 4.5) * 11) + 100}, ${(seat.y - this.yMin + 5.4) * 11})`;
        }
    }

    getMatrixTransform(e) {
        const screenCTM = this.svg.nativeElement.getScreenCTM();
        const svgPoint = this.svg.nativeElement.createSVGPoint();

        svgPoint.x = e.clientX;
        svgPoint.y = e.clientY;

        return svgPoint.matrixTransform(screenCTM.inverse());
    }

    zoom(type: string) {
        const viewBoxSplit = this.svg.nativeElement.getAttribute('viewBox').split(' ');

        let multiplier = 1;
        if (type === 'IN') {
            multiplier = 0.8;
        }

        if (type === 'OUT') {
            multiplier = 1.2;
        }

        const viewBox = {
            x: viewBoxSplit[0] * multiplier,
            y: viewBoxSplit[1] * multiplier,
            width: viewBoxSplit[2] * multiplier,
            height: viewBoxSplit[3] * multiplier,
        };

        viewBox.width += (1 - multiplier) * (viewBox.x / 2);
        viewBox.height += (1 - multiplier) * (viewBox.y / 2);

        this.renderer.setAttribute(this.svg.nativeElement, 'viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`);
    }

    resetViewBox() {
        this.renderer.setAttribute(this.svg.nativeElement, 'viewBox', `${this.originalViewBox}`);
    }

    getSeatInfo(seat: VenueSectionSeat) {
        if (this.isTouchDevice()) {
            return;
        }

        let seatInfo = '';

        const eventTicket = this.eventTickets.find(eventTicketI => eventTicketI.seat.id === seat.id);
        if (eventTicket) {
            seatInfo += `<span class="tooltip-name">${eventTicket.customer.getName()}</span>`;
        }

        seatInfo += `<span class="tooltip-body">${this.translations.row} ${seat.row}, ${this.translations.seat} ${seat.seat}</span>`;

        return seatInfo;
    }

    isTouchDevice() {
        return 'ontouchstart' in window        // works on most browsers
            || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    }

    showTooltip(evt, text) {
        const tooltip = (document.getElementById('tooltip') as HTMLElement);
        tooltip.innerHTML = text;
        tooltip.style.display = 'block';
        tooltip.style.left = evt.pageX + 10 + 'px';
        tooltip.style.top = evt.pageY + 10 + 'px';
    }

    hideTooltip() {
        const tooltip = (document.getElementById('tooltip') as HTMLElement);
        tooltip.style.display = 'none';
    }
}
