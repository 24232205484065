import {
    AfterViewInit,
    Component, DEFAULT_CURRENCY_CODE,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Component({
    selector: 'cm-ticketing-data-table-row-data',
    templateUrl: './data-table-row-data.component.html',
    styleUrls: ['./data-table-row-data.component.scss'],
    standalone: false
})
export class DataTableRowDataComponent implements OnInit, AfterViewInit {
    @ViewChild('tableData') tableData: ElementRef;
    @Output() checkboxChangedEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() actionClickedEmitter: EventEmitter<any> = new EventEmitter();
    @Input() value;
    @Input() options;
    @Input() selected = false;
    public displayValue;
    public alignRight = false;

    public type: 'DATA' | 'ACTION' | 'CHECKBOX' | 'PROGRESS' = 'DATA';

    constructor(private currencyPipe: CurrencyPipe, @Inject(DEFAULT_CURRENCY_CODE) private currencyId: string) {
    }

    ngOnInit(): void {
        this.displayValue = this.value ? this.value : '';
        for (const option of this.options) {
            if (option.name === 'data-align-right') {
                this.alignRight = true;
            } else if (option.name === 'data-currency') {
                const splittedValue = option.value.split('|');
                this.displayValue = `<div class="currency">${this.currencyPipe.transform(this.value,
                    splittedValue[0] ? splittedValue[0] : this.currencyId, 'symbol-narrow', null,
                    splittedValue[1] ? splittedValue[1] : 'nl-NL')}</div>`;
            } else if (option.name === 'data-percentage') {
                const percentageValue = Math.floor(this.value);
                this.displayValue = `<div class="background-bar" style="width: calc(100% - 70px);min-width: 16px;overflow: hidden;position: absolute;top: 0;height: 12px;left: 16px;border-radius: 8px">
                <div class="overlay-bar" style="background: var(--default-background);width: ${100 - percentageValue}%;position: absolute;z-index: 9999;
                height: 25px;border-top-right-radius: 8px;border-bottom-right-radius: 8px;right: 0;"></div>
                </div>
                <div style="z-index: 9999;position: absolute;top: -6px;right: 16px;font-size: 12px;">${Math.floor(percentageValue)}%</div>


                <div class="background-bar-small" style="background-color: var(${
                    percentageValue === 100 ? '--cl-success-color' :
                        percentageValue > 66 ? '--default-hover-background' :
                            percentageValue > 33 ? '--default-active-background' :
                                '--selected-background'
                });
                width: 16px;overflow: hidden;position: absolute;top: 0;height: 16px;
                right: ${percentageValue === 100 ? 60 : 55}px;
                border-radius: 8px">
                </div>`;
            }
        }

        this.type = this.getType();
    }

    ngAfterViewInit(): void {
        for (const option of this.options) {
            if (!this.tableData) {
                break;
            }
            this.tableData.nativeElement.setAttribute(option.name, option.value);
            if (option.name === 'data-action') {
                this.tableData.nativeElement.addEventListener('click', ((event) => {
                    if (event) {
                        event.stopPropagation();
                    }
                }));
            }
        }
    }

    checkBoxClicked(event): void {
        event.stopPropagation();
    }

    actionClicked(event): void {
        this.actionClickedEmitter.emit(event.target.id);
    }

    isCheckbox(): boolean {
        return this.options?.some((option => option.name === 'data-checkbox'));
    }

    isAction(): boolean {
        return this.options?.some((option => option.name === 'data-action'));
    }

    isProgress(): boolean {
        return this.options?.some((option => option.name === 'data-progress'));
    }

    isLoading(): boolean {
        return this.options?.some((option => option.name === 'data-is-loading'));
    }

    onCheckboxChange(event: any): void {
        this.checkboxChangedEmitter.emit(event);
    }

    private getType(): 'DATA' | 'ACTION' | 'CHECKBOX' | 'PROGRESS' {
        if (this.isAction()) {
            return 'ACTION';
        }

        if (this.isCheckbox()) {
            return 'CHECKBOX';
        }

        if (this.isProgress()) {
            return 'PROGRESS';
        }

        return 'DATA';
    }

}
