import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AccountNotificationService} from '../../services/sse/observers/account-notification.service';
import {Notification} from '../../models/notification.class';
import {Observable, Subject} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

@Component({
    selector: 'app-progress-notification',
    templateUrl: './progress-notification.component.html',
    styleUrls: ['./progress-notification.component.scss'],
    host: {
        '[class.display]': 'this.display'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProgressNotificationComponent implements OnInit {

    public progress$: Observable<any>;

    private notifications$ = this.accountNotificationService.notifications$;
    private progressStream: Subject<{
        subject: string,
        progress: number,
        total: number,
        current: number
    }> = new Subject<{
        subject: 'Action in progress',
        progress: 0,
        total: 0,
        current: 0
    }>();

    private total = 0;
    private current = 0;

    private display = false;

    constructor(private accountNotificationService: AccountNotificationService, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.progress$ = this.progressStream.asObservable().pipe(
            tap(() => this.changeDetectorRef.detectChanges())
        );

        this.notifications$.pipe(
            map((notifications: Notification[]) => {
                return notifications.filter(notification => notification.processId === 'BULK_ACTION');
            }),
            filter((notifications: Notification[]) => notifications.length > 0)
        ).subscribe((notifications: Notification[]) => {
            this.display = true;

            const notification = notifications[0];
            this.total = notification.content.total;
            this.current++;

            if (this.current > this.total) {
                this.current = this.total;
            }

            let percentage = (this.current / this.total) * 100;

            if (percentage > 100) {
                percentage = 100;
            }

            this.progressStream.next({
                subject: 'Action in progress',
                progress: percentage,
                total: this.total,
                current: this.current
            });

            if (percentage >= 100) {
                this.handleCompleteAction();
            }
        });
    }

    private handleCompleteAction() {
        this.progressStream.next({
            subject: 'Action complete!',
            progress: 100,
            total: this.total,
            current: this.total
        });

        setTimeout(() => {
            this.display = false;
        }, 2000);
    }

}
