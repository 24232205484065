import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';

@Component({
    selector: 'app-form-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    standalone: false
})
export class RadioComponent implements OnInit, OnDestroy, FormField {
    @Input() formId;
    @Input() id;
    @Input() label;
    @Input() hint;
    @Input() value;
    @Input() disabled;
    @Input() readonly;
    @Input() required;
    @Input() options;
    @Input() optionLabelTranslate                = false;
    @Input() optionLabel                         = 'label';
    @Input() optionValue                         = 'value';
    @Input() listHorizontal                      = false;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    isValid                                      = true;
    errorMessage: string;

    constructor(private formManagementService: FormManagementService) {

    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);
    }

    inputChange() {
        this.valueChange.emit(this.value);
        this.validate();
    }

    validate() {
        this.isValid      = true;
        this.errorMessage = '';

        if (this.required && !this.value) {
            this.errorMessage = 'General.Required';
            this.isValid      = false;
        }

        return this.isValid;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }

}
