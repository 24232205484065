import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.css'],
    standalone: false
})
export class PaginationComponent implements OnChanges {

    @Input()
    total: any;

    @Input()
    skip: any;

    @Input()
    take: any;

    @Output()
    paginationChange: EventEmitter<any> = new EventEmitter<any>();

    currentPageStartAt: number;
    currentPageEndAt: number;

    ngOnChanges(changes: SimpleChanges): void {
        this.total = parseInt(this.total, 10);
        this.skip  = parseInt(this.skip, 10);
        this.take  = parseInt(this.take, 10);

        this.currentPageStartAt = this.skip;
        this.currentPageEndAt   = this.skip + this.take;

        if (this.currentPageEndAt > this.total) {
            this.currentPageEndAt = this.total;
        }
    }

    onPaginationChange(paginationEvent: any): void {
        const paginationEventKeys = Object.keys(paginationEvent);

        if (paginationEventKeys.includes('skip')) {
            this.skip = parseInt(paginationEvent.skip, 10);
        }

        if (paginationEventKeys.includes('take')) {
            this.take = parseInt(paginationEvent.take, 10);
        }

        this.emitPaginationChange();
    }

    onPreviousPageClick() {
        if ((this.skip - this.take) >= 0) {
            this.skip -= this.take;
            this.emitPaginationChange();
        }

    }

    onNextPageClick() {
        if ((this.skip + this.take) < this.total) {
            this.skip += this.take;
            this.emitPaginationChange();
        }
    }

    private emitPaginationChange(): void {
        this.paginationChange.emit({
                                       total: parseInt(this.total, 10),
                                       skip:  parseInt(this.skip, 10),
                                       take:  parseInt(this.take, 10)
                                   });
    }

}
