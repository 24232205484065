import {Component, OnInit} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {Observable} from 'rxjs';
import {Shop} from '../../../models/shop.class';
import {ShopManagementService} from '../../../services/shop-management-service/shop-management.service';
import { UserManagementService } from '../../../services/user-management-service/user-management.service';

@Component({
    selector: 'app-dialog-export-customer-list',
    templateUrl: './dialog-export-customer-list.component.html',
    styleUrls: ['./dialog-export-customer-list.component.css'],
    standalone: false
})
export class DialogExportCustomerListComponent extends DialogBase<IInput, IOutput> implements OnInit {
    public titleText: any;
    public bodyText: any;
    public confirmText: string;
    public extraData: any;

    includeCustomFields: boolean;
    includeAcceptedConsents: boolean;
    personalLink: boolean;
    personalLinkShopId: string;
    customerIban: boolean;

    isCmEmployee: boolean;

    shops$: Observable<Shop[]>;

    constructor(dialogService: DialogService, private shopManagementService: ShopManagementService, private userManagementService: UserManagementService) {
        super(dialogService);
    }

    ngOnInit(): void {
        this.shops$ = this.shopManagementService.list$ as Observable<Shop[]>;

        this.userManagementService.isCmEmployee().subscribe(isCmEmployee => {
            this.isCmEmployee = isCmEmployee;
        });
    }

    setModal(data: IInput) {
        this.titleText = data.titleText;
        this.bodyText = data.bodyText;
        this.confirmText = data.confirmText;
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: {
                personalLink: this.personalLink,
                personalLinkShopId: this.personalLinkShopId,
                customerIban: this.customerIban,
                includeCustomFields: this.includeCustomFields,
                includeAcceptedConsents: this.includeAcceptedConsents
            }
        });
    }

    public cancel() {
        super.cancel();
    }

    onCustomFieldsChanged(enabled: boolean) {
        this.includeCustomFields = enabled;
    }

    onAcceptedConsentsChanged(enabled: boolean) {
        this.includeAcceptedConsents = enabled;
    }

    onPersonalLinkChanged(enabled: boolean) {
        this.personalLink = enabled;

        if (!enabled) {
            this.personalLinkShopId = null;
        }
    }

    onCustomerIbanChanged(enabled: boolean): void {
        this.customerIban = enabled;
    }

}
