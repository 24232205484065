import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';

@Component({
    selector: 'app-form-input-color-picker',
    templateUrl: './input-color-picker.component.html',
    styleUrls: ['./input-color-picker.component.css'],
    standalone: false
})
export class InputColorPickerComponent implements OnInit, OnDestroy, FormField {
    @Input() formId;
    @Input() id: string;
    @Input() label;
    @Input() value;
    @Input() required;
    @Input() errorMessage;
    @Input() isValid = true;
    @Input() hideAlpha = false;
    @Input() format: 'HEX' | 'RGBA' = 'RGBA';
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    hex: string;
    alpha: number;

    constructor(private formManagementService: FormManagementService) {

    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);

        this.hex = this.value?.toUpperCase();
        this.convertRGBA(this.value);
    }

    inputChange() {
        this.valueChange.emit(this.value);
        this.validate();
    }

    validate() {
        this.isValid = true;
        return this.isValid;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }

    onColorChange(colorChangeEvent: any) {
        if (this.format === 'HEX') {
            this.value = colorChangeEvent.hex;
        }

        if (this.format === 'RGBA') {
            this.value = `rgba(${colorChangeEvent.rgba.join(', ')})`;
        }

        this.inputChange();
    }

    convertRGBA(rgba) {
        if (rgba) {
            let match = rgba.match(/rgba\(\s*(-?\d+|-?\d*\.\d+(?=%))(%?)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*.\d+)\s*\)/);
            if (match) {
                match = match.filter(i => i !== '');

                let r = (+match[1]).toString(16);
                let g = (+match[2]).toString(16);
                let b = (+match[3]).toString(16);

                if (r.length === 1) {
                    r = '0' + r;
                }
                if (g.length === 1) {
                    g = '0' + g;
                }
                if (b.length === 1) {
                    b = '0' + b;
                }

                this.hex = '#' + r + g + b;

                const a = parseFloat(match[4]) * 100;
                this.alpha = a;
            }
        }
    }
}
