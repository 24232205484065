import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-setting-checkbox-picker',
    templateUrl: './setting-checkbox-picker.component.html',
    styleUrls: ['./setting-checkbox-picker.component.css'],
    standalone: false
})
export class SettingCheckboxPickerComponent implements OnInit {
    @Input() categories: any[];
    @Input() categoryKey: string;
    @Input() categoryName: string;
    @Input() optionsKey: string;
    @Input() optionKey: string;
    @Input() optionName: string;
    @Input() optionSubtitle: string;
    @Input() columns = 2;
    @Input() selectedOptions: string[] = [];
    @Output() selectedOptionsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() selectionChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

    constructor() {
    }

    ngOnInit(): void {

    }

    updateSelection(object: any) {
        const index = this.selectedOptions.findIndex(id => {
            return id === object[this.optionKey];
        }, 0);
        if (index >= 0) {
            this.selectedOptions.splice(index, 1);
        } else {
            this.selectedOptions.push(object[this.optionKey]);
        }

        this.selectionChanged.emit(this.selectedOptions);
    }

    inSelection(object: any) {
        return this.selectedOptions.findIndex(id => {
            return id === object[this.optionKey];
        }) >= 0;
    }

    onSelectAllClicked(array) {
        for (const object of array) {
            const index = this.selectedOptions.findIndex(id => {
                return id === object[this.optionKey];
            }, 0);
            if (index >= 0) {
                this.selectedOptions.splice(index, 1);
            }
            this.updateSelection(object);
        }
    }

    onDeselectAllClicked(array) {
        for (const object of array) {
            const index = this.selectedOptions.findIndex(id => {
                return id === object[this.optionKey];
            }, 0);
            if (index >= 0) {
                this.selectedOptions.splice(index, 1);
            }
        }

        this.selectionChanged.emit(this.selectedOptions);
    }

}
