import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-widget',
    templateUrl: './widget.component.html',
    styleUrl: './widget.component.scss',
    standalone: false
})
export class WidgetComponent {
  @Input() title: string;
  @Input() body: string;
}
