import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Event} from '../../models/event.class';
import {SubscriptionType} from '../../models/subscription-type.class';
import {Shop} from '../../models/shop.class';
import {CustomerTag} from '../../models/customer-tag.class';
import {EventService} from '../../services/event-service/event.service';
import {SubscriptionTypeService} from '../../services/subscription-type-service/subscription-type.service';
import {ShopService} from '../../services/shop-service/shop.service';
import {CustomerTagService} from '../../services/customer-tag-service/customer-tag.service';
import {takeUntil, tap} from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import {mapOrderDistributionSetting, OrderDistributionSetting} from '../../models/order-distribution-setting';
import {OrderDistributionSettingService} from '../../services/order-distribution-setting.service';
import {NotificationService} from '../../services/notification-service/notification.service';
import {VenueService} from '../../services/venue-service/venue.service';
import {VenueSectionGroup} from '../../models/venue-section-group.class';
import {ProductType} from '../../models/product-type';
import {ProductTypeManagementService} from '../../services/product-type-service/product-type-management.service';

@Component({
    selector: 'app-order-distribution-setting',
    templateUrl: './order-distribution-setting.component.html',
    styleUrls: ['./order-distribution-setting.component.scss'],
    standalone: false
})
export class OrderDistributionSettingComponent implements OnInit, OnDestroy {

    @Input()
    type: string;

    @Input()
    externalId: any;

    orderDistributionSettings$: Observable<OrderDistributionSetting[]>;
    orderDistributionSettings: OrderDistributionSetting[] = [];

    activeRule: Rule;
    rules: Rule[];

    unsavedChangesStream: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    unsavedChanges$ = this.unsavedChangesStream.asObservable();

    events: Event[];
    subscriptionTypes: SubscriptionType[];
    productTypes: ProductType[];
    shops: Shop[];
    customerTags: CustomerTag[];
    venueSectionGroups: VenueSectionGroup[];

    destroy$: Subject<boolean> = new Subject<boolean>();

    barcodeTypes = [
        'QRCODE',
        'C128'
    ];

    templateTypes = [
        'A4',
        'BOCA',
        'PVCCARD'
    ];

    constructor(
        private orderDistributionSettingService: OrderDistributionSettingService,
        private eventService: EventService,
        private subscriptionService: SubscriptionTypeService,
        private productTypeService: ProductTypeManagementService,
        private shopService: ShopService,
        private customerTagService: CustomerTagService,
        private venueService: VenueService,
        private notificationService: NotificationService
    ) {
    }

    ngOnInit(): void {
        this.rules = this.getRulesForType(this.type);

        this.orderDistributionSettings$ = this.orderDistributionSettingService.getOrderDistributionSettings(this.type, this.externalId).pipe(
            tap((orderDistributionSettings: OrderDistributionSetting[]) => {
                this.orderDistributionSettings = orderDistributionSettings;

                this.orderDistributionSettings.forEach(orderDistributionSetting => {
                    orderDistributionSetting.activeRule = this.rules[0];
                });
            })
        );

        this.eventService.getEvents(new HttpParams().set('take', -1)).pipe(takeUntil(this.destroy$)).subscribe((values) => {
            this.events = values.body;

            this.orderDistributionSettings.forEach(orderDistributionSetting => {
                this.onArraySelectionChanged(orderDistributionSetting, 'filterEventIds', orderDistributionSetting.filterEventIds, true);
            });
        });

        this.subscriptionService.getSubscriptionTypes().pipe(takeUntil(this.destroy$)).subscribe((values) => {
            this.subscriptionTypes = values;
        });

        this.productTypeService.list$.pipe(takeUntil(this.destroy$)).subscribe((values) => {
            this.productTypes = values;
        });

        this.shopService.getShops(new HttpParams().set('depth', '3')).pipe(takeUntil(this.destroy$)).subscribe(shops => {
            this.shops = shops;
        });

        this.customerTagService.getCustomerTags().pipe(takeUntil(this.destroy$)).subscribe(response => {
            this.customerTags = response.body;
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onRuleSelected(orderDistributionSetting: OrderDistributionSetting, rule: Rule) {
        orderDistributionSetting.activeRule = rule;
    }

    onArraySelectionChanged(orderDistributionSetting: OrderDistributionSetting, field: string, ids: string[], ignoreUnsaved = false) {
        orderDistributionSetting[field] = ids;

        if (field === 'filterEventIds') {
            this.onFilterEventIdsChange(orderDistributionSetting);
        }

        if (!ignoreUnsaved) {
            this.addToUnsavedOrderDistributionSettings(orderDistributionSetting);
        }
    }

    getSelectedEvents(orderDistributionSetting: OrderDistributionSetting) {
        return this.events?.filter(event => orderDistributionSetting.filterEventIds.includes(event.id) && event.ticketTypes.length > 0);
    }

    getSelectedSubscriptionTypes(orderDistributionSetting: OrderDistributionSetting) {
        return this.subscriptionTypes?.filter(subscriptionType => {
            return orderDistributionSetting.filterSubscriptionTypeIds.includes(subscriptionType.id) && subscriptionType.subscriptionTypePrices.length > 0;
        });
    }

    getSelectedProductTypes(orderDistributionSetting: OrderDistributionSetting) {
        return this.productTypes?.filter(productType => orderDistributionSetting.filterProductTypeIds.includes(productType.id) && productType.productTypePrices.length > 0);
    }

    onSaveEmailTemplateGroupSettingsClicked() {
        this.orderDistributionSettings.forEach(orderDistributionSetting => {
            this.orderDistributionSettingService.saveOrderDistributionSetting(orderDistributionSetting).subscribe(orderDistributionSettingResponse => {
                orderDistributionSetting.id = orderDistributionSettingResponse.id;

                this.notificationService.showTranslatedNotification('success', 'setting', 'saved');
            });
        });

        this.unsavedChangesStream.next([]);
    }

    onAddOrderDistributionSettingClick() {
        const newOrderDistributionSetting = mapOrderDistributionSetting({
            type: this.type,
            externalId: this.externalId,
        });

        newOrderDistributionSetting.activeRule = this.rules[0];

        this.orderDistributionSettings.push(newOrderDistributionSetting);
    }

    onSettingChange(orderDistributionSetting: OrderDistributionSetting, setting: string, value: any): void {
        orderDistributionSetting[setting] = value;

        this.addToUnsavedOrderDistributionSettings(orderDistributionSetting);
    }

    public onDeleteOrderDistributionSetting(orderDistributionSetting: OrderDistributionSetting): void {
        this.orderDistributionSettingService.deleteOrderDistributionSetting(orderDistributionSetting).subscribe(() => {
            this.notificationService.showTranslatedNotification('success', 'setting', 'deleted');

            const index = this.orderDistributionSettings.findIndex(existingOrderDistributionSetting => existingOrderDistributionSetting.id === orderDistributionSetting.id);

            if (index >= 0) {
                this.orderDistributionSettings.splice(index, 1);
            }
        });
    }

    private onFilterEventIdsChange(orderDistributionSetting: OrderDistributionSetting): void {
        const events = this.getSelectedEvents(orderDistributionSetting);

        let venueId = null;
        if (events.length) {
            venueId = events[0].venueId;
        }

        if (events.length && events.every(event => event.venueId === venueId)) {
            if (venueId !== orderDistributionSetting.venueId) {
                this.venueService.getVenueSectionsGroups(venueId).subscribe(venueSectionGroups => {
                    orderDistributionSetting.venueId = venueId;
                    orderDistributionSetting.venueSectionGroups = venueSectionGroups;
                });
            }
        } else {
            orderDistributionSetting.venueId = null;
            orderDistributionSetting.venueSectionGroups = [];
            this.onArraySelectionChanged(orderDistributionSetting, 'filterVenueSectionGroupIds', [], true);
        }
    }

    private addToUnsavedOrderDistributionSettings(orderDistributionSetting: OrderDistributionSetting): void {
        const unsavedChangesState = this.unsavedChangesStream.getValue();
        const index = unsavedChangesState.findIndex(orderDistributionSettingId => {
            return orderDistributionSettingId === orderDistributionSetting.id;
        }, 0);

        if (index < 0) {
            unsavedChangesState.push(orderDistributionSetting.id);
        }

        this.unsavedChangesStream.next(unsavedChangesState);
    }

    private getRulesForType(type: string): Rule[] {
        if (type === 'EMAIL_TEMPLATE_GROUP') {
            return emailTemplateGroupRules;
        }
        if (type === 'MOBILE_TEMPLATE') {
            return mobileTemplateRules;
        }
        if (type === 'TICKET_TEMPLATE') {
            return ticketTemplateRules;
        }
    }

}

interface Rule {
    id: string;
    field: string;
    label: string;
}

const emailTemplateGroupRules: Rule[] = [
    {
        id: 'SETTINGS',
        field: null,
        label: 'General.Settings'
    },
    {
        id: 'EVENT',
        field: 'filterEventIds',
        label: 'General.Events'
    },
    {
        id: 'TICKET_TYPE',
        field: 'filterTicketTypeIds',
        label: 'General.Ticket_Types'
    },
    {
        id: 'SUBSCRIPTION_TYPE',
        field: 'filterSubscriptionTypeIds',
        label: 'General.Subscriptions'
    },
    {
        id: 'SUBSCRIPTION_TYPE_PRICE',
        field: 'filterSubscriptionTypePriceIds',
        label: 'General.Subscription_Types'
    },
    {
        id: 'SHOP',
        field: 'filterShopIds',
        label: 'General.Shops'
    },
    {
        id: 'CUSTOMER_TAG',
        field: 'filterCustomerTagIds',
        label: 'General.Customer_Tags'
    }
];

const mobileTemplateRules: Rule[] = [
    {
        id: 'SETTINGS',
        field: null,
        label: 'General.Settings'
    },
    {
        id: 'EVENT',
        field: 'filterEventIds',
        label: 'General.Events'
    },
    {
        id: 'SUBSCRIPTION_TYPE',
        field: 'filterSubscriptionTypeIds',
        label: 'General.Subscriptions'
    },
    {
        id: 'SUBSCRIPTION_TYPE_PRICE',
        field: 'filterSubscriptionTypePriceIds',
        label: 'General.Subscription_Types'
    },
    {
        id: 'SHOP',
        field: 'filterShopIds',
        label: 'General.Shops'
    }
];

const ticketTemplateRules: Rule[] = [
    {
        id: 'SETTINGS',
        field: null,
        label: 'General.Settings'
    },
    {
        id: 'EVENT',
        field: 'filterEventIds',
        label: 'General.Events'
    },
    {
        id: 'TICKET_TYPE',
        field: 'filterTicketTypeIds',
        label: 'General.Ticket_Types'
    },
    {
        id: 'SUBSCRIPTION_TYPE',
        field: 'filterSubscriptionTypeIds',
        label: 'General.Subscriptions'
    },
    {
        id: 'SUBSCRIPTION_TYPE_PRICE',
        field: 'filterSubscriptionTypePriceIds',
        label: 'General.Subscription_Types'
    },
    {
        id: 'PRODUCT_TYPE',
        field: 'filterProductTypeIds',
        label: 'General.Products'
    },
    {
        id: 'PRODUCT_TYPE_PRICE',
        field: 'filterProductTypePriceIds',
        label: 'General.Product_Types'
    },
    {
        id: 'VENUE_SECTION_GROUP',
        field: 'filterVenueSectionGroupIds',
        label: 'General.Venue_Section_Groups'
    },
    {
        id: 'SHOP',
        field: 'filterShopIds',
        label: 'General.Shops'
    }
];
