import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '../../../shared/models/customer.class';
import {InviteUser} from '../../../shared/models/invite/invite-user.class';
import {SportsApiService} from '../../../sports-api.service';
import {FormManagementService} from '../../../shared/services/form-management.service';
import {NotificationService} from '../../../shared/services/notification-service/notification.service';

@Component({
    selector: 'app-customer-invite-list',
    templateUrl: './customer-invite-list.component.html',
    styleUrls: ['./customer-invite-list.component.css'],
    standalone: false
})
export class CustomerInviteListComponent implements OnInit {
    @Input() customer: Customer;
    error = false;
    errorMessage = null;

    formId = 'inviteUserForm';
    inviteUser: InviteUser;
    inviteForm = false;

    constructor(private sportsApiService: SportsApiService, private formManagementService: FormManagementService, private notificationService: NotificationService) {
    }

    ngOnInit() {
        if (!this.customer.inviteOrganisationId) {
            this.inviteUser = new InviteUser(null, null);
            return;
        }

        this.sportsApiService.getInviteUsers(this.customer.id).subscribe({
            next: (users: InviteUser[]) => {
                this.customer.invite = {
                    users: users
                };
                const emailValue = users != null ? null : this.customer.email ?? null;
                this.inviteUser = new InviteUser(null, emailValue);
            },
            error: (error) => {
                this.notificationService.error(error.error.message);
            }
        });

    }

    public addInviteUser() {
        if (!this.isFormValid()) {
            return;
        }

        this.sportsApiService.addUserToInvite(this.customer.id, this.inviteUser.email).subscribe({
            next: () => {
                const inviteUser = new InviteUser(
                    null,
                    this.inviteUser.email
                );

                if (!this.customer.invite) {
                    this.customer.invite = {
                        users: []
                    };
                }

                if (!Array.isArray(this.customer.invite.users)) {
                    this.customer.invite.users = [];
                }

                this.customer.invite.users = [...this.customer.invite.users, inviteUser];
                this.inviteUser = new InviteUser(null, null);
                this.inviteForm = false;
            },
            error: (error) => {
                this.errorMessage = error.error.message;
            }
        });
    }

    public enableInvite(customer: Customer) {
        this.error = false;

        this.sportsApiService.createInviteOrganisation(customer.id).subscribe({
            next: () => {
                this.customer.inviteOrganisationId = 'tempId';
                this.customer.invite = {users: []};
                this.inviteUser.email = customer.email;
            },
            error: (error: any) => {
                this.error = error.error.message;
            }
        });
    }

    public isFormValid(): boolean {
        return this.inviteUser.email && this.formManagementService.validate(this.formId);
    }
}
