<cm-dialog data-show-top-divider
           data-show-bottom-divider
           data-max-width="800px"
           #cmModal>
    <h4 slot="header" class="dialog-title">
        {{ title }}
    </h4>
    <div slot="body" class="dialog-body">
        @for (translation of translationArray; track $index) {
            <div class="col-lg-12 m-b-16">
                <div class="form-group position-relative">
                    @if (multiLine) {
                        <cm-text-editor data-toolbar-options='[["bold","italic","underline","link","ordered","bullet","clean"],["header"],""]'
                                        data-show-editor-options="true"
                                        [attr.data-innertext]="translationObject[translation]"
                                        data-custom-height="125px"
                                        (change)="translationInputChanged($event, translation)"></cm-text-editor>
                        <span class="multiline cm-flag cm-flag-{{checkTranslationFlag(translation.toUpperCase())}} position-absolute" aria-hidden="true"></span>
                    } @else {
                        <div class="form-row">
                            <div class="form-input-special">
                                <input type="text" (input)="translationObjectChanged($event, translation)"
                                       [(ngModel)]="translationObject[translation]" class="form-control"
                                       [id]="title + $index" [readonly]="readonly">
                                <cm-tooltip
                                    [attr.data-tooltip-text]="('Language.' + checkTranslationFlag(translation.toUpperCase()) | trans) | async">
                                    <span class="input-left icon">
                                        <span
                                            class="cm-flag cm-flag-{{checkTranslationFlag(translation.toUpperCase())}} m-t-5"
                                            aria-hidden="true"></span>
                                    </span>
                                </cm-tooltip>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
    <div slot="footer">
        <button class="btn btn-secondary" *ngIf="!readonly" (click)="closeModal(true)">
            {{ ('Generic.Forms.Cancel_Button' | trans) | async }}
        </button>
        <button class="btn btn-primary" (click)="saveChanges()"
                [disabled]="maxChars && checkMaxChars()">
            <ng-container *ngIf="!readonly; else okText">
                {{ ('Generic.Forms.Apply_Button' | trans) | async }}
            </ng-container>
            <ng-template #okText>
                {{ ('Generic.Forms.Ok_Button' | trans) | async }}
            </ng-template>
        </button>
    </div>
</cm-dialog>
