import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '../../../shared/models/customer.class';
import {CustomerNote, mapCustomerNote} from '../../../shared/models/customer-note.class';
import {DialogService} from '../../../shared/services/dialog-service/dialog.service';
import {DialogConfirmationComponent} from '../../../shared/components/dialogs/dialog-confirmation/dialog-confirmation.component';
import {CustomerService} from '../../../shared/services/customer-service/customer.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CustomerManagementService} from '../../../shared/services/customer-management/customer-management.service';
import {AppService} from '../../../app.service';

@Component({
    selector: 'app-customer-settings',
    templateUrl: './customer-settings.component.html',
    styleUrls: ['./customer-settings.component.css'],
    standalone: false
})
export class CustomerSettingsComponent implements OnInit {

    customer$: Observable<Customer> = this.customerManagementService.customer$.pipe(
        tap(customer => this.customer = customer)
    );

    @Input()
    customer: Customer;

    public showCustomerNoteEditor = false;
    public newCustomerNote: CustomerNote = new CustomerNote();
    public editorOptions = JSON.stringify([
        ['bold', 'italic', 'underline', 'link', 'ordered', 'bullet']
    ]);
    public inviteEnabled = false;

    constructor(private dialogService: DialogService, private customerService: CustomerService,
                private customerManagementService: CustomerManagementService, private app: AppService) {
    }

    ngOnInit() {
        this.inviteEnabled = this.app.config('organisation').getSetting('FEATURE_FLAG_INVITE_ENABLED');
    }

    deleteCustomerNote(customerNote: CustomerNote) {
        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {key: 'Dialog.Delete_Customer_Note_Title'},
            bodyText: {key: 'Dialog.Delete_Customer_Note_Body'},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'delete-customer-note');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                if (customerNote.id) {
                    this.customerService.deleteCustomerNote(this.customer.id, customerNote.id).subscribe();
                }

                const index = this.customer.customerNotes.findIndex(customerNoteItem => customerNoteItem === customerNote);
                if (index >= 0) {
                    this.customer.customerNotes.splice(index, 1);
                }
            }
        });
    }

    setCustomerNoteContent(event): void {
        this.newCustomerNote.content = event.detail.value;
    }

    saveCustomerNote(): void {
        this.newCustomerNote.customerId = this.customer.id;
        this.newCustomerNote.content = this.newCustomerNote.content.replace(/^"(.*)"$/, '$1');
        this.newCustomerNote.content = this.newCustomerNote.content.replace(/style=\\\"[^\\\"]*\\\"/g, '');
        this.customerService.postCustomerNote(this.customer.id, this.newCustomerNote).subscribe(dataResponse => {
            this.customer.customerNotes.unshift(mapCustomerNote(dataResponse));
        });

        this.showCustomerNoteEditor = false;
    }

}
