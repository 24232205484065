import {Download} from './download.interface';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {ExportService} from '../../export-service/export.service';
import {map} from 'rxjs/operators';

export class TicketReportDownload extends Download {
    type = 'ticket';
    filters: any[];
    total: number;
    batchSize = 100;
    formatting = {
        ticket_price: {
            numFmt: '0.00'
        },
        ticket_discount_amount: {
            numFmt: '0.00'
        }
    };

    private extraFields: any;

    constructor(private exportService: ExportService) {
        super();
    }

    getData(type: string, skip: number, total: number, filters) {
        let httpParams = new HttpParams()
            .set('skip', skip.toString())
            .set('take', this.batchSize.toString())
            .set('timezone', this.getTimezone());

        if (filters.eventIds?.length) {
            httpParams = httpParams.set('eventTicket[eventIds]', filters.eventIds);
        }

        if (filters.ticketTypeIds?.length) {
            httpParams = httpParams.set('eventTicket[ticketTypeIds]', filters.ticketTypeIds);
        }

        if (filters.venueSectionIds?.length) {
            httpParams = httpParams.set('eventTicket[venueSectionIds]', filters.venueSectionIds);
        }

        if (filters.status?.length) {
            httpParams = httpParams.set('eventTicket[status]', filters.status);
        }

        if (filters.subscriptionTypeIds?.length) {
            httpParams = httpParams.set('eventTicket[subscriptionTypeIds]', filters.subscriptionTypeIds);
        }

        if (filters.subscriptionTypePriceIds?.length) {
            httpParams = httpParams.set('eventTicket[subscriptionTypePriceIds]', filters.subscriptionTypePriceIds);
        }

        if (filters.customerTagIds?.length) {
            httpParams = httpParams.set('eventTicket[customerTagIds]', filters.customerTagIds);
        }

        if (filters.discountCampaignIds?.length) {
            httpParams = httpParams.set('eventTicket[discountCampaignIds]', filters.discountCampaignIds);
        }

        if (filters.paymentMethodIds?.length) {
            httpParams = httpParams.set('eventTicket[paymentMethodIds]', filters.paymentMethodIds);
        }

        if (filters.importIds?.length) {
            httpParams = httpParams.set('eventTicket[importIds]', filters.importIds);
        }

        if (filters.search) {
            httpParams = httpParams.set('eventTicket[search]', filters.search);
        }

        if (filters.checkedIn) {
            httpParams = httpParams.set('eventTicket[checkedIn]', true);
        }

        if (this.extraFields.includeCustomFields) {
            httpParams = httpParams.set('export[includeCustomFields]', true);
        }

        return this.exportService.getTicketReport(httpParams).pipe(
            map((response: HttpResponse<any[]>) => {
                return response.body.map(eventTicket => {
                    if (eventTicket.custom_fields) {
                        for (const customFieldId of Object.keys(eventTicket.custom_fields)) {
                            eventTicket[customFieldId] = eventTicket.custom_fields[customFieldId];
                        }
                    }

                    if (eventTicket.personalisation_custom_fields) {
                        for (const customFieldId of Object.keys(eventTicket.personalisation_custom_fields)) {
                            eventTicket[customFieldId] = eventTicket.personalisation_custom_fields[customFieldId];
                        }
                    }

                    delete eventTicket.custom_fields;
                    delete eventTicket.personalisation_custom_fields;

                    return eventTicket;
                });
            })
        );
    }

    setExtraFields(extraFields: any) {
        this.extraFields = extraFields;

        return this;
    }
}
