import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CustomerTag} from '../../models/customer-tag.class';
import {SubscriptionType} from '../../models/subscription-type.class';
import {TicketType} from '../../models/ticket-type.class';
import {DiscountCampaignSetting} from '../../models/discount-campaign-setting';
import {Event} from '../../models/event.class';
import {Subject} from 'rxjs';
import {Venue} from '../../models/venue.class';
import {EventSaleSetting} from '../../models/event-sale-setting';
import {Shop} from '../../models/shop.class';
import {LocaleService} from '../../../core/services/locale.service';
import {Group, GroupByService} from '../../services/group-by-service/group-by.service';
import {Router} from '@angular/router';
import {WindowHelper} from '../../helpers/util/window-helper';

@Component({
    selector: 'app-event-sale-setting',
    templateUrl: './event-sale-setting.component.html',
    styleUrls: ['./event-sale-setting.component.scss'],
    standalone: false
})
export class EventSaleSettingComponent implements OnInit, OnChanges, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    isMobile = WindowHelper.isMobile();

    @Input() hasIdinToken: boolean;
    @Input() eventSaleSetting: EventSaleSetting;
    @Input() event: Event;
    @Input() shops: Shop[];
    @Input() venue: Venue;
    @Input() ticketTypes: TicketType[];
    @Input() subscriptionTypes: SubscriptionType[];
    @Input() customerTags: CustomerTag[];
    @Output() delete: EventEmitter<DiscountCampaignSetting> = new EventEmitter();
    @Output() change: EventEmitter<DiscountCampaignSetting> = new EventEmitter();

    subscriptionTypeGroups: Group[] = [];
    language: string;
    activeMenuItem = Settings.DETAILS;
    isExpanded = false;
    public Settings = Settings;
    eventSaleSettingTypes = [
        {
            key: null,
            value: Settings.DETAILS,
            viewValue: 'General.Details'
        },
        {
            key: null,
            value: null,
            viewValue: 'General.If'
        },
        {
            key: 'startAt',
            value: Settings.START_AT,
            viewValue: 'Event_Sale_Setting.Sale_Start_At'
        },
        {
            key: 'endAt',
            value: Settings.END_AT,
            viewValue: 'Event_Sale_Setting.Sale_End_At'
        },
        {
            key: 'filterShopIds',
            value: Settings.SHOP,
            viewValue: 'General.Shops'
        },
        {
            key: 'filterSubscriptionTypeIds',
            value: Settings.SUBSCRIPTION_TYPE,
            viewValue: 'General.Subscription_Types'
        },
        {
            key: 'verificationRequired',
            value: Settings.VERIFICATION_REQUIRED,
            viewValue: 'General.Verification_Required'
        },
        {
            key: 'filterCustomerTagIds',
            value: Settings.CUSTOMER_TAG,
            viewValue: 'General.Customer_Tags'
        },
        {
            key: null,
            value: null,
            viewValue: 'General.Then'
        },
        {
            key: 'ticketTypeIds',
            value: Settings.TICKET_TYPE,
            viewValue: 'General.Ticket_Types'
        },
        {
            key: 'venueSectionIds',
            value: Settings.VENUE_SECTION,
            viewValue: 'General.Venue_Sections'
        },
        {
            key: 'maxAmount',
            value: Settings.MAX_AMOUNT,
            viewValue: 'Event_Sale_Setting.Max_Amount'
        },
        {
            key: 'totalMinAmount',
            value: Settings.TOTAL_MIN_AMOUNT,
            viewValue: 'Event_Sale_Setting.Total_Min_Amount'
        },
        {
            key: 'capacity',
            value: Settings.CAPACITY,
            viewValue: 'Event_Sale_Setting.Capacity'
        }
    ];

    headerColumns: {
        id: string,
        title: string,
        items: string[]
    }[] = [
        {
            id: 'SHOPS',
            title: 'General.Shops',
            items: []
        },
        {
            id: 'TICKET_TYPES',
            title: 'General.Ticket_Types',
            items: []
        },
        {
            id: 'SUBSCRIPTION_TYPES',
            title: 'General.Subscriptions',
            items: []
        },
        {
            id: 'VENUE_SECTIONS',
            title: 'General.Venue_Sections',
            items: []
        },
        {
            id: 'CUSTOMER_TAGS',
            title: 'General.Customer_Tags',
            items: []
        }
    ];

    headerPreviewItemMapping = {
        TICKET_TYPES: [],
        SUBSCRIPTION_TYPES: [],
        SHOPS: [],
        VENUE_SECTIONS: [],
        CUSTOMER_TAGS: []
    };

    constructor(private groupByService: GroupByService,
                private localeService: LocaleService,
                private router: Router) {
    }

    ngOnInit() {
        this.language = this.localeService.getLanguage().toUpperCase();

        this.setHeaderPreviews();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.subscriptionTypes) {
            this.subscriptionTypeGroups = this.groupByService.groupByYear(this.subscriptionTypes, 'createdAt', 'isMembership');
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    isSettingEnabled(eventSaleSettingType) {
        for (const eventSaleSettingTypeItem of this.eventSaleSettingTypes) {
            if (eventSaleSettingType.value === eventSaleSettingTypeItem.value && this.hasValue(this.eventSaleSetting[eventSaleSettingTypeItem.key])) {
                return true;
            }
        }

        return false;
    }

    hasValue(value) {
        if (typeof (value) === 'number' || typeof (value) === 'string' || (Array.isArray(value) && value.length > 0)) {
            return true;
        }

        return typeof (value) === 'boolean' && value === true;
    }

    isSettingDisabled(eventSaleSettingType) {
        const criteriaToDisableASwitch = {
            verificationRequired: () => !this.hasIdinToken
        };

        if (!Object.keys(criteriaToDisableASwitch).includes(eventSaleSettingType.key)) {
            return false;
        }

        return criteriaToDisableASwitch[eventSaleSettingType.key]();
    }

    switchMenu(eventSaleSettingTypeValue) {
        if (!eventSaleSettingTypeValue) {
            return;
        }

        this.activeMenuItem = eventSaleSettingTypeValue;
    }

    toggleEventSaleSetting(eventSaleSettingType) {
        if (['START_AT', 'END_AT', 'MAX_AMOUNT', 'TOTAL_MIN_AMOUNT', 'CAPACITY'].includes(eventSaleSettingType.value)) {
            this.eventSaleSetting[eventSaleSettingType.key] = null;
        } else if ([Settings.VERIFICATION_REQUIRED].includes(eventSaleSettingType.value)) {
            this.eventSaleSetting[eventSaleSettingType.key] = false;
        } else {
            this.eventSaleSetting[eventSaleSettingType.key] = [];
        }
    }

    deleteEventSettingSetting() {
        this.delete.emit();
    }

    changeEventSettingSetting() {
        this.setHeaderPreviews();

        this.change.emit();
    }

    onArraySelectionChanged(field: string, ids: string[]) {
        this.eventSaleSetting[field] = ids;
        this.changeEventSettingSetting();
    }

    private setHeaderPreviews(): void {
        if (!this.eventSaleSetting) {
            return;
        }

        this.headerPreviewItemMapping.TICKET_TYPES = this.ticketTypes
            .filter(ticketType => this.eventSaleSetting.ticketTypeIds.includes(ticketType.id))
            .map(ticketType => ticketType.name);

        this.headerPreviewItemMapping.SUBSCRIPTION_TYPES = this.subscriptionTypes
            .filter(subscriptionType => this.eventSaleSetting.filterSubscriptionTypeIds.includes(subscriptionType.id))
            .map(subscriptionType => subscriptionType.name);

        this.headerPreviewItemMapping.SHOPS = this.shops
            .filter(shop => this.eventSaleSetting.filterShopIds.includes(shop.id))
            .map(shop => shop.name);

        this.headerPreviewItemMapping.CUSTOMER_TAGS = this.customerTags
            .filter(customerTag => this.eventSaleSetting.filterCustomerTagIds.includes(customerTag.id))
            .map(customerTag => customerTag.name);

        const venueSections = [];
        this.venue.sections.forEach(ring => {
            ring.sections.forEach(venueSection => {
                venueSections.push(venueSection);
            });
        });

        this.headerPreviewItemMapping.VENUE_SECTIONS = venueSections
            .filter(venueSection => this.eventSaleSetting.venueSectionIds.includes(venueSection.id))
            .map(venueSection => venueSection.name);
    }

    navigateToIntegrations() {
        this.router.navigate(['settings/integrations']);
    }

    public onStartDateChange(e: any): void {
        this.eventSaleSetting.startAt = e.detail.datetime;
        this.changeEventSettingSetting();
    }

    public onEndDateChange(e: any): void {
        this.eventSaleSetting.endAt = e.detail.datetime;
        this.changeEventSettingSetting();
    }
}

enum Settings {
    DETAILS = 'DETAILS',
    IF = 'IF',
    START_AT = 'START_AT',
    END_AT = 'END_AT',
    SHOP = 'SHOP',
    SUBSCRIPTION_TYPE = 'SUBSCRIPTION_TYPE',
    CUSTOMER_TAG = 'CUSTOMER_TAG',
    TICKET_TYPE = 'TICKET_TYPE',
    VENUE_SECTION = 'VENUE_SECTION',
    MAX_AMOUNT = 'MAX_AMOUNT',
    TOTAL_MIN_AMOUNT = 'TOTAL_MIN_AMOUNT',
    CAPACITY = 'CAPACITY',
    VERIFICATION_REQUIRED = 'VERIFICATION_REQUIRED',
}
