import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Timezone} from '../../../../shared/models/timezone.class';
import {SeatedApiService} from '../../../../shared/services/util/seated-api/seated-api.service';

@Component({
    selector: 'app-timezone',
    templateUrl: './timezone.component.html',
    styleUrls: ['./timezone.component.css'],
    standalone: false
})
export class TimezoneComponent implements OnInit, AfterViewInit {
    private allTimeZones: Timezone[];
    public defaultTimeZone: Timezone;

    @Input() timezone: Timezone;
    @Input() disabled = false;
    @Output() timezoneChange: EventEmitter<Timezone> = new EventEmitter<Timezone>();
    @ViewChild('timezonePicker') timezonePicker: ElementRef;

    constructor(private seatedApiService: SeatedApiService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.toggleTimezonePicker(true);
        this.seatedApiService.getMany<Timezone>(new Timezone(), null).subscribe(timezones => {
            this.allTimeZones = timezones;

            if (!this.disabled) {
                this.toggleTimezonePicker(false);
            }

            // Select default language
            if (!this.timezone?.name) {
                this.timezoneChanged(Intl.DateTimeFormat().resolvedOptions().timeZone);
            }
        });
    }

    private toggleTimezonePicker(disable: boolean): void {
        if (disable) {
            this.timezonePicker.nativeElement.setAttribute('disabled', true);
            return;
        }

        this.timezonePicker.nativeElement.removeAttribute('disabled');
    }

    inputChange($event): void {
        const newValue = $event.detail.value;
        this.timezoneChanged(newValue);
    }

    private timezoneChanged(name: string): void {
        const newTimezone = this.allTimeZones.find(timezone => timezone.name === name);

        if (newTimezone) {
            this.timezone = newTimezone;
            this.timezoneChange.emit(newTimezone);
        }
    }
}
