import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {Event} from '../../models/event.class';
import {map, Observable, tap} from 'rxjs';
import {EventManagementService} from '../../services/event-service/event-management.service';

@Component({
    selector: 'app-event-autocomplete',
    templateUrl: './event-autocomplete.component.html',
    styleUrls: ['./event-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventAutocompleteComponent implements OnInit, OnChanges {
    @Input() label: string;
    @Input() eventId: string;
    @Output() eventChange: EventEmitter<Event> = new EventEmitter<Event>();
    events$: Observable<Event[]>;
    selectedEvent                              = null;

    constructor(private eventManagementService: EventManagementService) {
    }

    ngOnInit() {
        this.events$ = this.eventManagementService.list$.pipe(
            map(events => events.reverse())
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.eventId) {
            this.selectEvent(changes.eventId.currentValue);
        }
    }

    onSearchValueChanged(event) {
        const value = event.target.value;

        if (!value) {
            this.eventChange.emit(null);
        }

        this.search(value);
    }

    search(value) {
        this.events$ = this.eventManagementService.list$.pipe(
            map(events => {
                if (value) {
                    return events.reverse().filter(event => event.name?.toLowerCase().includes(value.toLowerCase()));
                }

                return events.reverse();
            })
        );
    }

    selectEvent(eventId) {
        if (this.selectedEvent && this.selectedEvent.id === eventId) {
            return;
        }

        this.eventManagementService.list$.pipe(
            tap(events => {
                this.selectedEvent = events.find(item => item.id === eventId);
                this.eventChange.emit(this.selectedEvent);
            })
        ).subscribe();
    }

}
