import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';
import {LocaleService} from '../../../services/locale.service';

@Component({
    selector: 'app-form-input-phone-number',
    templateUrl: './input-phone-number.component.html',
    styleUrls: ['./input-phone-number.component.scss'],
    standalone: false
})
export class InputPhoneNumberComponent implements OnInit, OnDestroy, FormField {
    @Input() formId;
    @Input() id;
    @Input() label;
    @Input() hint;
    @Input() value;
    @Input() required;
    @Input() autocomplete;
    @Input() placeholder;
    @Input() isValid                         = true;
    @Input() dropdown                        = true;
    @Input() type                            = 'text';
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
    language                                 = 'EN';
    countryCode                              = 'NL';

    constructor(private formManagementService: FormManagementService, private localeService: LocaleService) {
    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);
        this.language = this.localeService.getShortLanguage();
        this.countryCode = this.localeService.getCountryCode();
    }

    inputChange(event) {
        this.value   = event.formats?.INTERNATIONAL ?? event.value;
        this.isValid = event.isValid;

        this.valueChange.emit(this.value);
        this.validate();
    }

    validate() {
        return this.isValid;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }

}
