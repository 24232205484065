import {Component, OnInit} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {Printer} from '../../../models/printer.class';
import {PrintService} from '../../../services/print-service/print.service';
import {PrintFormat} from '../../../models/printFormat.class';

@Component({
    selector: 'app-dialog-edit-printer',
    templateUrl: './dialog-edit-printer.component.html',
    styleUrls: ['./dialog-edit-printer.component.css'],
    standalone: false
})
export class DialogEditPrinterComponent extends DialogBase<IInput, IOutput> implements OnInit {
    public titleText: any;
    public bodyText: any;
    public confirmText: string;
    public printer: Printer;
    public printers: Printer[];
    public printFormats: PrintFormat[];

    orientations = [
        'portrait',
        'landscape',
        'reverse-landscape'
    ];
    colors       = [
        'color',
        'grayscale',
        'blackwhite'
    ];
    rotations    = [
        -270,
        -180,
        -90,
        0,
        90,
        180,
        270
    ];

    constructor(dialogService: DialogService, private printService: PrintService) {
        super(dialogService);
    }


    ngOnInit(): void {
        this.printers     = this.printService.printers;
        this.printFormats = this.printService.getPrintFormats();
    }

    setModal(data: IInput) {
        this.titleText   = data.titleText;
        this.bodyText    = data.bodyText;
        this.confirmText = data.confirmText;
        this.printer     = data.extraData;
    }

    public confirm() {
        super.confirm({
                          successful: true,
                          // @ts-ignore
                          extraData:  this.printer
                      });
    }

    public cancel() {
        super.cancel();
    }

}
